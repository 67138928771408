import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setDisableBtn, setIsPrayerCampaign, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { setCompanyCampaigns } from "../redux/Slices/deviceSlice";
import { handleResErrors } from "./utils";

//--------------------------Get Company Campaigns--------------------------
const getCampaigns = async () => {
    const { data } = await axiosInstance.get(`/company/campaigns`);
    return data.data;
}

export const useGetCampaigns = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    const { isLoading, data, error, isError, refetch } = useQuery(
        'getCampaigns',
        getCampaigns,
        {
            onSuccess: (data) => {
                dispatch(setCompanyCampaigns(data));
            },
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}

//--------------------------Create Campaign--------------------------
const createCampaign = async (payload) => {
    const { data } = await axiosInstance.post("/campaign/create", payload);

    return data.data;
}

export const useCreateCampaignMutation = () => {
    const { isInteractiveCampaign } = useDataTableContext();
    const { showToast } = useCustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return useMutation(createCampaign, {
        onSuccess: async (data) => {
            dispatch(setDisableBtn(false))
            dispatch(setIsPrayerCampaign(false))
            dispatch(toggleDialog("createCampaign"));
            showToast('success', 'Campaign Creation', "Campaign created successfully!");

            sessionStorage.setItem('is_default_prayer', JSON.stringify(false));

            if (isInteractiveCampaign)
                navigate(`/interactive/campaign/${data?.id}/setup`)
            else
                navigate(`/campaigns/${data?.id}/setup`)
        },
        onError: (error) => {
            dispatch(setDisableBtn(false));
            handleResErrors(error, showToast)
        }
    })
}

//--------------------------Update Campaign--------------------------
const updateCampaign = async (payload) => {
    const { data } = await axiosInstance.put(`/campaign/${payload.id}/update`, payload.data);

    return data.data;
}

export const useUpdateCampaignMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(updateCampaign, {
        onSuccess: async () => {
            dispatch(setDisableBtn(false))
            dispatch(toggleDialog("updateCampaign"))
            setLazyParams(prev => ({ ...prev }))
            showToast('success', 'Campaign Updating', "The campaign has been updated successfully!");
        },
        onError: (error) => {
            dispatch(setDisableBtn(false));
            handleResErrors(error, showToast)
        }
    })
}

//--------------------------Get Campaign By ID--------------------------
const getCampaign = async (id) => {
    const { data } = await axiosInstance.get(`/campaign/${id}`);
    return data.data;

}

export const useGetCampaign = (param) => {
    const { showToast } = useCustomToast();
    const { id, enabled } = param

    const { isLoading, data, error, isError, refetch } = useQuery(
        ['getCampaign', id],
        () => getCampaign(id),
        {
            enabled,    // Control whether the query runs automatically
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}

