import { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Select from 'react-select';

import { useCreateBroadcastMutation, useGetDevices } from '../../../../queries/broadcast';
import { BROADCAST_SETTINGS, BROADCAST_TYPES } from '../../../../Constants/apps';
import { useSelector, useDispatch } from 'react-redux';
import DeviceLastLoginListener from '../../../Services/DeviceLastLoginListener';
import { getFormErrorMessage } from '../../../../config/global';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function CreateBroadcast() {
  const { control, formState: { errors }, handleSubmit, getValues } = useForm();
  const { openDialog } = useSelector((state) => state.global);
  const onlineDevices = useSelector((state) => state.device.onlineDevices);
  const createBroadcast = useCreateBroadcastMutation()
  const dispatch = useDispatch()

  const { data: devices } = useGetDevices()
  const [devicesOption, setDevicesOption] = useState([]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      const options = devices
        .filter(device => onlineDevices[device.serial_name])
        .map(device => ({ label: device.name, value: device.id }));
      setDevicesOption(options)
    }
  }, [devices, onlineDevices])

  const createHandler = async (data) => {
    await createBroadcast.mutateAsync({
      name: data.name,
      type: data.type,
      senders: [data.senders],
      receivers: getValues('receivers').map(option => option.value),
      senders_options: BROADCAST_SETTINGS["TWO_WAY"],
      receivers_options: BROADCAST_SETTINGS[data.type]
    })
  }

  const onClose = () => dispatch(toggleDialog("createBroadcastDialog"))

  return (
    <Dialog visible={openDialog.createBroadcastDialog}
      style={{ width: '42%' }}
      breakpoints={{ '960px': '95vw' }}
      header="Broadcast Creation"
      modal className="p-fluid overflow-visible"
      onHide={onClose}
    >
      <form onSubmit={handleSubmit(createHandler)} className=" d-flex flex-column justify-content-center">
        {
          devicesOption?.length > 0 ?
            <>
              <div className="col-12 d-flex flex-column  justify-content-center py-4 border-top border-gray">
                {/* Name */}
                <div className='mb-3 px-4'>
                  <div className="field ">
                    <label className="form-label"> Name </label>
                    <span className="p-float-label">
                      <Controller name="name" control={control}
                        rules={{ required: 'Name is required.' }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoFocus
                            inputRef={field.ref}
                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                          />
                        )} />
                    </span>
                    {getFormErrorMessage('name', errors)}
                  </div>
                </div>

                {/* Type */}
                <div className='mb-3 px-4'>
                  <label htmlFor="" className='mb-2 form-label'>type</label>
                  <Controller name="type" control={control}
                    rules={{ required: "type is required!" }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        id={field.name} {...field}
                        value={field.value}
                        options={BROADCAST_TYPES}
                        onChange={(e) => { field.onChange(e.value) }}
                        optionLabel="label"
                        optionValue="value"
                        inputRef={field.ref}
                        placeholder="select..."
                        className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                      />
                    )
                    } />
                  {getFormErrorMessage('type', errors)}
                </div>

                {/* Hosted  Device */}
                <div className='mb-3 px-4'>
                  <label htmlFor="" className='mb-2 form-label'>Hosted Device</label>
                  <Controller name="senders" control={control}
                    rules={{ required: "Hosted device is required!" }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        id={field.name} {...field}
                        value={field.value}
                        options={devicesOption}
                        onChange={(e) => { field.onChange(e.value) }}
                        optionLabel="label"
                        optionValue="value"
                        inputRef={field.ref}
                        placeholder="select..."
                        className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                      />
                    )
                    } />
                  {getFormErrorMessage('senders', errors)}
                </div>

                {/* Receiver  Devices */}
                <div className='mb-3 px-4'>
                  <label htmlFor="" className='mb-2 form-label'>Receiver Device</label>
                  <Controller name="receivers" control={control}
                    rules={{ required: "Receiver device is required!" }}
                    render={({ field, fieldState }) => (
                      <Select
                        closeMenuOnSelect={false}
                        {...field}
                        options={devicesOption}
                        onChange={(selectedOptions) => {
                          field.onChange(selectedOptions);
                        }}
                        isMulti
                        placeholder="Select..."
                      />
                    )}
                  />
                  {getFormErrorMessage('receivers', errors)}
                </div>
              </div>
            </>
            :
            <div className="d-flex p-5 justify-content-center ">
              <Message className="fw-bold fs-3 p-4 w-100"
                icon={<ProgressSpinner className='w-[35px] h-[35px] mx-3' strokeWidth="4" fill="var(--surface-ground)" animationDuration="50s" />
                }
                severity="info" text="Detecting online devices!" />
            </div>
        }


        <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
          <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createBroadcast.isLoading} onClick={onClose} />
          <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Create"
            loading={createBroadcast.isLoading}
            disabled={devicesOption.length == 0}
          />
        </div>

        {
          devices?.length ?
            devices?.map(device => {
              return (<DeviceLastLoginListener serialName={device.serial_name} path={"Devices/" + device.serial_name + "/lastLogin"} />)
            })
            :
            <></>
        }
      </form>
    </Dialog>
  )
}

export default CreateBroadcast