import React, { useRef } from 'react'

import { useParams } from 'react-router-dom';
import { Menu } from 'primereact/menu';
 
import { useSelector } from 'react-redux';
import { useFlushDevice, useRestartDevice } from '../../../../queries';

function SerialNav () {
    const menu = useRef(null);
    const deviceInfo = useSelector((state) => state.device.selectedDevice);

    const { id } = useParams();
     const restartDevice = useRestartDevice();
     const flushDevice = useFlushDevice();

    const options = [
        {
            items: [
                {
                    label: 'Flush',
                    icon: 'bx bx-help-circle',
                    command: () => {
                        flushHandler()
                    }
                },
                {
                    label: 'Restart',
                    icon: 'bx bx-power-off',
                    command: () => {
                        restartHandler()
                    }
                }
            ]
        },
    ];

    const flushHandler = async () => {
        await flushDevice.mutateAsync({ id })
    }

    const restartHandler = async () => {
        await restartDevice.mutateAsync({ id })
    }

    return (
        <nav className="layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme w-100 mt-0 mb-3" id="layout-navbar">
            <div className="navbar-nav-right d-flex align-items-center py-2 " id="navbar-collapse">
                     <div className="d-flex flex-col">
                            <h5 className="m-0">{ deviceInfo.name} </h5>
                            <small className="mt-1 font-light">{ deviceInfo.serial_name }</small>
                    </div>
                 <div className="navbar-nav flex-row align-items-center ms-auto d-lg-flex d-none">
                    <button className="dropdown-item" onClick={ flushHandler }>
                        <i className="bx bx-help-circle me-2" />
                        <span className="align-middle">Flush</span>
                    </button>

                    <button className="dropdown-item" onClick={ restartHandler }>
                        <i className="bx bx-power-off me-2" />
                        <span className="align-middle">Restart</span>
                    </button>
                </div>

                <span className='text-end ms-auto cursor-pointer d-flex d-lg-none'
                    onClick={ (event) => menu.current.toggle(event) } >
                    <i className="fas fa-ellipsis-v text-light fs-4" tooltip="option"
                        tooltipOptions={ { position: 'bottom' } } onClick={ (event) => menu.current.toggle(event) } aria-controls="popup_menu" aria-haspopup></i>
                    <Menu model={ options } popup ref={ menu } id="popup_menu" />
                </span>

            </div>
        </nav>
    )
}

export default SerialNav