import React, { useEffect, useState } from 'react'

import axiosInstance from '../../../../../config/Axios';

import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';

import ChangeTemplatesLayouts from './Edit/ChangeTemplatesLayouts';
import ChangeCustomLayouts from './Edit/ChangeCustomLayouts';
import EditLayout from './Edit/EditLayout';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, toggleLoader } from '../../../../../redux/Slices/Global/globalSlice';
import { setDefaultTemplate, setUserTemplate } from '../../../../../redux/Slices/layoutSlice';
import { Button } from 'primereact/button';

function EditLayoutPopUp() {

  const { openDialog } = useSelector((state) => state.global);

  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isMaximized, setIsMaximized] = useState(false);


  const items = [
    {
      label: 'Current Layout',
      command: () => setActiveIndex(0),
    },
    {
      label: 'Custom Layouts',
      command: () => setActiveIndex(1),
    },
    {
      label: 'Templates Layouts',
      command: () => setActiveIndex(2),
    },
  ];

  const fetchTemplate = () => {
    dispatch(toggleLoader("createLoading"))
    axiosInstance.get(`/layout/all?page=${currentPage + 1}`)
      .then((result) => {
        const defaultTemplate = result.data.data.default_layout;
        const userTemplate = result.data.data.custom_layout.data;

        const updatedDefaultTemplates = defaultTemplate.map(item => ({
          id: item.id,
          title: item.title,
          screens_setting: item.screens_setting,
          aspect_ratio: item.aspect_ratio,

        }));

        const updatedUserTemplates = userTemplate.map(item => ({
          id: item.id,
          title: item.title,
          screens_setting: item.screens_setting,
          aspect_ratio: item.aspect_ratio,

        }));
        const data = result.data.data.custom_layout.pagination.total;
        setTotalRecords(data);
        dispatch(setDefaultTemplate(updatedDefaultTemplates));
        dispatch(setUserTemplate(updatedUserTemplates));
        dispatch(toggleLoader("createLoading"));
      })
      .catch((error) => {
        console.error("Error fetching layouts:", error);
        dispatch(toggleLoader("createLoading"));
      });
  }

  useEffect(() => {
    fetchTemplate()
  }, [currentPage]);


  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <EditLayout
          isMaximized={isMaximized}
        />;
      case 1:
        return (<ChangeCustomLayouts
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={totalRecords}
        />);

      case 2:
        return <ChangeTemplatesLayouts />;

      default:
        return null;
    }
  };

  const handleMaximize = () => { setIsMaximized((prev) => !prev); };

  const customHeader = (
    <div className="flex justify-between items-center w-full">
      <span className="text-lg font-semibold">Edit Your Layout</span>
      <Button
        icon={isMaximized ? "pi pi-window-minimize" : "pi pi-window-maximize"}
        className="p-button-text p-2"
        onClick={handleMaximize}
      />
    </div>
  );

  return (
    <Dialog visible={openDialog.EditLayoutPopUp}
      breakpoints={{ '960px': '95vw' }}
      header={customHeader}
      style={{ overflow: 'hidden' }}
      modal
      blockScroll={true}
      className={`p-fluid w-full ${isMaximized ? 'p-dialog-maximized' : 'p-dialog-default md:w-[100%] lg:w-[75%] h-[85%]'}`}
      onHide={() => dispatch(toggleDialog("EditLayoutPopUp"))}
    >
      <div className="overflow-hidden h-full">

        <TabMenu className='pe-5 pl-5' model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

        <div className="flex flex-col mt-3 overflow-y-auto h-[calc(100%-3rem)]" >
          {renderContent()}
        </div>

      </div>

    </Dialog>
  )
}

export default EditLayoutPopUp