import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useCustomToast } from '../../../../../Contexts/ToastContext';
import axiosInstance from '../../../../../../config/Axios';
import { layoutColor, unitOptions,  COMMON_ASPECT_RATIOS } from '../../../../../../config/layoutConstans';
import { convertToPx, handleSwitchUnit } from '../../../../../../config/layoutHelper';

import AreaControls from "../../Custom/Controls/AreaControls";
import DynamicContainer from "../../Custom/Controls/DynamicContainer";
import LayoutNameController from '../../Custom/Controls/LayoutNameController';
import DynamicContainerPreview from "./Warning_preview/DynamicContainerPreview";
import AddArea from '../../Custom/Controls/AddArea';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, setDisableBtn } from '../../../../../../redux/Slices/Global/globalSlice';
import { setReloadTrigger } from '../../../../../../redux/Slices/layoutSlice';

function EditLayout(isMaximized) {
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();
    const { id } = useParams();
    const navigate = useNavigate();
    const [unit, setUnit] = useState(unitOptions[0]); // Default to percentage

    const selectedLayout = useSelector((state) => state.layout.selectedLayout);
    const screens_setting = useSelector((state) => state.layout.screens_setting);

    const { control, handleSubmit } = useForm({ defaultValues: { layoutName: selectedLayout.title, }, });
    const [layoutName, setLayoutName] = useState({ isEditing: false, name: selectedLayout.title });

    const [oldLayout, setOldLayout] = useState(selectedLayout.screens_setting);
    const [overlappingAreas, setOverlappingAreas] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isConflectScreens, setIsConflectScreens] = useState(false);
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [newLayout, setNewLayout] = useState([]);
    const newAreas = screens_setting.map((area, index) => ({
        ...area,
        id: area.id || index + 1,
        unit: "%"
    }));
    const [titleError, setTitleError] = useState("");

    const [areas, setAreas] = useState(newAreas);
    const [selectedArea, setSelectedArea] = useState({});

    const [oldAspectRatio, setOldAspectRatio] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`/aspect-ratio/${selectedLayout.aspect_ratio_id}/show`)
            .then((res) => {
                const data = res.data.data;
                setOldAspectRatio(data)
                setCurrentAspectRatio(data)
                setLoading(false)

            })
    }, []);


    useEffect(() => {
        // setAreas(newAreas)
        setLayoutName({ isEditing: false, name: `${selectedLayout.title}` });
    }, [])

    useEffect(() => {
        if (unit.value == "px") {
            // convertToPx()//convert area to px
            const areasConverted = convertToPx(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
        if (unit.value == "%") {
            // handleSwitchUnit()//convert area to % 
            const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
    }, [unit])

    const handleEditTemplate = () => {
        const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
        const sanitizedAreas = areasConverted.map(({ unit, ...rest }) => rest);
        if (areas.length < oldLayout.length) {
            setOldLayout(selectedLayout.screens_setting)
            setNewLayout(sanitizedAreas)
            setIsConflectScreens(true)
        } else {
            setIsConflectScreens(false)
            setNewLayout(sanitizedAreas)
            handleChange();
        }
    };

    const handleChange = () => {

        const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
        const areaPercentage = areasConverted.map(({ unit, ...rest }) => rest);

        const toSethScreenSetting = areaPercentage.map(({ id, name, width, height, start_margin, top_margin }, index) => ({
            id: index,
            name,
            width,
            height,
            start_margin,
            top_margin
        }));

        const sanitizedAreas = areaPercentage
            .filter(({ id }) => typeof id === 'string')
            .map(({ id, name, width, height, start_margin, top_margin }) => ({
                id,
                name,
                width,
                height,
                start_margin,
                top_margin
            }));

        axiosInstance.post(`/layout/${id}/update`, {
            title: layoutName.name,
            screens_setting: toSethScreenSetting,
            type: "CUSTOM",
            status: "ACTIVE",
            aspect_ratio_id: currentAspectRatio.id,
            updated_campaigns: sanitizedAreas
        })
            .then(result => {
                dispatch(toggleDialog("EditLayoutPopUp"));
                showToast('success', "Layout Creation", "Layout Updated successfully!");
                dispatch(setReloadTrigger(true));
                dispatch(setDisableBtn(false));
            })
            .catch(error => {
                console.error("API call failed:", error);
                showToast('error', "Layout Creation", "Failed to update layout!");
            });
    };
    const handleUnitChange = (e) => {
        setUnit(e.target)
    }

    return (
        <>
            <div className="flex flex-col items-center  mt-1 w-100">
                {isConflectScreens ? (
                    <>
                        <div className="w-[70%] flex flex-col mt-5 text-white font-medium mb-3 alert bg-orange-300 p-5" role="alert">
                            <div className="flex justify-center justify-items-center text-center ">
                                {/* Preview Area */}
                                <DynamicContainerPreview
                                    areas={oldLayout}
                                    layoutColor={layoutColor}
                                    currentAspectRatio={oldAspectRatio}
                                />
                                <div className="mt-3 relative w-auto pt-[12%] pl-3 pr-3 h-36 rounded-md justify-items-center justify-self-center">

                                    <i class="fa-solid fa-angles-right"></i>
                                </div>
                                <DynamicContainerPreview
                                    areas={newLayout}
                                    layoutColor={layoutColor}
                                    currentAspectRatio={currentAspectRatio}
                                />

                            </div>

                            <div className="flex mt-3 justify-center">
                                <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                <div className="ml-3">
                                    The selected layout has fewer zones than your current setup.
                                    <br />
                                    Content in some zones may be lost. Are you sure you want to proceed?
                                </div>
                            </div>
                            <div className="flex mt-3 justify-center  ">
                                <Button label="Confirm" className={"pt-1 pb-1 me-2  "} disabled={isSaveDisabled} onClick={() => {
                                    handleChange()
                                }} />
                                <Button label="Cancel" className={"pt-1 pb-1 me-2   bg-gray-500 border-orange-300 text-white rounded hover:bg-gray-600"} onClick={() => {
                                    setAreas(newAreas)
                                    setIsConflectScreens(false)
                                }} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {loading ?
                            <div className='w-100 h-100 d-flex align-items-center' >
                                <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                            </div>
                            :
                            <>
                                <div className="overflow-hidden w-100 mb-3 pl-5 pr-5 flex flex-col lg:flex-row gap-10">

                                    {/* Preview Area */}
                                    <DynamicContainer
                                        isMaximized={isMaximized}
                                        selectedArea={selectedArea}
                                        setSelectedArea={setSelectedArea}
                                        areas={areas}
                                        setAreas={setAreas}
                                        unit={unit}
                                        layoutColor={layoutColor}
                                        currentAspectRatio={currentAspectRatio}
                                        setCurrentAspectRatio={setCurrentAspectRatio}
                                    />

                                    {/* Controls */}
                                    <div className="  flex flex-col w-50  ">
                                        <div className="mt-3 flex  w-100 lg:w-1/2">
                                            <div className="w-75">
                                                <label className="text-sm font-medium mb-1">Title</label>
                                                <div className="d-flex mb-3 ">
                                                    <LayoutNameController layoutName={layoutName} setLayoutName={setLayoutName} titleError={titleError} />

                                                </div>
                                            </div>

                                            <div className="w-25">
                                                <label className="text-sm font-medium mb-1">Unit</label>
                                                <Dropdown
                                                    value={unit?.value || unitOptions[0]}
                                                    options={unitOptions}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="mb-3 mt-1 w-100"
                                                    onChange={handleUnitChange}
                                                    placeholder="Select a Unit"
                                                />
                                            </div>

                                        </div>
                                        <div className="flex flex-col w-100 lg:w-1/2">
                                            <AreaControls
                                                selectedArea={selectedArea}
                                                setSelectedArea={setSelectedArea}
                                                areas={areas}
                                                unit={unit}
                                                setAreas={setAreas}
                                                currentAspectRatio={currentAspectRatio}
                                                isSaveDisabled={isSaveDisabled}
                                                overlappingAreas={overlappingAreas}
                                                setOverlappingAreas={setOverlappingAreas}
                                                setIsSaveDisabled={setIsSaveDisabled}
                                            />
                                            {overlappingAreas.length > 0 && (
                                                <div className=" flex mt-3 text-white font-medium mb-3 alert bg-orange-300" role="alert">
                                                    <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                                    <div className="ml-3">
                                                        These zones are being overlapped by some other zone:{" "}
                                                        {overlappingAreas.join(", ")}.
                                                        <br />
                                                        Please adjust the zones to avoid overlapping.
                                                    </div>
                                                </div>
                                            )}
                                            <AddArea unit={unit} currentAspectRatio={currentAspectRatio} setAreas={setAreas} areas={areas} />
                                            {/* Action Buttons */}
                                            <div className="mt-3 mb-5 flex justify-end gap-3">
                                                <Button label="Save" className={"pt-1 pb-1 me-2"} disabled={isSaveDisabled} onClick={() => handleEditTemplate()} />
                                                <Button label="Cancel" className={"pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => setAreas(newAreas)} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                )}
            </div>
        </>
    )
}

export default EditLayout