import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react'
import { Controller } from "react-hook-form";
import Marquee from "react-fast-marquee";
import isEmpty from 'lodash/isEmpty';

import { SelectButton } from 'primereact/selectbutton';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';

import { directionOptions, speedOptions } from "../../../../config/constants"
import { setRssSettings } from '../../../../redux/Slices/Apps/appsSlice';

const Bar = ({ feeds }) => {
  const { rssSettings } = useSelector(state => state.apps);

  return (
    <Marquee
      direction={rssSettings?.direction}
      speed={`${50 * rssSettings?.speed}`}
      style={{
        backgroundColor: `${rssSettings?.bg_color }`,
        color: `${rssSettings?.text_color } !important`,
        fontFamily: rssSettings?.font_family,
        fontSize: `${rssSettings?.font_size}px`
      }}
    >
      {
        feeds.map((item, index) => {
          return (
            <div className='d-flex align-items-center py-1' key={index}>
              <h4 className='mb-0 ' style={{
                color: rssSettings?.text_color ,
                fontFamily: rssSettings?.font_family,
                fontSize: `${rssSettings?.font_size}px`
              }}>{item?.title}</h4>
              <img src={isEmpty(rssSettings?.newSeparator) ? rssSettings?.separator : URL.createObjectURL(rssSettings?.newSeparator)}
                style={{ width: "40px" }}
                className="w-px-40 mx-5 "
              />
            </div>
          )
        })
      }
    </Marquee >
  )
}

const BottomOption = ({ control, classes = {} }) => {
  const { rssSettings } = useSelector(state => state.apps);
  const fileUploadRef = useRef(null);
  const dispatch = useDispatch()
  const chooseOptions = {
    className: 'upload_separator',
    label: 'click to upload image...',
    style: {
      background: 'transparent',
      color: 'gray',
      width: "100%",
      border: "2px dashed #D9DEE3",
      fontWeight: "normal",
      fontSize: classes?.separator && "13px",
      padding: classes?.separator && "5px",

    }
  };

  const onFileSelect = (event) => {
    const file = event.files && event.files[0];

    if (file) {
      dispatch(setRssSettings({ newSeparator: file }))
    }
  };

  const onCancelUpload = () => {
    dispatch(setRssSettings({ newSeparator: {} }))
    fileUploadRef.current.clear();
  };

  return (
    <>
      <div className={`col-6 p-1 `}>
        <label htmlFor="direction" className={`mb-0 form-label text-capitalize `} >Direction</label>
        <Controller name="direction" control={control} className=""
          rules={{ required: false }}
          render={({ field, fieldState }) => (
            <SelectButton
              id={field.name} {...field}
              className={`custom_select d-flex ${classNames({ 'p-invalid': fieldState.invalid })}`}
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e.value);
                dispatch(setRssSettings({ direction: e.value }))
              }}
              options={directionOptions}
              optionLabel="icon" />
          )} />
      </div>

      <div className={`col-6  p-1 mb-2`} >
        <label htmlFor="speed" className={`mb-0 form-label text-capitalize `} >Speed</label>
        <Controller name="speed" control={control}
          rules={{ required: false }}
          render={({ field, fieldState }) => (
            <SelectButton
              id={field.name} {...field}
              onChange={(e) => {
                const selectedSpeed = e.value || rssSettings?.speed || 1;
                dispatch(setRssSettings({ speed: selectedSpeed }))
              }}

              className={`custom_select d-flex ${classNames({ 'p-invalid': fieldState.invalid })}`}
              inputRef={field.ref}
              options={speedOptions}
              optionLabel="label"
            />
          )} />
      </div>

      {/* Separator Input */}
      <div className={`col-12 ${classes?.mb ?? "mb-3"} d-flex flex-column`} >
        <label htmlFor="separator" className={`mb-0 form-label text-capitalize `} >Separator</label>
        <Controller name="separator" control={control}
          rules={{ required: false }}
          render={({ field, fieldState }) => (
            <FileUpload
              mode="basic"
              name="separator"
              accept="image/*"
              maxFileSize={1000000}
              customUpload
              onSelect={onFileSelect}
              ref={fileUploadRef}
              onClick={onCancelUpload}
              chooseOptions={chooseOptions}
            />
          )} />

      </div>
    </>
  )
}

export {
  Bar,
  BottomOption
}