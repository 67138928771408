import React, { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../../config/global';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import {
    useDeleteGroupMutation,
    useFlushGroupMutation,
    useMuteUnmuteGroupMutation,
    usePlayPauseGroupMutation,
    useRestartGroupMutation,
    useUpdateGroupMutation
} from '../../../../queries/group';

function GroupHeader({ hasDevices, groupName, columnId, groupID, assignSchedule }) {
    const { formState: { errors }, control, handleSubmit, reset } = useForm();

    const restartGroupDevices = useRestartGroupMutation();
    const muteUnmuteGroup = useMuteUnmuteGroupMutation()
    const playPauseGroup = usePlayPauseGroupMutation()
    const updateGroup = useUpdateGroupMutation();
    const deleteGroup = useDeleteGroupMutation();
    const flushGroup = useFlushGroupMutation();

    const isDefaultGroup = useMemo(() => groupName.toLowerCase() === "default", [groupName])

    let defaultName = groupName

    const [editName, setEditName] = useState(false);
    const [name, setName] = useState(defaultName);

    useEffect(() => {
        reset({ "name": name })
    }, [editName])

    const onSubmit = async (data) => {
        setEditName(false)
        setName(data.name)
        if (groupName != data.name) {
            await updateGroup.mutateAsync({
                id: groupID,
                data: data
            })
        }
    }

    const deleteGroupHandler = async (groupID) => {
        await deleteGroup.mutateAsync({ id: groupID })
    }

    const flushHandler = async (groupID) => {
        await flushGroup.mutateAsync({ id: groupID })
    }

    const restartHandler = async (groupID) => {
        await restartGroupDevices.mutateAsync({ id: groupID })
    }

    const muteHandler = async (groupID, action) => {
        await muteUnmuteGroup.mutateAsync({
            id: groupID,
            data: { "action": action, }
        })
    }

    const playPauseHandler = async (groupID, action) => {
        await playPauseGroup.mutateAsync({
            group_id: groupID,
            action: action
        })
    }

    const items = [

        {
            label: <span className="fs-6 "> Assign schedule </span>,
            icon: 'bx bx-time-five me-2 text-light',
            command: () => {
                assignSchedule(groupID)
            },
            visible: true,
            disabled: !hasDevices
        },
        {
            label: <span className="fs-6 ">Edit Group Name</span>,
            icon: "fa fa-edit me-2 text-light",
            command: () => {
                { setEditName(true) }
            },
            visible: !isDefaultGroup,
            disabled: false
        },
        {
            label: <span className="fs-6 ">Delete</span>,
            icon: "bx bx-trash me-2 text-light",
            command: () => {
                deleteGroupHandler(groupID)
            },
            visible: !isDefaultGroup,
            disabled: false
        },
        {
            label: <span className="fs-6 ">Mute</span>,
            icon: "fa fa-volume-off me-2 text-light",
            command: () => {
                muteHandler(groupID, "mute")
            },
            visible: true,
            disabled: !hasDevices
        },
        {
            label: <span className="fs-6 ">UnMute</span>,
            icon: "fas fa-volume-high me-2 text-light",
            command: () => {
                muteHandler(groupID, "unmute")
            },
            visible: true,
            disabled: !hasDevices
        },
        {
            label: <span className="fs-6 ">Flush</span>,
            icon: 'bx bx-help-circle me-2 text-light',
            command: () => {
                flushHandler(groupID)
            },
            visible: true,
            disabled: !hasDevices
        },
        {
            label: <span className="fs-6 ">Restart</span>,
            icon: 'fas fa-power-off me-2 text-light',
            command: () => {
                restartHandler(groupID)
            },
            visible: true,
            disabled: !hasDevices
        }]

    return (
        <>
            <div className='bg-white px-3 pt-2 group_title w_20'>
                <div className='d-flex justify-content-between align-items-center'>
                    {
                        editName ?
                            <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center">
                                <div className={`field  ${errors.name ? "mx-2 mt-2" : "m-2"}`}>
                                    <span className="p-float-label">
                                        <Controller name="name" control={control}
                                            rules={{ required: 'Name is required!' }}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <InputText
                                                        id={field.name} {...field} autoFocus
                                                        className={`w-100 p-1 ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                                                )
                                            }} />
                                    </span>
                                </div>

                                {/* Save changes */}
                                <Tooltip target={`.save-button-${columnId}`} showDelay={100} className="fs-8" />
                                <button className={`btn btn-sm btn-icon save-button-${columnId}`} data-pr-position="bottom" type='submit' data-pr-tooltip="Update" >
                                    <i className="far fa-check-circle  text-light fs-6" ></i>
                                </button>

                            </form>
                            :
                            <h2 className=" mb-0 me-3 fs-5 fw-bold">{name}</h2>
                    }

                    <div className='d-flex align-items-center'>
                        {
                            !editName &&
                            <>
                                {
                                    restartGroupDevices.isLoading || muteUnmuteGroup.isLoading || updateGroup.isLoading || deleteGroup.isLoading || flushGroup?.isLoading || playPauseGroup.isLoading ?
                                        <ProgressSpinner className='w-[25px] h-[25px]' strokeWidth="3" />
                                        :
                                        <></>
                                }

                                {/* Play */}
                                <Tooltip target={`.play-${columnId}`} showDelay={100} className="fs-8" />
                                <button className={`btn btn-sm btn-icon play-${columnId}`} data-pr-position="bottom" data-pr-tooltip="play"
                                    onClick={() => playPauseHandler(groupID, "play")} disabled={!hasDevices}>
                                    <i className="bx bx-play-circle text-light"></i>
                                </button>

                                {/* Pause */}
                                <Tooltip target={`.pause-${columnId}`} showDelay={100} className="fs-8" />
                                <button disabled={!hasDevices} className={`btn btn-sm btn-icon pause-${columnId}`} data-pr-position="bottom" data-pr-tooltip="pause"
                                    onClick={() => playPauseHandler(groupID, "pause")}>
                                    <i className="bx bx-pause-circle text-light"></i>
                                </button>
                            </>
                        }
                        <SplitButton
                            label={null}
                            model={items}
                            className="p-button-text p-button-primary p-button-sm  mt-1 p-button-secondary device_actions ms-3"
                            buttonClassName="d-none"
                            dropdownIcon="fas fa-ellipsis-v"
                        />
                    </div>

                </div>
                <div className='mx-2'>{getFormErrorMessage('name', errors)}</div>
            </div>
        </>
    )
}

export default GroupHeader