import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';

import { useAssignCampaignDevices } from '../../../../queries';

function AssignCampaign({ selectedCampaign, setSelectedCampaign }) {
    const assignCampaignDevices = useAssignCampaignDevices();
    const { companyCampaigns } = useSelector((state) => state.device);
    const { id } = useParams();

    const assign = async (e) => {
        setSelectedCampaign(e.value);
        await assignCampaignDevices.mutateAsync({
            type: "devices",
            devices_ids: [id],
            campaign_id: e.value
        });
    }

    return !_.isEmpty(companyCampaigns) ?
        <div className="card-body py-2">
            <div className="card-header p-0 mb-2 ">
                <div className=' d-flex align-items-center justify-content-between mb-2'>
                    <h5 className="m-0 me-2 ">Assign Campaign</h5>
                    <small>{assignCampaignDevices?.isLoading ? "updating..." : ""}</small>
                </div>
                { }
                <Dropdown
                    className='w-100'
                    value={selectedCampaign}
                    options={companyCampaigns}
                    onChange={assign}
                    filter
                    disabled={assignCampaignDevices?.isLoading}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select campaign" />
            </div>
        </div>
        : <></>
}

export default AssignCampaign