import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Spinner } from 'react-bootstrap';
import { Bar } from './Positions/Bar';

import { ScreenSides } from './Positions/ScreenSides';
import { useReadRss } from '../../../queries/rss';

const templateFeed = [
    {
        "title": "Here will be the title of the first news article",
        "link": "https://yourwebsite.com/news-article-1",
        "description": "This is where the description of the news article will go.",
        "date": "YYYY-MM-DD",
        "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
    },
    {
        "title": "Here will be the title of the second news article",
        "link": "https://yourwebsite.com/news-article-2",
        "description": "This is where the description of the news article will go.",
        "date": "YYYY-MM-DD",
        "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
    },
    {
        "title": "Here will be the title of the third news article",
        "link": "https://yourwebsite.com/news-article-3",
        "description": "This is where the description of the news article will go.",
        "date": "YYYY-MM-DD",
        "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
    }
]

function RssPreview({ rssURL = "", isCustomRSS = false, isCreation = true }) {
    const { rssSettings, selectedRss } = useSelector(state => state.apps);
    const readRss = useReadRss();

    const [feeds, setFeeds] = useState([]);
    const [activePosition, setActivePosition] = useState();

    const readHandler = async (rssURL) => {
        await readRss.mutateAsync({ url: rssURL }, {
            onSuccess: (data) => {
                setFeeds(data?.posts)
            }
        })
    }

    // useEffect to trigger an action when the url input changes
    useEffect(() => {
        const _url = selectedRss?.path ?? rssURL;
        setFeeds([])
        if (!_.isEmpty(_url) && !isCustomRSS) {
            readHandler(_url)
        } else if (isCustomRSS && isCreation) {
            setFeeds(templateFeed)
        }
    }, [rssURL]);

    useEffect(() => {
        let _position = rssSettings?.position;
        let _child = {}
        switch (_position) {
            case "FULL_SCREEN":
                _child = ({
                    component: <ScreenSides feeds={feeds} />,
                    classes: "justify-content-center align-items-center",
                })
                break;
            case "BOTTOM":
                _child = {
                    component: <Bar feeds={feeds} />,
                    classes: "justify-content-center align-items-end",
                }
                break; case "TOP":
                _child = {
                    component: <Bar feeds={feeds} />,
                    classes: "justify-content-center align-items-start",
                }
                break;
            default:
                _child = {
                    component: <></>,
                    classes: "justify-content-center align-items-center"
                }
                break;
        }

        setActivePosition(_child)
    }, [rssSettings.position, feeds]);

    return (
        <div className={`d-flex feed_container border-5 border-dark h-100 ${readRss.isLoading ? "justify-content-center align-items-center" : activePosition?.classes}`}>
            {
                readRss.isLoading ?
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    :
                    process.env.REACT_APP_APP_ENV != "Production" && isCustomRSS && !isCreation ?
                        <div className='d-flex justify-content-center flex-column align-items-center my-auto'>
                            <i className="fas fa-cogs fs-1 mb-3"></i>
                            <p className='text-center'>You can preview custom RSS on your device!</p>
                        </div>
                        :
                        feeds?.length > 0
                            ? activePosition?.component
                            :
                            <div className='d-flex justify-content-center flex-column align-items-center my-auto'>
                                <i className="fas fa-cogs fs-1 mb-3"></i>
                                <h5 className='text-center mb-2' >App instance preview </h5>
                                <p className='text-center'>Edit and save the configuration to preview this app instance </p>
                            </div>
            }
        </div >
    )
}

export default RssPreview