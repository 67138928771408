import { useSelector } from 'react-redux';
import AssignScheduleCampaign from '../CampaignAssignment/AssignScheduleForm';
import ScheduleCampaignList from '../CampaignAssignment/ScheduleCampaignList';

const CampaignComponents = () => {
    const showAssignForm = useSelector((state) => state.device.showAssignForm);

    return (
        <div className="card">
            <h5 className='text-center my-3'>Scheduled Campaigns Assigned</h5>
            <ScheduleCampaignList showBtn={false} />

            {showAssignForm ? <AssignScheduleCampaign /> : <></>}
        </div>
    )
};

export default CampaignComponents;