import { isEmpty } from "lodash";
import { defaultRssSetting } from "../../../config/constants";

const appsActions = {
    setCreateDialog: (state, action) => { state.createDialog = action.payload; },
    setPrayerEnableDialog: (state, action) => { state.prayerEnableDialog = action.payload; },
    setIsLoading: (state, action) => { state.isLoading = action.payload; },
    setData: (state, action) => { state.data = action.payload; },

    setSelectedFeed: (state, action) => { state.selectedFeed = action.payload; },
    setSelectedRss: (state, action) => { state.selectedRss = action.payload },
    setIsCreation: (state, action) => { state.isCreation = action.payload; },
    setNewSeparator: (state, action) => { state.newSeparator = action.payload; },
    setRssName: (state, action) => { state.rssName = action.payload; },

    setRssSettings: (state, action) => {
        state.rssSettings = { ...state.rssSettings, ...action.payload };
    },

    resetRssStates: (state) => {
        state.selectedRss = {};
        state.isCreation = true;
        state.rssSettings = defaultRssSetting;
        state.rssName = "Instance Name";
        state.selectedFeed = {};
    },

    rssDataHandler: (state, action) => {
        const { formData } = action.payload;

        let rss_settings = {
            ...defaultRssSetting,
            ...state.rssSettings,
            direction: state.rssSettings?.direction === "left" ? "ltr" : "rtl",
            duration: state.rssSettings?.duration ?? 20,
        };

        if (!isEmpty(state.rssSettings?.newSeparator)) {
            formData.append("separator", state.rssSettings?.newSeparator);
        }

        for (var key in rss_settings) {
            formData.append(`rss_settings[${key}]`, rss_settings[key]);
        }
    }
};

export default appsActions;
