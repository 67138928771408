import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { useGetPrayersTimes } from '../../../queries';
import { prayersNames } from '../../../config/global'
import PrayerCountdown from './PrayerCountdown';
import { Spinner } from 'react-bootstrap';
import Layout from '../../Layout/Layout';
import "../css/apps.css"

function PrayerCard() {
    const { isLoading, data: prayerData, refetch } = useGetPrayersTimes()
    const [nextPrayerIndex, setNextPrayerIndex] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        if (!isEmpty(prayerData)) {
            const nextPrayerTime = moment(prayerData?.prayer_times[prayerData?.next], 'H:mm');
            let info = {
                "location": {
                    "city": prayerData?.city,
                    "country_name": prayerData?.country_name,
                }
            };

            if (nextPrayerTime.isBefore(moment()) && prayerData?.next == 4) {
                info.next = 0
                setNextPrayerIndex(0)
            } else {
                info.next = prayerData?.next
                setNextPrayerIndex(prayerData?.next)
            }

        }
    }, [prayerData])

    return (
        <Layout>
            <div className="card">
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between   flex-shrink-0 ">
                        <div className='d-flex align-items-center '>
                            <span className="avatar-initial rounded bg-label-primary p-2 me-2">
                                <i className="fas fa-mosque"></i>
                            </span>
                            <div className='d-flex flex-column'>

                                <h5 className="fw-semibold my-0  ">Prayer App</h5>
                                <small className=' text-capitalize'>
                                    {`${prayerData?.city} / ${prayerData?.country_name}  `}
                                </small>
                            </div>
                        </div>
                        <div className='d-flex align-items-center '>
                            <button className="btn btn-primary mx-1" disabled={isLoading} onClick={() => window.history.go(-1)} > Back</button>
                            <button className="btn btn-primary " disabled={isLoading} onClick={() => navigate("/apps/prayer/campaigns")} > Campaigns</button>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        {
                            isLoading ?
                                <div className='d-flex justify-content-center align-items-center my-5'>
                                    <Spinner variant="primary" as="span" animation="border" role="status" className='mx-3' aria-hidden="true" />
                                </div>
                                :
                                prayerData?.prayer_times?.map((prayerTime, index) => {
                                    return (
                                        <div className={`col-12 d-flex my-1 p-2 rounded  ${index == nextPrayerIndex ? "next_prayer" : ""}`} key={index}>
                                            {
                                                index == nextPrayerIndex ?
                                                    <div className='d-flex flex-column align-items-center '>
                                                        <div className='fs-6 fw-bold text-dark'>Upcoming Prayer</div>
                                                        <div className='fs-6 fw-bold text-dark mt-2'>
                                                            {prayersNames[index]} &nbsp;&nbsp;&nbsp;&nbsp;  {prayerTime}

                                                            <span className='ms-3 fw-normal'>{prayerData?.prayer_times?.[index]}</span>
                                                        </div>
                                                        <div className='my-2 fs-2 fw-bolder'>
                                                            <PrayerCountdown
                                                                prayerTimes={prayerData?.prayer_times}
                                                                nextPrayerIndex={nextPrayerIndex}
                                                                setNextPrayerIndex={setNextPrayerIndex}
                                                                refetch={refetch}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='me-3 col-3 fw-bold'>{prayersNames[index]}</div>
                                                        <div className={`${index == nextPrayerIndex ? "fw-bold" : ""}`}>{prayerTime}</div>
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PrayerCard