export const CAMPAIGNS_TABLE_CONFIG =
{
    rows: 10,
    modelName: "campaign",
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
        'is_interactive': { value: 0, matchMode: 'equals' },
        'type': { value: 'campaign', matchMode: 'equal' },
    }
}

export const STREAMS_TABLE_CONFIG =
{
    rows: 8,
    modelName: "streams",
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
        'type': { value: '', matchMode: 'contains' },
    }
}

export const CUSTOM_RSS_TABLE_CONFIG =
{
    modelName: "media",
    filters: {
        'file_name': { value: '', matchMode: 'contains' },
        'type': { value: 'RSS_CUSTOM', matchMode: 'equals' },
    }
}

export const LAYOUTS_TABLE_CONFIG =
{
    modelName: "layouts",
    filters: {
        'title': { value: '', matchMode: 'contains' },
        'screens_setting': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
        'created_at': { value: '', matchMode: 'contains' },
    }
}

export const MULTI_3D_TABLE_CONFIG =
{
    modelName: "media",
    filters: {
        'file_name': { value: '', matchMode: 'contains' },
        'type': { value: 'MULTI_3D', matchMode: 'equals' },
    }
}

export const RSS_TABLE_CONFIG =
{
    modelName: "media",
    filters: {
        'file_name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'path': { value: '', matchMode: 'contains' },
        'type': { value: 'RSS', matchMode: 'equals' },
    }
}

export const INTERACTIVE_CAMPAIGN_TABLE_CONFIG =
{
    modelName: "campaign",
    filters: {
        'type': { value: 'campaign', matchMode: 'equal' },
        'name': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
        "is_interactive": { value: 1, matchMode: 'equals' },

    }
}

export const PLAYLISTS_TABLE_CONFIG =
{
    modelName: "playlist",
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
        'created_at': { value: '', matchMode: 'contains' },
        'updated_at': { value: '', matchMode: 'contains' },
    }
}

export const SUBSCRIPTIONS_TABLE_CONFIG =
{
    rows: 10,
    modelName: "subscriptions",
    filters: {}
}

export const INVITATIONS_TABLE_CONFIG =
{
    modelName: "invitations",
    filters: {
        'email': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
    }
}

export const USERS_TABLE_CONFIG =
{
    modelName: "users",
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'email': { value: '', matchMode: 'contains' },
        'phone': { value: '', matchMode: 'contains' },
        'status': { value: '', matchMode: 'contains' },
    }
}