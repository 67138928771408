import { useSelector, useDispatch } from 'react-redux';
import  { useEffect, useState } from 'react'

import { useTitle } from '../../../config/global';
import Add3DMedia from './Add3DMedia';
import Layout from '../../Layout/Layout'
import icon from '../../../assets/img/3d_primary.png'

import { FiEdit2, FiTrash2, FiPlusCircle } from "react-icons/fi";
import CreateEditCollection from './CreateEditCollection';
import { Image, Tooltip } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { MULTI_3D_TABLE_CONFIG } from '../../../Constants/datatables';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useDeleteMedia } from '../../../queries/media';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { setIsCreation } from '../../../redux/Slices/Apps/appsSlice';

function Multi3DViewerIndex() {
  useTitle('Multi-3d Viewer');

  const { totalRecords, lazyParams, setLazyParams, data, onPage, onSort, onFilter, getDataTable } = useDataTableContext();
  const openDialog = useSelector((state) => state.global.openDialog);
  const deleteRss = useDeleteMedia();
  const dispatch = useDispatch()
 
  const [selectedCollection, setSelectedCollection] = useState({});

  const fetchData = () => {
    setLazyParams(prev => ({
      ...prev, ...MULTI_3D_TABLE_CONFIG
    }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const deleteHandler = async (data) => {
    await deleteRss.mutateAsync(
      { id: data.id },
      {
        onSuccess: () => fetchData()
      });
  }

  const editRss = (rowData) => {
    setSelectedCollection(rowData)
    dispatch(setIsCreation(false))
    dispatch(toggleDialog("createEditCollection"))
  }

  const addContent = (rowData) => {
    setSelectedCollection(rowData)
    dispatch(toggleDialog("addCollectionContent"))
  }

  // Data Table Body Template
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-inline-block text-nowrap">
        {/* Add Content RSS */}
        <Tooltip target={`.add-button-${rowData.id}`} showDelay={100} className="fs-8" />
        <button className={`btn btn-sm btn-icon delete-record me-2 add-button-${rowData.id}`}
          data-pr-position="bottom"
          data-pr-tooltip="Add Content"
          onClick={() => addContent(rowData)} >
          <FiPlusCircle className="fs-5 fw-bold " />
        </button>

        {/* Update RSS */}
        <button className={`btn btn-sm btn-icon  me-2  update-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="
              Update" onClick={() => editRss(rowData)}>
          <FiEdit2 className="fs-5 fw-bold " />
        </button>

        {/* Delete RSS */}
        <button className={`btn btn-sm btn-icon delete-record me-2  delete-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="Delete" onClick={() => deleteHandler(rowData)} >
          <FiTrash2 className="fs-5 fw-bold " />
        </button>
      </div >
    );
  }

  return (
    <Layout>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title m-0 me-2 d-flex align-items-center">
            <div className="avatar-initial rounded bg-label-primary p-2 me-2">
              {<Image src={icon} width="30" />}
            </div>
            Multi-3d Viewer</h5>
          <div className=' d-flex align-items-center  mb-4'>
            <button className="btn btn-primary mx-1" disabled={getDataTable.isLoading} onClick={() => window.history.go(-1)} > Back</button>
            <button className="btn btn-primary mx-1 d-flex align-items-center"
              disabled={getDataTable.isLoading}
              onClick={() => dispatch(toggleDialog("createEditCollection"))} >
              <i class="fa-solid fa-plus me-1 fw-lighter fs-5 "></i>
              Add New 3D Media Collection
            </button>
          </div>
        </div>


        <div className='table-responsive text-nowrap'>
          <DataTable value={data?.media} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
            paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
            totalRecords={totalRecords} onPage={onPage}
            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
            onFilter={onFilter} filters={lazyParams.filters} loading={getDataTable.isLoading} className="table"
          >
            <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
            <Column field="created_at" header="Created Date" className='text-center' sortable />
            <Column body={actionBodyTemplate} exportable={false} ></Column>
          </DataTable>
        </div>
      </div>
      {
        openDialog?.createEditCollection ? <CreateEditCollection selectedCollection={selectedCollection} /> : <></>
      }
      {
        openDialog?.addCollectionContent ? <Add3DMedia selectedCollection={selectedCollection} /> : <></>
      }

    </Layout >
  )
}

export default Multi3DViewerIndex