import React from "react";
import { Link } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { useTitle, getFormErrorMessage } from "../../config/global";

import "../../assets/vendor/css/pages/page-auth.css";

import landing from "../../assets/img/illustrations/boy-with-rocket-light.png";
import logo from "../../assets/img/logo.png";

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useLogInMutation } from "../../queries";

import { useDispatch } from 'react-redux';
import { setIsAuthenticated, setUserInfo } from '../../redux/Slices/Global/globalSlice';

const Login = () => {
  useTitle("Login Page");

  const { formState: { errors }, handleSubmit, control } = useForm();
  const logIn = useLogInMutation();

  const onSubmit = async (data) => {
    await logIn.mutateAsync(data)
  };

  return (
    <>
      <div className="authentication-wrapper authentication-cover">
        <div className="authentication-inner row m-0">
          <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
            <div className="w-100 d-flex justify-content-center">
              <img src={landing} className="img-fluid" width={700} alt="landing" />
            </div>
          </div>
          <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              {/* Logo */}
              <div className="app-brand mb-5">
                <Link to="/" className="app-brand-link gap-2" style={{ margin: "auto" }} >
                  <img src={logo} className="img-fluid" width={200} alt="logo" />
                </Link>
              </div>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>

              <form onSubmit={handleSubmit(onSubmit)} className="mb-3 d-flex flex-wrap">

                {/* email */}
                <div className="mb-2 col-12">
                  <div className="field">
                    <label className="form-label">Email</label>
                    <span className="p-float-label">
                      <Controller name="email" control={control}
                        rules={{
                          required: 'Email Address is required.',
                          // pattern: {
                          //   value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          //   message: "Invalid email address",
                          // }
                        }}
                        render={({ field, fieldState }) => (
                          <InputText id={field.email} {...field} inputRef={field.ref}
                            className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                        )} />
                    </span>
                    {getFormErrorMessage('email', errors)}
                  </div>
                </div>

                {/* password */}
                <div className="mb-2 form-password-toggle col-12 my-4">
                  <div className="field me-2">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password"> Password </label>
                      <Link to="/forget/password">
                        <small>Forgot Password?</small>
                      </Link>
                    </div>
                    <span className="p-float-label">
                      <Controller name="password" control={control}
                        rules={{ required: 'Password is required.', }}
                        render={({ field, fieldState }) => (
                          <Password id={field.password} {...field} inputRef={field.ref}
                            className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            toggleMask />
                        )} />
                    </span>
                    {getFormErrorMessage('password', errors)}
                  </div>
                </div>

                <button className="btn btn-primary d-grid w-100 mt-4"> Sign in </button>
              </form>

              <p className="text-center mt-3 fs-8">
                Don't have an account!
                <Link to="/signup">
                  <span className="mx-2">Sign-up</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
