import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { generateMessage, handleResErrors } from "./utils";
import { setDisableBtn, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { setShowAssignForm } from "../redux/Slices/deviceSlice";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";

//--------------------------Get Campaign By ID--------------------------
const getDeviceScheduleCampaigns = async (id) => {
    const { data } = await axiosInstance.get(`/campaign/device/${id}/show`);
    return data.data;
}

export const useGetDeviceScheduleCampaigns = () => {
    const { showToast } = useCustomToast();
    const selectedDevice = useSelector(state => state.device.selectedDevice)

    const { isLoading, data, error, isError, refetch } = useQuery(
        ['getDeviceScheduleCampaigns', selectedDevice?.id],
        () => getDeviceScheduleCampaigns(selectedDevice?.id),
        {
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}

//--------------------------Assign Event Campaign Devices--------------------------
const assignCampaignDevices = async (payload) => {
    const { data } = await axiosInstance.post("/campaign/assign/devices", payload);
    return data?.data;
}

export const useAssignCampaignDevices = () => {
    const selectedDevice = useSelector(state => state.device.selectedDevice)
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(assignCampaignDevices, {
        onSuccess: async (data) => {
            let rejectedSatellite = data?.rejected?.satellite;
            let rejectedHDMI = data?.rejected?.hdmi;
            var errorsArr = [];

            if (_.isEmpty(rejectedHDMI) && _.isEmpty(rejectedSatellite)) {
                showToast('success', 'Assign Campaign', data?.msg);
                if (!_.isEmpty(selectedDevice))
                    dispatch(setShowAssignForm(false))
            }

            if (!_.isEmpty(rejectedHDMI)) {
                errorsArr.push(generateMessage(rejectedSatellite, "HDMI"));
            }

            if (!_.isEmpty(rejectedSatellite)) {
                errorsArr.push(generateMessage(rejectedSatellite, "satellite"));
            }

            errorsArr?.map((error) => { showToast(error.severity, error.summary, error.detail) })

        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//--------------------------Assign Schedule Campaign to Group--------------------------
const assignScheduleCampaign = async (payload) => {
    const { data } = await axiosInstance.post("/groups/assign/campaign/schedule", payload);
    return data?.data;
}

export const useAssignScheduleCampaign = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(assignScheduleCampaign, {
        onSuccess: async (responseData) => {
            dispatch(setDisableBtn(false));

            if (responseData?.error === "HDMI") {
                showToast("error", "Assign Campaign", responseData?.msg);
            } else if (_.isEmpty(responseData?.error)) {
                dispatch(toggleDialog("assignDialog"));
                showToast("success", "Assign Campaign", responseData?.msg);
            }
        },
        onError: (error) => {

            handleResErrors(error, showToast)
        }
    })
}

//--------------------------Replace and Assign Schedule Campaign to Group--------------------------
const replaceScheduleCampaign = async (payload) => {
    const { data } = await axiosInstance.post("/groups/schedule/campaign/replace", payload);
    return data?.data;
}

export const useReplaceScheduleCampaign = () => {
    const { showToast } = useCustomToast();
    const openDialog = useSelector(state => state.global.openDialog)
    const dispatch = useDispatch()

    return useMutation(replaceScheduleCampaign, {
        onSuccess: async (responseData) => {
            showToast('success', 'Assign Campaign', responseData?.msg);
            if (openDialog?.assignDialog)
                dispatch(toggleDialog("assignDialog"))

            if(openDialog?.assignScheduleCampaigns)
                dispatch(toggleDialog("assignScheduleCampaigns"))
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//-------------------------- Assign Schedule Campaign And Ignore Devices with Conflict--------------------------
const ignoreDevicesWithConflict = async (payload) => {
    const { data } = await axiosInstance.post("/groups/schedule/campaign/rest", payload);
    return data?.data;
}

export const useIgnoreDevicesWithConflict = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(ignoreDevicesWithConflict, {
        onSuccess: async (responseData) => {
            showToast('success', 'Assign Campaign', responseData?.msg);
            dispatch(toggleDialog("assignDialog"))
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//-------------------------- Assign Schedule Campaign to Device--------------------------
const assignScheduleCampaignDevice = async (payload) => {
    const { data } = await axiosInstance.post("/campaign/device/assign", payload);
    return data?.data;
}

export const useScheduleCampaignDevice = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()
    const queryClient = useQueryClient();

    return useMutation(assignScheduleCampaignDevice, {
        onSuccess: async (responseData) => {
            queryClient.invalidateQueries('getDeviceScheduleCampaigns');
            showToast('success', 'Assign Campaign', responseData?.msg);
            dispatch(setShowAssignForm(false))
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//-------------------------- Delete Schedule Campaign--------------------------
const deleteScheduleCampaign = async (payload) => {
    const { data } = await axiosInstance.delete(`/campaign/device/${payload?.id}/delete`);
    return data?.data;
}

export const useDeleteScheduleCampaign = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(deleteScheduleCampaign, {
        onSuccess: async (responseData) => {
            queryClient.invalidateQueries('getDeviceScheduleCampaigns');
            showToast('success', 'Delete Schedule Campaign', responseData?.msg);
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}
