import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector, useDispatch } from "react-redux";

import { setDisableBtn, toggleDeleteDialog, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { handleGroupsData, handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { setCompanyGroups, setEditRotation } from "../redux/Slices/deviceSlice";

// -------------------------Get Grouped Devices-------------------------//
const getGroupedDevices = async ({ queryKey }) => {
    const [, isOnline] = queryKey;
    let url = `/groups/company${!_.isNull(isOnline) ? `?is_online=${isOnline}` : ""}`

    const { data } = await axiosInstance.get(url);

    return handleGroupsData(data);
}

export const useGetGroupedDevices = () => {
    const { showToast } = useCustomToast();
    const { deviceFilters } = useSelector((state) => state.device);
    const dispatch = useDispatch();

    let { isLoading, data, error, isError } = useQuery(
        ['getGroupedDevices', deviceFilters?.isOnline ?? null],
        getGroupedDevices,
        {
            onSuccess: (data) => {
                let _options = []
                Object.values(data).forEach(group => {
                    _options = [..._options, {
                        name: group.name,
                        id: group.group_id
                    }]
                })
                dispatch(setCompanyGroups(_options))
                dispatch(setDisableBtn(false))
            },
        }
    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { isLoading, data };
}

// -------------------------Get Devices List-------------------------//
const getDevicesList = async ({ queryKey }) => {
    const [, pageNum,] = queryKey;
    let url = `/company/devices${!_.isNull(pageNum) ? `?page=${pageNum}&per_page=7` : ""}`

    const { data } = await axiosInstance.get(url);

    return data;

}

export const useGetDevicesList = (pageNum = null, enabled = true) => {
    const { showToast } = useCustomToast();

    let { isLoading: devicesLoading, data, isFetching, refetch: refetchDevices } = useQuery(
        ['getDevicesList', pageNum],
        getDevicesList,
        {
            enabled,
            onError: (error) => showToast("error", "Fetching Date", error.response?.data?.message)

        }
    );

    return { devicesLoading, data, refetchDevices, isFetching};
}

// -------------------------Get Countries-------------------------//
const getCountries = async () => {
    const { data } = await axiosInstance.get("/countries/all",);

    const _countries = data?.geonames.map(country => {
        return {
            label: country.countryName,
            value: country.countryCode,
        }
    });

    return _countries;
}

export const useGetCountries = () => {
    const { showToast } = useCustomToast();

    let { isLoading, data, error, isError } = useQuery('getCountries', getCountries);

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { isLoading, data };
}

// -------------------------Get Cites-------------------------//
const getCites = async ({ queryKey }) => {
    const { data } = await axiosInstance.get(`/countries/${queryKey[1].countryName}/cities/all`);

    const _cities = data?.geonames.filter(city => city.adminName1 != "")
        .map(city => {
            return {
                label: city.toponymName,
                value: city.toponymName,
                lat: city.lat,
                lng: city.lng
            }
        });

    return _cities;
}

export const useGetCites = () => {
    const { showToast } = useCustomToast();
    const { selectedCountry } = useSelector((state) => state.device);

    let { isLoading, data, error, isError } = useQuery(
        ['getCites', { countryName: selectedCountry.value }],
        getCites,
        { enabled: !!selectedCountry?.value }
    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { isLoading, data };
}

// -------------------------Confirm Device-------------------------//
const confirmDevice = async (payload) => {
    const { data } = await axiosInstance.post("/device/confirm", payload);

    return data.data;
}

export const useConfirmDeviceMutation = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { showToast } = useCustomToast();

    return useMutation(confirmDevice, {
        onSuccess: async (data) => {
            queryClient.invalidateQueries('getUserSubscription');
            queryClient.invalidateQueries('getGroupedDevices');
            dispatch(toggleDialog("confirmDialog"));
            showToast('success', 'Device Confirmation', 'Device Confirmed Successfully!');
        },
        onError: (error) => {
            showToast('error', 'Device Confirmation', error.response?.data?.message);
        }
    })
}

//------------------------------Get Device Info-----------------------------
const getDeviceInfo = async (payload) => {
    const { data } = await axiosInstance.get(`/device/${payload.id}/info`);
    return data.data;
}

export const useGetDeviceInfMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(getDeviceInfo, {
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Get Device Info-----------------------------
const getDevice = async ({ queryKey }) => {
    const [, id] = queryKey
    const { data } = await axiosInstance.get(`/device/${id}/info`);
    return data.data;
}

export const useGetDevice = (id) => {
    const { showToast } = useCustomToast();

    let { isLoading: groupsLoading, data, error, isError, refetch: refetchGroup } = useQuery(
        ['getDevice', id],
        getDevice,

    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { groupsLoading, data, refetchGroup };

}

// -------------------------Update Device-------------------------//
const updateDevice = async (payload) => {
    const { data } = await axiosInstance.put(`/device/${payload?.id}/update`, payload.data);

    return data.data;
}

export const useUpdateDeviceMutation = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(updateDevice, {
        onSuccess: async (data) => {
            queryClient.invalidateQueries('getGroupedDevices');
            dispatch(toggleDialog("updateDevice"));
            showToast('success', 'Device Updating', data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// -------------------------Flush Device-------------------------//
const flushDevice = async (payload) => {
    const { data } = await axiosInstance.get(`/device/${payload?.id}/screen`);
    return data.data;
}

export const useFlushDevice = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(flushDevice, {
        onSuccess: async (data) => {
            showToast('success', 'Play-Pause Device', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// -------------------------Play-Pause Device-------------------------//
const palyPauseDevice = async (payload) => {
    const { data } = await axiosInstance.post(`/device/${payload?.id}/play/pause`, payload.data);
    return data.data;
}

export const usePalyPauseDevice = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(palyPauseDevice, {
        onSuccess: async (data) => {
            showToast('success', 'Play-Pause Device', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// -------------------------Restart Device-------------------------//
const restartDevice = async (payload) => {
    const { data } = await axiosInstance.get(`/device/${payload?.id}/restart`);
    return data.data;
}

export const useRestartDevice = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(restartDevice, {
        onSuccess: async (data) => {
            showToast('success', 'Restart Device', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// -------------------------Delete Device-------------------------//
const deleteDevice = async (payload) => {
    const { data } = await axiosInstance.delete(`/device/${payload?.id}`);
    return data.data;
}

export const useDeleteDevice = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(deleteDevice, {
        onSuccess: async (data) => {
            showToast('success', 'Delete Device', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
            queryClient.invalidateQueries('getUserSubscription');
            dispatch(toggleDeleteDialog("device"));
            dispatch(setDisableBtn(false))

        },
        onError: (error) => {
            handleResErrors(error, showToast)
            dispatch(setDisableBtn(false))
        }
    })
}

//------------------------------Mute-Unmute Device-----------------------------
const muteUnmuteDevice = async (payload) => {
    const { data } = await axiosInstance.post(`/device/${payload?.id}/mute`, payload?.data);
    return data.data;
}

export const useMuteUnmuteDevice = () => {
    const { showToast } = useCustomToast();

    return useMutation(muteUnmuteDevice, {
        onSuccess: async (data) => {
            showToast('success', "Mute-Unmute Action", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Rotate Device-----------------------------
const rotateDevice = async (payload) => {
    const { data } = await axiosInstance.post(`/device/${payload?.id}/rotate`, payload?.data);
    return data.data;
}

export const useRotateDeviceMutation = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(rotateDevice, {
        onSuccess: async (data) => {
            dispatch(setEditRotation(false));
            showToast('success', "Rotate Action", data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Rotate Device-----------------------------
const updateDeviceGroup = async (payload) => {
    const { data } = await axiosInstance.put(`/device/update/group`, payload?.data);
    return data.data;
}

export const useUpdateDeviceGroup = () => {
    const { showToast } = useCustomToast();

    return useMutation(updateDeviceGroup, {
        onSuccess: async (data) => {
            showToast('success', 'Group Updating', data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
