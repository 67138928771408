import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import _ from 'lodash';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Button } from "primereact/button";

import { getFormErrorMessage } from '../../../config/global';
import { setDeviceFilters } from '../../../redux/Slices/deviceSlice';
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';

function Filter() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { formState: { errors }, handleSubmit, control, reset } = useForm();
    const deviceFilters = useSelector((state) => state.device.deviceFilters);
    const disableBtn = useSelector((state) => state.global.disableBtn);

    const options = [
        { name: 'On-line', code: 1 },
        { name: 'Off-line', code: 0 },
    ];

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true))
        dispatch(setDeviceFilters({
            status: data.status,
            name: data.name,
            isOnline: data.is_online
        }, () => {
            queryClient.invalidateQueries('getGroupedDevices');
        }))
    };

    const clearFilter = () => {
        reset()
        dispatch(setDeviceFilters({ isOnline: null }))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center justify-content-start w-full">
                {/* is_online input */}
                <div className="col-5 me-1 d-flex flex-column align-items-start ">
                    <Controller name="is_online" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) =>
                        (
                            <Dropdown id={field.name} {...field}
                                onChange={(e) => field.onChange(e.value)}
                                options={options}
                                optionLabel="name"
                                optionValue="code"
                                inputRef={field.ref}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                placeholder="On-Line / Off-Line"
                            />
                        )
                        } />
                    {getFormErrorMessage('is_online', errors)}
                </div>

                <div className="col-7 d-flex">
                    <Button className="py-2 px-4 me-1 w-auto p-button-sm" label="Filter" loading={disableBtn} />

                    {
                        !_.isNull(deviceFilters.isOnline) ?
                            <>
                                <Tooltip target=".clear-button" className="fs-8" />
                                <Button className="py-2 w-auto p-button-secondary p-button-outlined p-button-sm" label="Reset" disabled={disableBtn} onClick={clearFilter} />
                            </>
                            :
                            <></>
                    }
                </div>
            </form>
        </>
    )
}

export default Filter