import { useSelector } from 'react-redux';
import { useMemo } from 'react'

import { ColorsInputs, DurationInput, PositionInput } from './Inputs';
import { BottomOption } from './Positions/Bar';
import FontSelector from "./FontSelector"

function RssSettings({ errors, control }) {
    const { rssSettings } = useSelector(state => state.apps);
    const isBar = useMemo(
        () => ["BOTTOM", "TOP"].includes(rssSettings?.position),
        [rssSettings?.position]
    )

    return (
        <div className='d-flex flex-wrap  scroll_div rss_wrapper'>
            <PositionInput control={control} errors={errors} />

            {!isBar ? <DurationInput control={control} errors={errors} /> : <></>}

            <FontSelector control={control} errors={errors} />

            <ColorsInputs />

            {isBar ? <BottomOption errors={errors} control={control} /> : <></>}
        </div>
    )
}

export default RssSettings