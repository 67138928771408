import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button'
import { Spinner } from 'react-bootstrap'

import { setCreateDialog, setPrayerEnableDialog } from '../../../../redux/Slices/Apps/appsSlice';
import { useGetPrayerCampaigns } from '../../../../queries/apps';
import CreatePrayerCampaign from './CreatePrayerCampaign'
import PrayerEnableDialog from './PrayerEnableDialog'
import { useTitle } from '../../../../config/global'
import CampaignCard from './CampaignCard'
import Layout from '../../../Layout/Layout'

function PrayerCampaigns() {
    useTitle("Prayer Campaigns");

    const { createDialog, prayerEnableDialog } = useSelector(state => state.apps);
    const { data: prayerCampaigns, isLoading, refetch } = useGetPrayerCampaigns();
    const dispatch = useDispatch()

    return (
        <Layout>
            <div className="d-flex justify-content-between mb-4">
                <div className="col-8 col-md-6 d-flex">
                    <Button label="Back" className='py-2 back-button'
                        data-pr-position="bottom" data-pr-tooltip="Go back"
                        onClick={() => window.history.go(-1)} disabled={isLoading} />
                </div>
                <div className='d-none d-md-flex'>
                    <Button label="Enable Prayer" className='py-2 me-2' onClick={() => dispatch(setPrayerEnableDialog(true))} disabled={isLoading} />
                    <Button
                        label="Custom Campaign"
                        className='fs_md_6  py-2'
                        disabled={isLoading || prayerCampaigns.length == 0 || prayerCampaigns.length == 6}
                        onClick={() => dispatch(setCreateDialog(true))}
                    />
                </div>
            </div>

            {
                isLoading ?
                    <div className="d-flex justify-content-center my-5 py-5">
                        <Spinner variant="primary" as="span" size='lg' animation="border" role="status" className='mx-3' aria-hidden="true" />
                    </div>
                    :
                    prayerCampaigns.length == 0 ?
                        <div className='card my-5  py-5 text-center fs-5 '>Enable prayer on your device please!</div>
                        :
                        prayerCampaigns.map((item) => {
                            return <CampaignCard item={item} key={item.id} refetch={refetch} />
                        })
            }

            {createDialog && <CreatePrayerCampaign prayerCampaigns={prayerCampaigns} />}
            {prayerEnableDialog && <PrayerEnableDialog />}

        </Layout >
    )
}
export default PrayerCampaigns