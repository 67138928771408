import { Controller, useForm } from "react-hook-form";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


const LayoutNameController = ({
    layoutName, 
    setLayoutName,
    titleError,
}) => {
    const { control, handleSubmit } = useForm({ defaultValues: { layoutName: layoutName?.name, }, });

    const onTitleSubmit = (data) => {
        setLayoutName({ isEditing: false, name: data.layoutName });
    };

    return (
        <>
            {layoutName?.isEditing ? (
                <>
                    <form className="flex mt-1 w-100" onSubmit={handleSubmit(onTitleSubmit)}  >
                        <div className="flex flex-col w-full">
                            <Controller name="layoutName" control={control} rules={{
                                required: "Title is required",
                                minLength: { value: 3, message: "Title must be at least 3 characters" },
                            }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText
                                            {...field}
                                            placeholder="My custom layout"
                                            className={`p-2 border rounded-lg  ${fieldState.error ? "border-red-500" : "border-gray-300"
                                                }`}
                                        />
                                        {fieldState.error && (
                                            <span className="text-red-500 text-sm">{fieldState.error.message}</span>
                                        )}
                                        {titleError && <span className="text-red-500 text-sm">{titleError}</span>}
                                    </>
                                )}
                            />
                        </div>
                        <Button label="" icon="fa-solid fa-check" className="p-0 me-2 bg-transparent text-black border-transparent " type="submit" />
                    </form>

                </>
            ) : (
                <>
                    <span className="pt-2">{layoutName?.name}</span>
                    <Button
                        label=""
                        icon="fa-solid fa-pen"
                        className="p-0 pt-1 me-2 bg-white text-black border-white"
                        onClick={() =>
                            setLayoutName((prev) => ({ ...prev, isEditing: true }))
                        } // Toggle to edit mode
                    />
                </>
            )}
        </>
    );
};

export default LayoutNameController;
