
import React from 'react'
import { isEmpty } from 'lodash';

import { IoNewspaperOutline } from 'react-icons/io5'
import white3DIcon from "../../../../assets/img/3d_white.png"

import MediaList from '../../../Apps/Multi3dViwer/MediaList';
import RssList from './RssList';
import { Image } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedApp } from '../../../../redux/Slices/Global/globalSlice';
import { Message } from 'primereact/message';

function Apps() {
    const dispatch = useDispatch();
    const { selectedApp, layer, companyDetails } = useSelector((state) => state.global);

    const apps = [
        {
            featureKey: "rss_feed",
            title: "RSS Feeds ",
            icon: <i className="fa-solid fa-rss fs-3"></i>,
            visible: layer === "BANNER" ? window.location.pathname.includes("campaign") : (window.location.pathname.includes("layout")),

        },
        {
            featureKey: "banner",
            title: "Custom RSS",
            icon: <IoNewspaperOutline className='fs-3' />,
            visible: layer === "BANNER" ? window.location.pathname.includes("campaign") : window.location.pathname.includes("layout"),

        },
        {
            featureKey: "3d",
            title: "Multi-3D Media",
            icon: <Image src={white3DIcon} width="30" />,
            visible: companyDetails?.package?.features?.["3d"] && layer == "MAIN",
        }
    ]

    const allVisibleFalse = apps.every(obj => obj.visible === false);

    const components = {
        rss_feed: <RssList />,
        banner: <RssList mediaType={"RSS_CUSTOM"} />,
        "3d": <MediaList path={"/media/multi_3d"} />,

    }
    return (
        <>
            {
                (allVisibleFalse) ?
                    <div className='w-100 flex justify-center items-center h-full'>
                        <Message className="fw-bold fs-3 p-4 w-75 mt-5" severity="warn" text="There were no compatible Apps added for this campaign's layer!" />
                    </div>
                    :
                    isEmpty(selectedApp) ?
                        apps.map((app, index) => {
                            return app?.visible ? (
                                <div className={`co1-12 d-flex  align-items-center text-dark  bg-label-secondary px-3 py-2 cursor-pointer ${index + 1 == apps.length ? "" : "border-bottom"}`}
                                    onClick={() => { dispatch(setSelectedApp({ title: app?.title, featureKey: app?.featureKey })) }}
                                    key={index}>
                                    <div className='col-1 p-3 bg-dark text-white me-2 rounded-3 flex justify-center items-center'  >{app?.icon}</div>
                                    <div class="fw-bold">{app?.title}</div>
                                </div>
                            )
                                :
                                <></>
                        })
                        :
                        components?.[selectedApp?.featureKey]
            }
        </>
    )
}

export default Apps