import { useMutation, useQuery, useQueryClient } from "react-query";

import axiosInstance from "../config/Axios";
import { useDispatch } from "react-redux";

import { useCustomToast } from "../pages/Contexts/ToastContext";
import { useGlobalContext } from "../pages/Contexts/GlobalContext";
import { setProfileImage } from "../redux/Slices/Global/globalSlice";

const getUserSubscription = async () => {
    const { data } = await axiosInstance.get(`/company/details`);

    return data?.data;
}

export const useGetUserSubscription = () => {
    const { showToast } = useGlobalContext();

    let { isLoading, data, error, isError } = useQuery('getUserSubscription', getUserSubscription);

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { isLoading, data };
}


const updateUserProfile = async (updatedData) => {
    const formData = new FormData();
  
    formData.append("name", updatedData.name);
    if (updatedData.profile_img) formData.append("profile_img", updatedData.profile_img);
    formData.append("phone", updatedData.phone);
  
    const { data } = await axiosInstance.post(`/users/update`, formData); 
    return data;
  };
  
  export const useUpdateUserProfile = () => {
    const queryClient = useQueryClient();
    const globalContext = useGlobalContext();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();  
  
    const mutation = useMutation(updateUserProfile, {
      onSuccess: async (data) => {
        dispatch(setProfileImage(data?.data?.image));  
  
        localStorage.setItem("name", data?.data?.name);
        localStorage.setItem("image", data?.data?.image);
        localStorage.setItem("phone", data?.data?.phone);
  
        showToast("success", "Update User Profile", "Profile Updated Successfully!");
        queryClient.invalidateQueries("getUserSubscription"); 
      },
      onError: (error) => {
        showToast("error", "Update User Profile", error?.response?.data?.message || "An error occurred.");
      },
    });
  
    return mutation;
  };
  