import React, { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';

const VideoJS = (props) => {
    const videoNode = useRef(null);
    const [player, setPlayer] = useState(null);

    useEffect(() => {
        if (videoNode.current) {
            const _player = videojs(videoNode.current, props);
            if (_player.readyState() < 1) {
                // wait for loadedmetdata event
                // _player.one("loadedmetadata", onLoadedMetadata);
            }
            else {
                // metadata already loaded
                var t = setInterval(function () {
                    if (_player.readyState() > 0) {
                        var duration = _player.duration();
                        if (duration != "Infinity") {
                        }
                        clearInterval(t);
                    }
                }, 500);

                setPlayer(_player);
                return () => {
                    if (player !== null) {
                        player.dispose();
                    }
                };
            }
        }
    }, [])

    return (
        <div data-vjs-player>
            <video ref={videoNode} className="video-js"></video>
        </div>
    );


}

export default VideoJS;