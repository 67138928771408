import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

import { useRotateDeviceMutation } from '../../../../queries';

const options = [
    { name: '0 Degree', code: '0' },
    { name: '90 Degree', code: '90' },
    { name: '180 Degree', code: '180' },
    { name: '270 Degree', code: '270' },
];

function Rotate({ degree, setDegree }) {
    const { id } = useParams();
    const rotateDevice = useRotateDeviceMutation();

    const degreeHandler = async (e) => {
        const newDegree = e.value;
        setDegree(newDegree);
        await rotateDevice.mutateAsync({
            id: id,
            data: { rotation: newDegree }
        })
    }

    return (
        <>
            <div className="card-body pt-2 pb-3">
                <div className="card-header p-0 mb-2 ">
                    <div className=' d-flex align-items-center justify-content-between mb-2'>
                        <h5 className="m-0 me-2 ">Rotate</h5>
                        <small>{rotateDevice?.isLoading ? "updating..." : ""}</small>
                    </div>
                    <Dropdown className='w-100' value={degree} disabled={rotateDevice?.isLoading}
                        options={options} onChange={degreeHandler}
                        optionLabel="name" optionValue="code"
                        placeholder="Select a Rotate Degree"
                    />
                </div>
            </div>
        </>
    )
}

export default Rotate