import { COMMON_ASPECT_RATIOS } from "./layoutConstans";

export const convertToPx = (areas, currentAspectRatio) => {
  let maxWidth, maxHeight;

  if (currentAspectRatio) {
    const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;

    if (COMMON_ASPECT_RATIOS[aspectKey]) {
      maxWidth = COMMON_ASPECT_RATIOS[aspectKey].width;
      maxHeight = COMMON_ASPECT_RATIOS[aspectKey].height;
    } else {
      maxWidth = currentAspectRatio.width;
      maxHeight = currentAspectRatio.height;
    }
  }

  return areas.map((area) => {
    if (area.unit === "%") {
      return {
        ...area,
        width: Math.round((area.width / 100) * maxWidth),
        height: Math.round((area.height / 100) * maxHeight),
        start_margin: Math.round((area.start_margin / 100) * maxWidth),
        top_margin: Math.round((area.top_margin / 100) * maxHeight),
        unit: "px",
      };
    }
    return area;
  });
};

export const handleSwitchUnit = (areas, currentAspectRatio) => {
  let maxWidth, maxHeight;

  if (currentAspectRatio) {
    const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;

    if (COMMON_ASPECT_RATIOS[aspectKey]) {
      maxWidth = COMMON_ASPECT_RATIOS[aspectKey].width;
      maxHeight = COMMON_ASPECT_RATIOS[aspectKey].height;
    } else {
      maxWidth = currentAspectRatio.width;
      maxHeight = currentAspectRatio.height;
    }
  }

  const convertedAreas = areas.map((area) => {
    if (area.unit === "px") {
      return {
        ...area,
        width: (area.width / maxWidth) * 100,
        height: (area.height / maxHeight) * 100,
        start_margin: (area.start_margin / maxWidth) * 100,
        top_margin: (area.top_margin / maxHeight) * 100,
        unit: "%",
      };
    }
    return area;
  });

  return convertedAreas;
};


export const updateOriginalScreens = (originalScreens, sanitizedAreas) => {
    let theUpdatedVersion = [];

    if (originalScreens.length === sanitizedAreas.length) {
        theUpdatedVersion = originalScreens.map((screen, index) => ({
            id: screen.id,
            name: sanitizedAreas[index].name,
            width: sanitizedAreas[index].width,
            height: sanitizedAreas[index].height,
            start_margin: sanitizedAreas[index].start_margin,
            top_margin: sanitizedAreas[index].top_margin,
        }));
    }

    else if (originalScreens.length > sanitizedAreas.length) {
        theUpdatedVersion = originalScreens
            .slice(0, sanitizedAreas.length)
            .map((screen, index) => ({
                id: screen.id,
                name: sanitizedAreas[index].name,
                width: sanitizedAreas[index].width,
                height: sanitizedAreas[index].height,
                start_margin: sanitizedAreas[index].start_margin,
                top_margin: sanitizedAreas[index].top_margin,
            }));
    }

    else if (originalScreens.length < sanitizedAreas.length) {
        theUpdatedVersion = originalScreens.map((screen, index) => ({
            id: screen.id,
            name: sanitizedAreas[index].name,
            width: sanitizedAreas[index].width,
            height: sanitizedAreas[index].height,
            start_margin: sanitizedAreas[index].start_margin,
            top_margin: sanitizedAreas[index].top_margin,
        }));

        theUpdatedVersion = [...theUpdatedVersion];
    }

    return theUpdatedVersion;
};