import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'
import { useForm } from "react-hook-form";

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { TitleInput } from '../../SharedComponents/RSS/Inputs';
import RssSettings from '../../SharedComponents/RSS/RssSettings';
import RssPreview from '../../SharedComponents/RSS/RssPreview';

import { useCreateCustomRssMutation, useUpdateCustomRssMutation } from '../../../queries/rss';
import { resetRssStates, rssDataHandler } from '../../../redux/Slices/Apps/appsSlice';
import { defaultRssSetting } from '../../../config/constants';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function CreateCustomRssDialog() {
    const { formState: { errors }, handleSubmit, control, reset } = useForm({ shouldUnregister: true, mode: 'onChange' });
    const { isCreation, selectedRss } = useSelector(state => state.apps);
    const { openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const createRss = useCreateCustomRssMutation();
    const updateRss = useUpdateCustomRssMutation();

    useEffect(() => {
        if (!isCreation) {
            reset({
                ...selectedRss?.rss_settings,
                position: selectedRss?.rss_settings?.position,
                title: selectedRss?.title,
                font_size: Number(selectedRss?.rss_settings?.font_size),
                direction: selectedRss?.direction === "ltr" ? "left" : "right",
            })
        }
        else {
            reset(defaultRssSetting)
        }

        return () => { dispatch(resetRssStates()) }
    }, [selectedRss])

    const createHandler = async (data) => {
        const formData = new FormData();
        formData.append("name", data?.title);
        formData.append("title", data?.title);

        dispatch(rssDataHandler({ formData, data }));

        if (isCreation)
            await createRss.mutateAsync(formData)
        else
            await updateRss.mutateAsync({ id: selectedRss?.id, data: formData })
    }

    const onClose = () => {
        dispatch(toggleDialog("customRssCreation"))
    }

    return (
        <Dialog visible={openDialog.customRssCreation}
            style={{ width: '65%', overFlow: "hidden" }}
            breakpoints={{ '1200px': '95vw' }}
            header={`Custom RSS ${isCreation ? "Creation" : "Editing"}`}
            modal className="p-fluid" draggable={false}
            onHide={onClose}
        >
            <form onSubmit={handleSubmit(createHandler)} className="d-flex flex-wrap">
                <div className="col-12 flex">
                    <div className={`col-7 auto-column my-5 px-3 `} >
                        <RssPreview isCustomRSS={true} isCreation={isCreation} />
                    </div>
                    <div className="col-5 d-flex flex-wrap p-4 border-start border-gray scroll_div rss_wrapper ">
                        <TitleInput control={control} errors={errors} />
                        <RssSettings errors={errors} control={control} reset={reset} />
                    </div>
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createRss.isLoading || updateRss.isLoading} onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label={isCreation ? "Create" : "Update"} loading={createRss.isLoading || updateRss.isLoading} />
                </div>
            </form>

        </Dialog >
    )
}

export default CreateCustomRssDialog