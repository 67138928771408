import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useDeleteScheduleCampaign, useGetDeviceScheduleCampaigns } from '../../../../queries';
import { setSelectedDevice, setSelectedGroup, setShowAssignForm } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import AssignScheduleForm from './AssignScheduleForm';
import ScheduleCampaignList from './ScheduleCampaignList';
import { Spinner } from 'react-bootstrap';

function ScheduleCampaignDialog() {
    const dispatch = useDispatch();
    const openDialog = useSelector((state) => state.global.openDialog);
    const showAssignForm = useSelector((state) => state.device.showAssignForm);

    const { data: deviceCampaigns, isLoading } = useGetDeviceScheduleCampaigns();
    const deleteScheduleCampaign = useDeleteScheduleCampaign();

    const onClose = () => {
        dispatch(setSelectedGroup(""));
        dispatch(setSelectedDevice({}));
        dispatch(toggleDialog("assignScheduleCampaigns"))
    }

    return (
        <>
            <Dialog visible={openDialog.assignScheduleCampaigns}
                style={{ width: '42%', minHeight: "30vh" }}
                breakpoints={{ '960px': '95vw' }}
                header="Scheduled Campaigns Assigned"
                modal className="p-fluid"
                onHide={onClose}
            >
                 <ScheduleCampaignList onClose={onClose}/>
                

                {/* Add New Scheduled Campaign */}
                {showAssignForm ? <AssignScheduleForm /> : <></>}

            </Dialog >
        </>
    )
}

export default ScheduleCampaignDialog