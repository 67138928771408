import { configureStore } from "@reduxjs/toolkit";

import deviceReducer from "./Slices/deviceSlice";
import layoutReducer from "./Slices/layoutSlice";
import playlistReducer from "./Slices/playlistSlice";
import globalReducer from "./Slices/Global/globalSlice";
import appsReducer from "./Slices/Apps/appsSlice";

export const store = configureStore({
  reducer: {
    //  reducers will be here
    device: deviceReducer,
    global: globalReducer,
    layout: layoutReducer,
    playlist: playlistReducer,
    apps: appsReducer,
  },
  devTools: true,
});
