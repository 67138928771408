import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Outlet } from "react-router-dom";

import { useGetUserSubscription } from "../queries/profile";

import { useSelector, useDispatch } from 'react-redux';
import { setCompanyDetails, setLayerOptions, setSubscriptionInfo, setUserInfo } from '../redux/Slices/Global/globalSlice';

const CompanyDetailsRouter = () => {
    const { data: useSubscription } = useGetUserSubscription();

    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { rerenderSubscription } = useSelector((state) => state.global);


    useEffect(() => {
        let res = useSubscription

        dispatch(setCompanyDetails(res));
        dispatch(setUserInfo(res?.user));

        let packageName = res?.package?.title.toLowerCase() == "professional";
        let didEnded = new Date() > new Date(res?.subscription?.end_date);
        let repeated = res?.subscription?.period == "ONE_YEAR";
        let areFree = res?.package?.is_free == 1;

        dispatch(setSubscriptionInfo({
            isProPackage: packageName,
            isFinished: didEnded,
            isYearly: repeated,
            isFree: areFree,
        }));

        //Set allowed Layers for campaign and playlist setup
        dispatch(setLayerOptions(
            res?.package?.features?.multiple_layer.map((layer) => ({
                name: layer.charAt(0).toUpperCase() + layer.slice(1).toLowerCase(),
                code: layer.toUpperCase(),
            }))
        ))
    }, [useSubscription]);

    useEffect(() => {
        if (rerenderSubscription) {
            queryClient.invalidateQueries('getUserSubscription');
        }
    }, [rerenderSubscription]);

    return <Outlet />;

}

export { CompanyDetailsRouter }