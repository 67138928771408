import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';

import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button';

import { setIsPrayerCampaign } from '../../../../redux/Slices/Global/globalSlice';
import { useDeleteRowMutation } from '../../../../queries/dataTable';

function CampaignCard({ item, refetch }) {
    const dispatch = useDispatch()
    const deleteCampaign = useDeleteRowMutation();
    const navigate = useNavigate();

    const deleteHandle = async () => {
        await deleteCampaign.mutateAsync({
            id: item.id,
            table: 'campaign'
        }, {
            onSuccess: () => refetch()
        })
    }

    const goToSetup = () => {
        dispatch(setIsPrayerCampaign(true))
        sessionStorage.setItem('is_default_prayer', item.name == "default" ? JSON.stringify(true) : JSON.stringify(false));
        navigate(`/campaigns/${item.id}/setup`)
    }

    return (
        <div className="card my-2">
            <div className="card-body d-flex align-items-center">
                <div className="col-1 text-center">
                    <div className=' me-3 p-3 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                        <i className="fas fa-bullhorn fs-3 text-white mx-auto"></i>
                    </div>
                </div>
                <div className="col-8 text-capitalize fs-5">{item.name}</div>
                <div className="col-3 text-end fs-5">
                    {/* Delete Button  */}
                    {
                        !(item.name == "default") ?
                            <>
                                <Tooltip target={`.delete-button-${item.id}`} content="Delete" position='bottom' autoHide={true} className="fs-8" />
                                <Button
                                    className={`p-0 p-button-rounded p-button-secondary p-button-text p-button-lg delete-button-${item.id}`}
                                    icon="bx bx-trash text-light"
                                    onClick={deleteHandle}
                                    loading={deleteCampaign.isLoading} />
                            </> : <></>
                    }

                    {/* Setup Button  */}
                    <Tooltip target={`.setup-button-${item.id}`} content="Setup" position='bottom' autoHide={true} className="fs-8" />
                    <Button
                        className={`p-0 p-button-rounded p-button-secondary p-button-text p-button-lg setup-button-${item.id}`}
                        icon="bx bx-slideshow text-light"
                        onClick={goToSetup}
                        disabled={deleteCampaign.isLoading} />
                </div>
            </div>
        </div>
    )
}

export default CampaignCard