import React, { useState, useEffect } from 'react'

import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';

import { useGalleryContext } from '../../../Contexts/GalleryContext'
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function MoveMediaFolder({ data }) {
    const dispatch = useDispatch();
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const { setRerender } = useGalleryContext();
    const { showToast } = useCustomToast();

    const [subFolders, setSubFolders] = useState({});
    const [folders, setFolders] = useState([{
        id: "root",
        folder_name: "root"
    }]);
    const [openIcon, setOpenIcon] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedFolder, setSelectedFolder] = useState("");


    const renderHeader = (name) => {
        return (
            <div className='d-flex align-items-center' >
                <span className=' fs-6 me-2 text-gray d-flex align-items-center'>
                    Move  <i className="ms-1 fas fa-chevron-right"></i>
                </span>
                <h3 className='text-dark'>{name}</h3>
            </div>
        )
    }

    useEffect(() => {
        if (selectedFolder == "")
            dispatch(setDisableBtn(true))
        axiosInstance.get(`/folder/all`)
            .then((result) => {
                var data = result?.data?.data?.folders;
                setFolders(data)
                data.map((item) => { setOpenIcon(prev => ({ ...prev, [item.id]: false })) })
                setLoading(false);
            })
    }, [])

    useEffect(() => {
        if (selectedFolder != "")
            dispatch(setDisableBtn(false))

        return () => {
            dispatch(setDisableBtn(false))
        };
    }, [selectedFolder])



    const showSubFolder = (e) => {
        setLoading(true);
        var parentFolderId = e.target.getAttribute("data-id");
        setOpenIcon(prev => ({ ...prev, [parentFolderId]: !openIcon[parentFolderId] }))

        if (!openIcon[parentFolderId] && !subFolders[parentFolderId]) {
            axiosInstance.get(`/folder/all?parent_folder_id=${parentFolderId}`)
                .then((result) => {
                    data = result?.data?.data;
                    setSubFolders(prev => ({ ...prev, [data.parentID]: data.folders }))

                })
        }
        setLoading(false);
    };

    const FolderItem = ({ item }) => {
        return <>
            <div className={`col-12 ps-3 py-2 cursor-pointer folder_row ${selectedFolder == item.id && "selected_folder"}`} onClick={() => setSelectedFolder(item.id)}>
                {
                    openIcon[item.id] ?
                        <>
                            <i className="fas fa-minus-circle cursor-pointer fs-8 me-2" data-id={item.id} onClick={(e) => showSubFolder(e)}></i>
                            <i className="fas fa-folder-open text-primary me-1"></i>
                        </>
                        :
                        <>
                            <i className="fas fa-plus-circle cursor-pointer fs-8 me-2" data-id={item.id} onClick={(e) => showSubFolder(e)}></i>
                            <i className="fas fa-folder text-primary me-1"></i>
                        </>
                }
                <span className=' cursor-pointer' on onClick={() => setSelectedFolder(item.id)}> {item.folder_name}</span>
            </div>
            {
                subFolders[item.id] && openIcon[item.id] &&
                subFolders[item.id].map((item, index) => {
                    return (
                        <div className="col-12 ps-5" key={`${item.id}-${index}`}>
                            <FolderItem item={item} />
                        </div>
                    )
                })
            }
        </>
    }

    const moveMediaFolder = () => {
        axiosInstance.post(
            `/folder/media/move`,
            {
                type: data.hasOwnProperty("path") ? "media" : "folder",
                id: data.id,
                destination_id: selectedFolder,
            }
        ).then((result) => {
            setSelectedFolder("");
            showToast('success', 'Move', result?.data?.data?.msg);
            dispatch(toggleDialog("moveMediaFolder"))
            setRerender(true)
        })
    }

    useEffect(() => {

        return () => {
        };
    }, []);

    return (
        <>
            <Dialog header={renderHeader(data.file_name)}
                visible={openDialog.moveMediaFolder}
                onHide={() => { dispatch(toggleDialog("moveMediaFolder")) }}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '50vw' }}>
                {
                    loading ?
                        <div className="d-flex justify-content-center align-items-center my-5 py-5">
                            <Spinner as="span" animation="border" size="lg" role="status" className='mx-3 text-primary' aria-hidden="true" />
                        </div>
                        :
                        <div className='m-4 d-flex flex-column'>
                            <div className={`col-12 p-2 folder_row cursor-pointer ${selectedFolder == "root" && "selected_folder"}`} onClick={() => setSelectedFolder("root")}>
                                <i className="fas fa-folder text-primary me-1"></i>
                                <span className=' cursor-pointer' onClick={() => setSelectedFolder("root")}> root </span>
                            </div>
                            {
                                folders.map((item, index) => {
                                    return (
                                        <div className='d-flex flex-column align-items-center' key={`${item.id}-${index}`}>
                                            {
                                                data.id != item.id &&
                                                <FolderItem item={item} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" disabled={disableBtn} data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(toggleDialog("moveMediaFolder"))}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={disableBtn} onClick={() => moveMediaFolder()} >Move</button>
                </div>
            </Dialog>
        </>
    )
}

export default MoveMediaFolder