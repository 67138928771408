import { useSelector } from 'react-redux';

function DeviceInfo() {
    const deviceInfo = useSelector((state) => state.device.selectedDevice);

    var diskInfo = (deviceInfo.device_info?.storage_total - deviceInfo.device_info?.storage_available) ?? "--" + "/" + deviceInfo.device_info?.storage_total ?? "--"
    var ramInfo = (deviceInfo.device_info?.heap_total - deviceInfo.device_info?.heap_available) ?? "--" + "/" + deviceInfo.device_info?.heap_total ?? "--"
    
    return (
        <div className="card-body">
            <div className="user-avatar-section">
                <div className="d-flex align-items-center flex-column">
                    <div className="user-info text-center">
                        <h4 className="mb-2">Device Details</h4>
                        <span className="badge bg-label-secondary"> {deviceInfo.status}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <div className="d-flex align-items-start me-4 mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-check bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{deviceInfo.device_info?.network ?? "--"}</h5>
                            <span>Network</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-start mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-customize bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{deviceInfo.device_info?.cpu_core ?? "--"}</h5>
                            <span>CPU Core</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-start me-4 mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-check bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{ramInfo ?? "--"}<sup>MB</sup></h5>
                            <span>RAM</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="d-flex align-items-start mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-customize bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{diskInfo ?? "--"}<sup>MB</sup></h5>
                            <span>Disk</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-start me-4 mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-check bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{deviceInfo.rotation ?? "--"}</h5>
                            <span>Rotation</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-start mt-3 gap-3">
                        <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-customize bx-sm"></i></span>
                        <div>
                            <h5 className="mb-0">{deviceInfo.device_info?.last_active ?? "--"}</h5>
                            <span>Last Activated</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceInfo