import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./css/mediaTable.css";

import FirebaseMediaListener from "../Services/FirebaseMediaListener";
import DeleteConfirmation from "../SharedComponents/DeleteConfirmation";
import CreateFolderPopup from "./components/Folder/CreateFolder";
import MediaFilter from "../SharedComponents/MediaFilter";
import UploadPopup from "./components/UploadPopup/UploadPopup";
import BreadCrumb from "./components/MediaLayout/BreadCrumb";
import Layout from "../Layout/Layout";

import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";

import { useGalleryContext } from "../Contexts/GalleryContext";
import { useCustomToast } from "../Contexts/ToastContext";
import FolderDragDrop from "./components/FolderDragDrop/FolderDragDrop";
import axiosInstance from "../../config/Axios";
import { useTitle } from "../../config/global";

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDeleteDialog } from '../../redux/Slices/Global/globalSlice';
const emptyFolder = {
  id: "",
  name: "",
}

function MediaDataTable() {
  useTitle("Media");

  const deleteDialog = useSelector((state) => state.global.deleteDialog);
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();
  const {
    multiSelectCheck, setMultiSelectCheck,
    selectedItem, setSelectedItem,
    rerender, setRerender,
    idsList, setIdsList,
    layout, setLayout,
  } = useGalleryContext();

  const [currentFolder, setCurrentFolder] = useState(emptyFolder);
  const [breadCrumb, setBreadCrumb] = useState(JSON.parse(localStorage.getItem("bread_crumb")) ?? []);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id } = useParams();

  //fetch data  when mount component
  useEffect(() => {
    setCurrentFolder(emptyFolder);
    getData();
  }, []);

  useEffect(() => {    
    if (rerender) {
      getData()
    };
  }, [rerender]);

  const DEFAULT_FILTERS = {
    section: "MEDIA",
    searchInput: "",
    typeFilter: "",
    minDuration: "",
    maxDuration: "",
    sortOrder: "desc",
    minSize: "",
    maxSize: "",
    folder_id: "",
    resetTriggered: false,

  };

  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const prepareFilters = () => {
    const activeFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        activeFilters[key] = value;
      }
    });

    if (filters.section === "MEDIA") {
      activeFilters.folder_id = id || "";
    }
    return activeFilters;
  }

  useEffect(() => {
    if (typeof id == "undefined") {
      setBreadCrumb([]);
      setCurrentFolder(emptyFolder)
    }

    getData();

  }, [id]);

  const getData = () => {
    const activeFilters = prepareFilters();
    setLoading(true);

    axiosInstance
      .get(`/folder/media`, { params: activeFilters })
      .then((res) => {
        setMedia(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.values(error.response.data.errors).forEach((err) => {
            showToast("error", "Fail", err[0]);
          });
        }
      });
  };

  useEffect(() => {
    if (filters.resetTriggered) {
      getData();
      setFilters((prevFilters) => ({ ...prevFilters, resetTriggered: false })); // Reset the flag
    }
  }, [filters]);

  //Layout option
  const justifyOptions = [
    { icon: "pi pi-align-justify", value: "list" },
    { icon: "pi pi-th-large", value: "grid" },
  ];

  const layoutOptionsTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  // Multi media/folder delete handler
  useEffect(() => {

    if (multiSelectCheck) {
      var ids = media.map((item) => item.id);
      setIdsList(ids);
    } else {
      setIdsList([]);
    }
  }, [multiSelectCheck]);

  const deleteHandler = () => {
    axiosInstance.post(`/folder/media/delete`, { ids: idsList, })
      .then((result) => {
        showToast('success', 'Deleted', result?.data?.data?.msg);
        dispatch(toggleDeleteDialog("media"));
        dispatch(setDisableBtn(false))
        setRerender(true);
        setIdsList([]);
        setMultiSelectCheck(false);
      })
      .catch((error) => {
        showToast('error', 'Play-Pause Action', error?.response?.data?.message);
      });
  };

  return (
    <Layout>
      <div className="dataview-demo ">
        <div className="d-flex flex-column justify-content-center bg-white rounded-3 media_container px-auto mx-1 ">
          <div className="col-12 px-3 py-2 py-sm-0 d-flex justify-content-between align-items-sm-center align-items-start btn_container border-bottom">
            <div className="d-flex flex-sm-row flex-column">
              <SelectButton value={layout} options={justifyOptions} className="m-1  mb-3 mb-sm-0" onChange={(e) => setLayout(prev => e.value == null ? prev : e.value)} itemTemplate={layoutOptionsTemplate} optionLabel="value"
              />
              {
                breadCrumb.length != 0 &&
                <BreadCrumb setMedia={setMedia} getData={getData} breadCrumb={breadCrumb} setBreadCrumb={setBreadCrumb} setCurrentFolder={setCurrentFolder} />
              }
            </div>
            <div className=" d-flex justify-content-end align-items-center align-items-start my-sm-3">

              {!multiSelectCheck ?
                <>
                  <CreateFolderPopup
                    currentFolder={currentFolder}
                    setCurrentFolder={setCurrentFolder}
                    setBreadCrumb={setBreadCrumb}
                    breadCrumb={breadCrumb}
                    setRerender={setRerender} />
                  <UploadPopup currentFolder={currentFolder} setRerender={setRerender} content="media" />
                </>
                :
                <>
                  <Tooltip target=".delete-button" className="fs-8" />
                  <button
                    className="btn  btn-icon delete-button  px-2 "
                    onClick={() => { setSelectedItem(""); dispatch(toggleDeleteDialog("media")); }}
                    data-pr-position="bottom"
                    data-pr-tooltip="delete"
                  >
                    <i className="bx bx-trash fs-4 fw-light text-danger me-4"></i>
                  </button>
                  <div className="border-end border-start px-3 d-flex align-items-center">
                    <i className="fas fa-folder fw-light fs-4 pe-2"></i>
                    <span className="fs-5 ">{idsList.length}</span>
                  </div>
                </>
              }
              <div className="d-flex  px-2">
                <Checkbox inputId="binary" checked={multiSelectCheck} onChange={(e) => setMultiSelectCheck(e.checked)} className="me-2 main_checkbox media_checkbox" />
              </div>
            </div>
          </div>
          {/* Filter Section */}
          <MediaFilter
            filters={filters}
            setFilters={setFilters}
            onApplyFilters={getData}
          />

          {/* <DragDrop /> */}
          <div className="row justify-content-center align-items-start py-3">
            {loading ?
              <ProgressSpinner style={{ width: "60px", height: "60px" }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
              :
              media.length === 0 ?
                <h5 className="py-5 text-center w-100">
                  No media assigned yet!
                </h5>
                :
                <>
                  {
                    layout === "list" && (
                      <div className="media_list  mx-2 py-2 media position-relative  media_banner">
                        <div className="col-4"></div>
                        {/* <div className="col-md-3 col-sm-3 col-4 text-center text-truncate pe-4"></div> */}
                        <div className="col-md-1 col-2 fs-6 px-0 text-center fst-italic d-none d-sm-block">Duration </div>
                        <div className="col-2 fs-6 px-0 text-center fst-italic  d-none d-sm-block">Kind</div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic">Size</div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic d-none d-xl-block">Created Date</div>
                        <div className="col-3"> </div>
                      </div>
                    )
                  }
                  <div className="cards_container row justify-content-center align-items-start mx-auto scroll_div px-0">

                    <FolderDragDrop
                      data={media}
                      getData={getData}
                      setCurrentFolder={setCurrentFolder}
                      currentFolder={currentFolder}
                      setBreadCrumb={setBreadCrumb}
                      breadCrumb={breadCrumb} />
                  </div>
                </>
            }
          </div>
        </div>
      </div>

      {
        (deleteDialog.media && selectedItem == "") &&
        <DeleteConfirmation
          deleteHandler={deleteHandler}
          itemName={`${idsList.length} ${idsList.length == 1 ? "file" : "files"}  `}
          dialogKey="media"
        />
      }

      <FirebaseMediaListener
        path={"/Companies/" + localStorage.getItem("company_id") + "/actions"}
        getData={getData}
        currentFolder={currentFolder} />

    </Layout>
  );
}

export default MediaDataTable;
