import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import axiosInstance from '../../../../../config/Axios';
import { layoutColor, unitOptions } from '../../../../../config/layoutConstans';
import { convertToPx, handleSwitchUnit } from '../../../../../config/layoutHelper';

import AddArea from './Controls/AddArea';
import AreaControls from "./Controls/AreaControls";
import DynamicContainer from './Controls/DynamicContainer';
import LayoutNameController from './Controls/LayoutNameController';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setReloadTrigger } from '../../../../../redux/Slices/layoutSlice';


function TemplateCustomizationPopUp() {
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();

    const openDialog = useSelector((state) => state.global.openDialog);
    const selectedTemplate = useSelector((state) => state.layout.selectedTemplate);
    const [unit, setUnit] = useState(unitOptions[0]); // Default to percentage
    const newAreas = selectedTemplate.screens_setting.map(area => ({
        ...area,
        unit: "%"
    }));
    const [layoutName, setLayoutName] = useState({ isEditing: false, name: "" });
    const { control, handleSubmit } = useForm();
    const [overlappingAreas, setOverlappingAreas] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [areas, setAreas] = useState(newAreas);

    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [titleError, setTitleError] = useState("");
    const [selectedArea, setSelectedArea] = useState({});
    const [isMaximized, setIsMaximized] = useState(false);

    useEffect(() => {

        setLayoutName({ isEditing: true, name: "" });
    }, [])

    useEffect(() => {
        if (unit.value == "px") {
            // convertToPx()//convert area to px
            const areasConverted = convertToPx(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
        if (unit.value == "%") {
            // handleSwitchUnit()//convert area to % 
            const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
    }, [unit])

    const handleCreateTemplate = () => {
        if (layoutName.isEditing || !layoutName.name.trim()) {
            setTitleError("Title is required , save it to proceed");
            return;
        }

        setTitleError("");
        const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
        const sanitizedAreas = areasConverted.map(({ unit, ...rest }) => rest);

        axiosInstance.post(`/layout/create`,
            {
                "title": layoutName?.name,
                "screens_setting": sanitizedAreas,
                "type": "CUSTOM",
                "aspect_ratio_id": currentAspectRatio.id

            })
            .then((result) => {
                dispatch(toggleDialog("TemplateCustomizationPopUp"));
                dispatch(setReloadTrigger(true))
                showToast('success', "Layout Creation", "Layout Template created successfully!");

            })
            .catch((error) => {
                dispatch(setDisableBtn(false));
            })
    };
    const handleUnitChange = (e) => {
        setUnit(e.target)
    }

    const handleMaximize = () => { setIsMaximized((prev) => !prev); };

    const customHeader = (
        <div className="flex justify-between items-center w-full">
            <span className="text-lg font-semibold">Customize Your Layout</span>
            <Button
                icon={isMaximized ? "pi pi-window-minimize" : "pi pi-window-maximize"}
                className="p-button-text p-2"
                onClick={handleMaximize}
            />
        </div>
    );
    return (
        <Dialog visible={openDialog.TemplateCustomizationPopUp}
            breakpoints={{ '960px': '95vw' }}
            header={customHeader}
            modal
            className={`p-fluid w-full ${isMaximized ? 'p-dialog-maximized' : 'p-dialog-default md:w-[100%] lg:w-[75%] h-[85%]'}`}
            onHide={() => dispatch(toggleDialog("TemplateCustomizationPopUp"))}
        >
            <div className="overflow-auto h-full">

                <div className="flex flex-col items-center  mt-1 h-full w-[100%] ">
                    <div className="w-100 mt-3 mb-3 pl-5 pr-5 flex flex-col lg:flex-row gap-10 ">
                        {/* Preview Area */}
                        <DynamicContainer
                            isMaximized={isMaximized}
                            selectedArea={selectedArea}
                            setSelectedArea={setSelectedArea}
                            areas={areas}
                            setAreas={setAreas}
                            unit={unit}
                            layoutColor={layoutColor}
                            currentAspectRatio={currentAspectRatio}
                            setCurrentAspectRatio={setCurrentAspectRatio}

                        />

                        {/* Controls */}
                        <div className="flex flex-col w-full lg:w-1/2  mr-3">
                            <div className="mt-3 flex  w-100 lg:w-1/2">
                                <div className="w-75">
                                    <label className="text-sm font-medium mb-1">Title</label>
                                    <div className="d-flex mb-3 ">
                                        <LayoutNameController layoutName={layoutName} setLayoutName={setLayoutName} titleError={titleError} />

                                    </div>
                                </div>

                                <div className="w-25">
                                    <label className="text-sm font-medium mb-1">Unit</label>
                                    <Dropdown
                                        value={unit?.value || unitOptions[0]}
                                        options={unitOptions}
                                        optionLabel="label"
                                        optionValue="value"
                                        className="mb-3 mt-1 w-100"
                                        onChange={handleUnitChange}
                                        placeholder="Select a Unit"
                                    />
                                </div>

                            </div>
                            <div className="flex flex-col w-100 lg:w-1/2">
                                <AreaControls
                                    selectedArea={selectedArea}
                                    setSelectedArea={setSelectedArea}
                                    areas={areas}
                                    unit={unit}
                                    setAreas={setAreas}
                                    currentAspectRatio={currentAspectRatio}
                                    isSaveDisabled={isSaveDisabled}
                                    overlappingAreas={overlappingAreas}
                                    setOverlappingAreas={setOverlappingAreas}
                                    setIsSaveDisabled={setIsSaveDisabled}
                                />
                                {overlappingAreas.length > 0 && (
                                    <div className=" flex mt-3 text-white font-medium mb-3 alert bg-orange-300" role="alert">
                                        <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                        <div className="ml-3">
                                            These zones are being overlapped by some other zone:{" "}
                                            {overlappingAreas.join(", ")}.
                                            <br />
                                            Please adjust the zones to avoid overlapping.
                                        </div>
                                    </div>
                                )}
                                <AddArea unit={unit} currentAspectRatio={currentAspectRatio} setAreas={setAreas} areas={areas} />
                                {/* Action Buttons */}
                                <div className="mt-3 flex justify-end gap-3 mb-3">
                                    <Button label="Save" className={"pt-1 pb-1 me-2"} disabled={isSaveDisabled} onClick={() => handleCreateTemplate()} />
                                    <Button label="Cancel" className={"pt-1 pb-1  bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => dispatch(toggleDialog("TemplateCustomizationPopUp"))} />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Dialog>
    )
}

export default TemplateCustomizationPopUp