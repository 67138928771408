import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, } from 'react-bootstrap'
import { Tooltip } from 'primereact/tooltip';
import { SplitButton } from 'primereact/splitbutton';

import DeviceLastLoginListener from '../../../Services/DeviceLastLoginListener';
import FirebaseDeviceListener from '../../../Services/FirebaseDeviceListener';
import Rotation from './Rotation';

import { setStepsBack, setIsPrayerCampaign, toggleDeleteDialog, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { setSelectedDevice, setSelectedCampaign } from '../../../../redux/Slices/deviceSlice';
import {
    useMuteUnmuteDevice,
    useGetDeviceInfMutation,
    useFlushDevice,
    usePalyPauseDevice,
    useRestartDevice
} from '../../../../queries/devices'
import { useGetCampaign } from '../../../../queries/campaigns';
import { useMemo } from 'react';

function DeviceCard({ data }) {
    const { companyDetails } = useSelector((state) => state.global);
    const onlineDevices = useSelector((state) => state.device.onlineDevices);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [realtimeData, setRealtimeData] = useState({
        "id": "",
        "name": "",
        "is_playing": "",
        "screen_angle": "",
        "is_muted": "",
    })

    const { isLoading, data: campaignInfo, refetch } = useGetCampaign({ enabled: false, id: realtimeData?.id });
    const muteUnmuteDevice = useMuteUnmuteDevice();
    const palyPauseDevice = usePalyPauseDevice();
    const restartDevice = useRestartDevice();
    const getDeviceInfo = useGetDeviceInfMutation();
    const flushDevice = useFlushDevice();

    // Delete Handler
    const confirmDeleteDevice = () => {
        dispatch(setSelectedDevice(data));
        dispatch(toggleDeleteDialog("device"));
    }

    const openEditDialog = () => {
        dispatch(setSelectedDevice(data));
        dispatch(toggleDialog("updateDevice"));
    }

    const openAssignDialog = async () => {
        dispatch(setSelectedDevice(data));
        // Get previous selected campaign for the device
        await getDeviceInfo.mutateAsync(
            { id: data.id },
            {
                onSuccess: (data) => {
                    const prevCampaign = data?.campaigns[0]?.id;
                    dispatch(setSelectedCampaign(prevCampaign));
                    dispatch(toggleDialog("assignCampaignsDropDown"));
                }
            }
        )
    }

    const openAssignScheduleCampaignDialog = () => {
        dispatch(setSelectedDevice(data));
        dispatch(toggleDialog("assignScheduleCampaigns"));
    }

    const playPause = async (deviceID, action) => {
        await palyPauseDevice.mutateAsync({
            id: deviceID,
            data: { action: action.toLowerCase() }
        })
    }

    const muteUnMute = async (deviceID, action) => {
        await muteUnmuteDevice.mutateAsync({
            id: deviceID,
            data: { action: action.toLowerCase() }
        })
    }

    // Go to the campaign that is playing now on the device
    const goToCampaign = () => {
        if (onlineDevices[data.serial_name]) {
            dispatch(setStepsBack(-2))
            refetch();
            if (!isLoading) {
                dispatch(setIsPrayerCampaign(false))
                sessionStorage.setItem('is_default_prayer', JSON.stringify(false));

                if (campaignInfo?.is_interactive)
                    navigate(`/interactive/campaign/${realtimeData?.id}/setup`)
                else
                    navigate(`/campaigns/${realtimeData?.id}/setup`)
            }
        }
    }

    const channelHandler = () => {
        let selected_device = {
            id: data?.id,
            serial_name: data.serial_name,
            online: onlineDevices[data.serial_name],
            name: data.name
        }
        dispatch(setSelectedDevice(selected_device));
        dispatch(toggleDialog("createCampaign"));
        navigate("/campaigns/list");
    }

    const flushHandler = async () => {
        await flushDevice.mutateAsync({ id: data?.id })
    }

    const restartHandler = async () => {
        await restartDevice.mutateAsync({ id: data?.id })
    }

    const goToInfo = (deviceID) => {
        dispatch(setSelectedDevice(data));
        navigate(`/devices/${deviceID}/show`)
    }



    const items = [
        {
            label: <span className="fs-6 ">Assign campaign</span>,
            icon: 'bx bx-up-arrow-alt me-2 text-light',
            command: () => {
                openAssignDialog()
            }
        },
        {
            label: <span className="fs-6 "> Assign schedule </span>,
            icon: 'bx bx-time-five me-2 text-light',
            command: () => {
                openAssignScheduleCampaignDialog()
            },
            visible: companyDetails?.package?.features?.advance_content_schedule
        },
        {
            label: (
                <span className='flex flex-col'>Assign channel
                    {
                        data?.device_info?.satellite_is_available != 0 ?
                            <small className='text-[10px] text-[red] font-bold'>Doesn't support satellite</small>
                            :
                            <></>
                    }
                </span>
            ),
            icon: 'bx bx-tv me-2 text-light',
            command: () => {
                channelHandler()
            },
            visible: companyDetails?.package?.features?.media_type?.includes("satellite"),
            disabled: data?.device_info?.satellite_is_available != 0
        },
        {
            label: <span className="fs-6 ">Edit</span>,
            icon: 'pi pi-pencil text-light me-2',
            command: () => {
                openEditDialog()
            }
        },
        {
            label: <span className="fs-6 ">{realtimeData.is_playing ? 'Pause' : "Play"}</span>,
            icon: realtimeData.is_playing ? "bx bx-pause-circle me-2 text-light" : "bx bx-play-circle me-2 text-light",
            command: () => {
                playPause(data.id, realtimeData.is_playing ? "pause" : "play")
            }
        },
        {
            label: <span className="fs-6 ">{realtimeData.is_muted ? 'UnMute' : "Mute"}</span>,
            icon: realtimeData.is_playing ? "bx bx-volume-mute me-2 text-light" : "bx bx-volume-full me-2 text-light",
            command: () => {
                muteUnMute(data.id, realtimeData.is_muted ? "unmute" : "mute")
            }
        },
        {
            label: <span className="fs-6 ">Flush</span>,
            icon: 'bx bx-help-circle me-2 text-light',
            command: () => {
                flushHandler()
            }
        },
        {
            label: <span className="fs-6 ">Restart</span>,
            icon: 'fas fa-power-off me-2 text-light',
            command: () => {
                restartHandler()
            }
        },
        {
            label: 'Device Info',
            icon: 'bx bx-slideshow me-2 text-light',
            command: () => {
                goToInfo(data.id)
            }
        }
    ];

    return (
        <>
            <Card className='my-2 d-flex flex-row flex-nowrap align-items-center py-3 pe-3 ' style={{ backgroundColor: '#f5f5f9' }}>
                <div className="col-1 text-center">
                    <i className="menu-icon tf-icons bx bx-desktop  fs-1 text-secondary mx-auto "></i>
                </div>
                <div className='col-1 d-flex flex-column'>
                    <h6 className='my-1 fw-bold'>{data.name == "" ? "---" : data.name}</h6>
                </div>

                <div className={`col-3 d-flex ${realtimeData.name == "" ? "" : "cursor-pointer"}`} onClick={() => goToCampaign()}>
                    <div className={`my-1 fw-bold ${realtimeData.name == "" ? "" : "cursor-pointer playing_now_container"} py-1 mx-4 px-2 w-100`} onClick={() => goToCampaign()}>
                        <div className='fs-10 text-light text-uppercase'>now playing</div>
                        <div className='d-flex align-items-center '>
                            <i className="fas fa-bolt fs-8 me-1"></i>
                            <span> {realtimeData.name == "" ? "---" : realtimeData.name}</span>
                        </div>
                    </div>
                </div>

                <div className='col-1 d-flex'>
                    <span className={`text-center p-2 badge ${onlineDevices?.[data?.serial_name] ? "bg-label-success" : "bg-label-danger"}`}>
                        {onlineDevices?.[data?.serial_name] ? "On-line" : "Off-line"}
                    </span>
                </div>
                <div className='col-1 d-flex'>
                    <span className={`text-center p-2  badge ${data.status == "ACTIVE" ? "bg-label-success" : "bg-label-danger"} `}>{data.status}</span>
                </div>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                    <Rotation rowData={data} realtimeData={realtimeData} />

                </div>

                <div className='col-3 d-flex justify-content-end'>
                    {/* Delete */}
                    <Tooltip target={`.delete-button-${data.id}`} showDelay={100} className="fs-8" />
                    <button className={`btn btn-sm btn-icon delete-button-${data.id}`}
                        data-pr-position="bottom" data-pr-tooltip="Delete"
                        onClick={() => confirmDeleteDevice()} >
                        <i className="bx bx-trash text-light"></i>
                    </button>

                    <SplitButton
                        label={null}
                        model={items}
                        className="p-button-text p-button-primary p-button-sm mr-2 mb-2 p-button-secondary device_actions ms-3"
                        buttonClassName="d-none"
                        dropdownIcon="fas fa-ellipsis-v"

                    />
                </div>
            </Card>

            {/* Listen to campaign changes on device */}
            <FirebaseDeviceListener
                setRealtimeData={setRealtimeData}
                path={"Devices/" + data.serial_name + "/DeviceState"}
            />

            <DeviceLastLoginListener
                serialName={data.serial_name}
                path={"Devices/" + data.serial_name + "/lastLogin"} />

        </>
    )
}

export default DeviceCard