import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  selectedDevice: {},
  showAssignForm: false,
  companyCampaigns: {},
  companyGroups: {},
  onlineDevices: {},
  deviceFilters: {
    status: "",
    name: "",
    isOnline: null
  },
  rerender: false,
  loading: true,
  editRotation: false,
  device: {
    name: null,
    serial_name: "",
  },
  selectedCampaign: "",
  selectedCountry: {},
  selectedGroup: {},
  isDevice: false
};

//the actions
const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setSelectedDevice(state, action) {
      state.selectedDevice = _.isEmpty(action.payload) ? {} : { ...state.selectedDevice, ...action.payload };
    },
    setShowAssignForm(state, action) {
      state.showAssignForm = action.payload;
    },
    setCompanyCampaigns(state, action) {
      state.companyCampaigns = action.payload;
    },
    setCompanyGroups(state, action) {
      state.companyGroups = action.payload;
    },
    setOnlineDevices(state, action) {
      state.onlineDevices = { ...state.onlineDevices, ...action.payload };
    },
    setDeviceFilters(state, action) {
      state.deviceFilters = { ...state.deviceFilters, ...action.payload };
    },
    setRerender(state, action) {
      state.rerender = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEditRotation(state, action) {
      state.editRotation = action.payload;
    },
    setDevice(state, action) {
      state.device = action.payload;
    },
    setSelectedCampaign(state, action) {
      state.selectedCampaign = action.payload;
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload;

    },
    setIsDevice(state, action) {
      state.isDevice = action.payload;

    }
  },
});

export const {
  setSelectedDevice,
  setShowAssignForm,
  setCompanyCampaigns,
  setOnlineDevices,
  setDeviceFilters,
  setRerender,
  setLoading,
  setEditRotation,
  setDevice,
  setSelectedCampaign,
  setSelectedCountry,
  setCompanyGroups,
  setSelectedGroup,
  setIsDevice
} = deviceSlice.actions;

export default deviceSlice.reducer;
