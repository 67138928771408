import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";


import CampaignComponents from "./Setup/CampaignComponents";
import AssignCampaign from "./Setup/AssignCampaign";
import LiveScreen from "./Setup/LiveScreen";
import DeviceInfo from "./Setup/DeviceInfo";
import SerialNav from "./Setup/SerialNav";
import Layout from "../../Layout/Layout";
import Rotate from "./Setup/Rotate";
import Map from "./Setup/Map";

import { useGetCampaigns, useGetDevice } from "../../../queries";
import { setSelectedDevice } from "../../../redux/Slices/deviceSlice";
import { useTitle } from "../../../config/global";

const DeviceShow = () => {
  useTitle("Devices");
  useGetCampaigns();

  const selectedDevice = useSelector((state) => state.device.selectedDevice);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useGetDevice(id);


  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [degree, setDegree] = useState(0);

  useEffect(() => {
    dispatch(setSelectedDevice(data));
    setSelectedCampaign(() => _.isEmpty(data?.campaigns) ? "" : data?.campaigns?.[0]?.id);
    setDegree(data?.rotation || 0);
  }, [data]);

  return (
    <Layout>
      <SerialNav />
      <div className="row flex-wrap">
        <div className="col-md-12 col-lg-8 order-md-2 order-lg-1  mb-4">
          <LiveScreen />
        </div>
        <div className="col-md-12 col-lg-4 order-md-1 order-lg-2">
          <div className="row">
            <div className="col-md-12  mb-4">
              <div className="card d-flex   flex-column">
                <div className="col-12 col-sm-8 col-md-12 col-lg-12">
                  <DeviceInfo deviceInfo={selectedDevice} /></div>
                <hr />
                <div className="d-flex flex-column flex-sm-row flex-lg-column col-12 col-sm-4 col-md-12">
                  <Rotate degree={degree} setDegree={setDegree} />
                  <AssignCampaign selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 order-md-4 mb-4 order-lg-3">
          <Map deviceId={id} />
        </div>
        <div className="col-md-12 col-lg-4 order-md-3 mb-4 order-lg-4">
          <CampaignComponents />
        </div>
      </div>
    </Layout>
  );
};
export default DeviceShow;