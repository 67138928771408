export const FONTS = {
    ar: [
        { label: 'Alexandria', value: 'Alexandria' },
        { label: 'Zain', value: 'Zain' },
        { label: 'Beiruti', value: 'Beiruti' },
        { label: 'Cairo Play', value: 'Cairo Play' },
        { label: 'Noto Sans Arabic', value: 'Noto Sans Arabic' },
        { label: 'Rubik', value: 'Rubik' },
        { label: 'Tajawal', value: 'Tajawal' },
        { label: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic' },
        { label: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic' },
        { label: 'Noto Kufi Arabic', value: 'Noto Kufi Arabic' },
        { label: 'Noto Naskh Arabic', value: 'Noto Naskh Arabic' },
    ],
    en: [
        { label: 'Roboto', value: 'Roboto' },
        { label: 'Open Sans', value: 'Open Sans' },
        { label: 'Montserrat', value: 'Montserrat' },
        { label: 'Poppins', value: 'Poppins' },
        { label: 'Lato', value: 'Lato' },
        { label: 'Oswald', value: 'Oswald' },
        { label: 'Merriweather', value: 'Merriweather' },
        { label: 'Playfair Display', value: 'Playfair Display' },
        { label: 'Kanit', value: 'Kanit' },
        { label: 'Outfit', value: 'Outfit' },
    ]
};


export const FONT_SIZES = [
    { label: 'Small', value: 20 },
    { label: 'Medium', value: 25 },
    { label: 'Large', value: 30 }
];

export const LANGUAGES = [
    { label: "Arabic", value: "ar" },
    { label: "English", value: "en" }
];

// RSS position dropdown options
export const POSITION_OPTIONS = [
    { label: "Full Screen", value: "FULL_SCREEN" },
    { label: "Bottom", value: "BOTTOM" },
    { label: "Top", value: "TOP" },
];

export const BROADCAST_TYPES = [
    { label: "One Way", value: "ONE_WAY" },
    { label: "Two way", value: "TWO_WAY" },
    { label: "Multiple", value: "MULTIPLE" },
];

export const BROADCAST_SETTINGS = {
    ONE_WAY: {
        is_camera_enable: 0,
        is_mic_enable: 0
    },
    TWO_WAY: {
        is_camera_enable: 1,
        is_mic_enable: 1
    },
    MULTIPLE: {
        is_camera_enable: 0,
        is_mic_enable: 1
    }
}
