import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playlistDetails: {
    total_duration: 0,
  },
};

const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    setPlaylistDetails(state, action) {
      state.playlistDetails = action.payload;
    },
  },
});

export const { setPlaylistDetails } = playlistSlice.actions;

export default playlistSlice.reducer;
