import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'

import { useGetGroupedDevices } from '../../queries/devices';
import { toggleDialog } from '../../redux/Slices/Global/globalSlice';
import { useTitle } from '../../config/global';
import "./css/device.css";

import AssignCampaignToGroup from './Component/CampaignAssignment/AssignCampaignToGroup';
import GroupsOrderingDialog from './Component/Grouping/GroupsOrderingDialog';
import CreateGroup from './Component/Grouping/CreateGroup';
import Confirm from './Component/Confirm';
import Filter from './Component/Filter';
import Layout from '../Layout/Layout'
import Group from './Component/Grouping/Group'

import { Spinner } from 'react-bootstrap';
import { useGetCampaigns } from '../../queries';

function Index() {
    useTitle("Devices")

    const { openDialog, disableBtn } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const { data: groups, isLoading } = useGetGroupedDevices();
    useGetCampaigns();

    return (
        <Layout>
            <div className="d-flex flex-column align-items-start ">
                <div className="col-12 d-flex mb-4 align-items-start justify-content-end">
                    <div className="col-7">
                        <Filter />
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("orderDialog"))} disabled={disableBtn}>
                            <span className="tf-icons bx bx-device"></span> Reorder Groups
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("assignDialog"))} disabled={disableBtn}>
                            <span className="tf-icons bx bx-device" ></span> Assign Campaign
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("createGroupDialog"))} disabled={disableBtn}>
                            <span className="tf-icons bx bx-device"></span> New Group
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("confirmDialog"))} disabled={disableBtn}>
                            <span className="tf-icons bx bx-device"></span> New Device
                        </button>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className='my-5 h-100 w-100 d-flex align-items-center justify-content-center'>
                            <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
                        </div>
                        :
                        _.isEmpty(groups) ?
                            <div className='card d-flex justify-content-center text-center w-100 my-5 p-5'>
                                No results found!
                            </div>
                            :
                            <div className='col-12'>
                                <Group devicesGroup={groups} />
                            </div>
                }
            </div>
            {openDialog.confirmDialog ? <Confirm /> : <></>}
            {openDialog.createGroupDialog ? <CreateGroup /> : <></>}
            {openDialog.assignDialog ? <AssignCampaignToGroup /> : <></>}
            {openDialog.orderDialog ? <GroupsOrderingDialog groups={groups} /> : <></>}
        </Layout>
    )
}

export default Index