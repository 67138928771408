import _ from "lodash";

export const handleGroupsData = (data) => {
    let _groups = {};

    Object.values(data.data).forEach(group => {
        _groups = { ..._groups, [group.id]: { group_id: group.id, name: group.name, items: group.devices, } }
    })

    return _groups;
}

export const handleResErrors = (error, showToast) => {
    let _errors = error?.response?.data?.errors;
    
    if (_.isObject(_errors)) {
        _.forIn(_errors, (value) => {
            showToast("error", "Error", value)
        });
    } else {
        showToast("error", "Error", error.response?.data?.msg ?? error.response?.data?.message)
    }
}

// Generate error message
export const generateMessage = (arr, type) => {
    let msg = `${arr.map((name) => '"' + name + '" ')} ${arr.length > 1 ? "don't" : "doesn't"}  support ${type}!`;
    return { severity: 'error', summary: 'Assign Campaign', detail: msg, life: 3000 }
}