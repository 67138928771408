import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";

// -------------------------Get Gallery -------------------------//
const getGallery = async ({ queryKey }) => {
    const [, pageNum,] = queryKey;
    let url = `/groups${!_.isNull(pageNum) ? `?page=${pageNum}&per_page=7` : ""}`

    const { data } = await axiosInstance.get(url);

    return !_.isNull(pageNum) ? data : data.data;
}

export const useGetGallery = (pageNum = null, enabled = true) => {
    const { showToast } = useCustomToast();

    let { isLoading: groupsLoading, data, error, isError, refetch: refetchGroup } = useQuery(
        ['getGallery', pageNum],
        getGallery,
        {
            enabled,
        }
    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { groupsLoading, data, refetchGroup };
}

//-------------------------- Delete Schedule Campaign--------------------------
const deleteMedia = async (payload) => {
    const { data } = await axiosInstance.delete(`/media/${payload?.id}`);
    return data?.data;
}

export const useDeleteMedia = () => {
    const { showToast } = useCustomToast();

    return useMutation(deleteMedia, {
        onSuccess: async (responseData) => {
            showToast('success', 'Delete Rss', responseData?.msg);
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}
