import { useEffect } from 'react';
import { useLogoutMutation } from '../../../queries/auth';

const LogoutProcess = () => {
  const logout = useLogoutMutation();

  useEffect(() => {
    logout.mutateAsync();
  }, [])

  return null
}

export default LogoutProcess 