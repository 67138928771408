
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Card, Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { useCustomToast } from '../../../../../Contexts/ToastContext';

import axiosInstance from '../../../../../../config/Axios';
import { updateOriginalScreens } from '../../../../../../config/layoutHelper';

import LayoutPreview from '../../LayoutPreview';
import ContainerPreview from './Warning_preview/ContainerPreview';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTemplate, setReloadTrigger } from '../../../../../../redux/Slices/layoutSlice';
import { setDisableBtn, toggleDialog } from '../../../../../../redux/Slices/Global/globalSlice';

const ChangeTemplatesLayouts = () => {
    const { id } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { showToast } = useCustomToast();

    const { defaultTemplate, selectedLayout, screens_setting, selectedTemplate } = useSelector((state) => state.layout);
    const { subLoaders } = useSelector((state) => state.global);
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});

    const [isConflectScreens, setIsConflectScreens] = useState(false);
    const [newLayout, setNewLayout] = useState([]);
    const [oldLayout, setOldLayout] = useState([]);
    const [oldAspectRatio, setOldAspectRatio] = useState({});
    const isTemplateSelectTriggered = useRef(false);

    useEffect(() => {
        axiosInstance.get(`/aspect-ratio/${selectedLayout.aspect_ratio_id}/show`)
            .then((res) => {
                const data = res.data.data;
                setOldAspectRatio(data)
            })
    }, []);



    useEffect(() => {
        if (isTemplateSelectTriggered.current) {
            handleTemplateSelect();
            isTemplateSelectTriggered.current = false;
        }
    }, [selectedTemplate]);



    // const updateOriginalScreens = (originalScreens, sanitizedAreas) => {
    //     let theUpdatedVersion = [];

    //     if (originalScreens.length === sanitizedAreas.length) {
    //         theUpdatedVersion = originalScreens.map((screen, index) => ({
    //             id: screen.id,
    //             name: sanitizedAreas[index].name,
    //             width: sanitizedAreas[index].width,
    //             height: sanitizedAreas[index].height,
    //             start_margin: sanitizedAreas[index].start_margin,
    //             top_margin: sanitizedAreas[index].top_margin,
    //         }));
    //     }

    //     else if (originalScreens.length > sanitizedAreas.length) {
    //         theUpdatedVersion = originalScreens
    //             .slice(0, sanitizedAreas.length)
    //             .map((screen, index) => ({
    //                 id: screen.id,
    //                 name: sanitizedAreas[index].name,
    //                 width: sanitizedAreas[index].width,
    //                 height: sanitizedAreas[index].height,
    //                 start_margin: sanitizedAreas[index].start_margin,
    //                 top_margin: sanitizedAreas[index].top_margin,
    //             }));
    //     }

    //     else if (originalScreens.length < sanitizedAreas.length) {
    //         theUpdatedVersion = originalScreens.map((screen, index) => ({
    //             id: screen.id,
    //             name: sanitizedAreas[index].name,
    //             width: sanitizedAreas[index].width,
    //             height: sanitizedAreas[index].height,
    //             start_margin: sanitizedAreas[index].start_margin,
    //             top_margin: sanitizedAreas[index].top_margin,
    //         }));

    //         theUpdatedVersion = [...theUpdatedVersion];
    //     }

    //     return theUpdatedVersion;
    // };



    const handleTemplateSelect = () => {
        const sanitizedAreas = selectedTemplate.screens_setting;
        let originalScreens = screens_setting
        if (sanitizedAreas.length < originalScreens.length) {
            setOldLayout(selectedLayout.screens_setting)
            setNewLayout(sanitizedAreas)
            setIsConflectScreens(true)

        } else {
            setIsConflectScreens(false)
            handleChange(screens_setting, selectedTemplate)
        }

    }

    const handleChange = (screens_setting, selectedTemplate) => {

        const sanitizedAreas = selectedTemplate.screens_setting;
        let originalScreens = screens_setting
        const theNewAspect = selectedTemplate.aspect_ratio.id
        const theUpdatedVersion = updateOriginalScreens(originalScreens, sanitizedAreas);
        axiosInstance.post(`/layout/${id}/update`, {
            title: selectedLayout.title,
            screens_setting: sanitizedAreas,
            type: "CUSTOM",
            status: "ACTIVE",
            aspect_ratio_id: theNewAspect,
            updated_campaigns: theUpdatedVersion
        })
            .then(result => {
                dispatch(toggleDialog("EditLayoutPopUp"));
                showToast('success', "Layout Creation", "Layout Updated successfully!");
                dispatch(setReloadTrigger(true));
                dispatch(setDisableBtn(false));
            })
            .catch(error => {
                console.error("API call failed:", error);
                showToast('error', "Layout Creation", "Failed to update layout!");
            });
    };



    return (
        <>
            {isConflectScreens ? (
                <>
                    <div className="w-[70%]text-center ml-5 mr-5 flex flex-col mt-5 text-white font-medium mb-3 alert bg-orange-300 p-5" role="alert">
                        <div className="flex justify-center justify-items-center text-center ">
                            {/* Preview Area */}
                            <ContainerPreview
                                areas={oldLayout}
                                currentAspectRatio={oldAspectRatio}
                            />

                            <div className="mt-3 relative w-auto pt-[6%] pl-3 pr-3 h-36 rounded-md justify-items-center justify-self-center">

                                <i class="fa-solid fa-angles-right"></i>
                            </div>
                            <ContainerPreview
                                areas={newLayout}
                                currentAspectRatio={oldAspectRatio}
                            />

                        </div>

                        <div className="flex mt-3 justify-center w-100">
                            <i className="w-25 text-end ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                            <div className="ml-5 w-75">
                                The selected layout has fewer zones than your current setup.
                                <br />
                                Content in some zones may be lost. Are you sure you want to proceed?


                            </div>
                        </div>
                        <div className="flex mt-3 justify-center  ">
                            <Button label="Confirm" className={"pt-1 pb-1 ml-[190px]  me-2  "} onClick={() => {
                                setIsConflectScreens(false)
                                setOldLayout(newLayout)
                                handleChange(screens_setting, selectedTemplate)
                            }} />
                            <Button label="Cancel" className={"pt-1 pb-1 me-[190px]   bg-gray-500 border-orange-300 text-white rounded hover:bg-gray-600"} onClick={() => {

                                setIsConflectScreens(false)
                            }} />
                        </div>
                    </div>
                </>
            ) : (
                <> {
                    subLoaders.createLoading ?
                        <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                            <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                        </div>
                        :
                        <Row className="g-3 pe-5 pl-5 ">
                            {defaultTemplate.map((template, index) => (
                                <Col key={index} xs={12} sm={6} md={4} lg={3}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title><div className="text-[13px] text-center">{template.title}</div></Card.Title>
                                            <Card.Text><div className="ml-[10px] mr-[10px]">
                                                <LayoutPreview template={template} screens={template.screens_setting} onClick={() => {
                                                    dispatch(setSelectedTemplate(template));
                                                    isTemplateSelectTriggered.current = true;
                                                }} />
                                            </div></Card.Text>
                                        </Card.Body>
                                        <div className="w-100 pl-3 pr-3 pb-3 flex">
                                            <button
                                                className="w-100 px-4 py-1 border-1 border-blue-700 text-[15px]  rounded-lg hover:bg-purple-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                                                onClick={() => {
                                                    dispatch(setSelectedTemplate(template));
                                                    isTemplateSelectTriggered.current = true;
                                                }}
                                            >
                                                Select
                                            </button>
                                        </div>

                                    </Card>
                                </Col>
                            ))}
                        </Row>
                }</>
            )}


        </>
    );
};

export default ChangeTemplatesLayouts;