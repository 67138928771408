import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react'
import _ from 'lodash'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Dropdown } from 'primereact/dropdown'
import { Tooltip } from 'primereact/tooltip'
import { Spinner } from 'react-bootstrap'

import { setSelectedDevice, setEditRotation } from '../../../../redux/Slices/deviceSlice';
import { useRotateDeviceMutation } from '../../../../queries'
import { rotationOptions } from '../../../../config/constants'
import RotationIcon from "../../../../assets/img/rotation_icon.png"

function Rotation({ rowData, realtimeData }) {
    const dispatch = useDispatch();

    const { selectedDevice, editRotation } = useSelector((state) => state.device);
    const rotateDevice = useRotateDeviceMutation();

    const [rotation, setRotation] = useState(rotationOptions[0])
    const [loading, setLoading] = useState(false)

    const handleRotate = async () => {
        setLoading(true)
        await rotateDevice.mutateAsync({
            id: rowData?.id,
            data: { rotation: rotation }
        }, {
            onSuccess: () => setLoading(false)
        })
    }

    useEffect(() => {
        setRotation(rowData?.rotation)
    }, [selectedDevice])

    const btnHandle = () => {
        dispatch(setSelectedDevice(rowData))
        dispatch(setEditRotation(true));
    }

    return (
        <>
            {
                (editRotation && selectedDevice?.id === rowData?.id) ?
                    <>
                        <Dropdown
                            value={rotation}
                            options={rotationOptions}
                            onChange={(e) => { setRotation(e.value); }}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="select..."
                            className={` `}
                        />
                        {
                            loading ?
                                <Spinner variant="primary" as="span" size="sm" animation="border" role="status" className="mx-2" aria-hidden="true" />
                                :
                                <i className="fa-solid fa-check fs-5 ms-2 cursor-pointer" onClick={() => handleRotate(rowData.id)}></i>
                        }
                    </>
                    :
                    <>
                        <Tooltip target={`.rotate-${rowData.id}`} showDelay={100} className="fs-8" />

                        <span className={`text-center cursor-pointer rotate-${rowData.id} d-flex align-items-center bg-label-secondary p-2 rounded`}
                            rowData-pr-position="bottom"
                            rowData-pr-tooltip='Rotate'
                            onClick={btnHandle}

                        >
                            <LazyLoadImage src={RotationIcon} className=" my-1 cursor-pointer"
                                style={{
                                    width: "30px",
                                    transform: `rotate(${realtimeData.screen_angle || rowData?.rotation}deg)`
                                }} />
                            <span className='mx-2'>{realtimeData.screen_angle || rowData?.rotation} ْ</span>
                        </span>
                    </>
            }
        </>
    )
}

export default Rotation