
import React, { useState, useContext, createContext, useEffect, useRef } from "react"
import avatar from '../../assets/img/unknown_person.jpg';

import { Toast } from "primereact/toast";

const Global = createContext({});

export const GlobalProvider = (props) => {
    const [disableBtn, setDisableBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [packages, setPackages] = useState([]);
    const [stepsBack, setStepsBack] = useState(-1);
    const [countries, setCountries] = useState([]);

    const toastRef = useRef(null);

    // Rerender campaign list, interactive campaign list and play list after running any action   
    const [rerenderList, setRerenderList] = useState(false);

    // Subscription Information
    const [rerenderSubscription, setRerenderSubscription] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({});
    const [subscriptionInfo, setSubscriptionInfo] = useState({
        isProPackage: false,
        isFinished: false,
        isYearly: false,
        isFree: false,
    })

    // Auth
    const [userInfo, setUserInfo] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    // Shared Component states
    const [layerOptions, setLayerOptions] = useState([]);
    const [selectedApp, setSelectedApp] = useState({});
    const [assign, setAssign] = useState(false);
    const [layer, setLayer] = useState("MAIN");
    const [type, setType] = useState("media");


    // Used to show and hide assign button based on campaign type if prayer or not 
    const [isPrayerCampaign, setIsPrayerCampaign] = useState(false);

    // Control showing tabs in assign media popup for campaign 
    const [hideTabs, setHideTabs] = useState(false)

    // Campaign Information    
    const [campaignDetails, setCampaignDetails] = useState({ "total_duration": 0 });
    const [isPublished, setIsPublished] = useState();

    // Interactive Media information
    const [selectedMediaInfo, setSelectedMediaInfo] = useState({});
    const [deletedItemId, setDeletedItemId] = useState("");

    // Profile Image
    const [profileImage, setProfileImage] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));

    // Device 
    const [selectedCountry, setSelectedCountry] = useState({});

    // Global Dialogs Controls
    const [openDialog, setOpenDialog] = useState({
        // Shared component (Add content dialog)
        assignScheduleCampaigns: false,
        assignCampaignsDropDown: false,
        assignDeviceGroup: false,
        addContent: false,

        // Media-Folder
        moveMediaFolder: false,

        // Folder
        createFolder: false,
        updateFolder: false,

        // Media
        previewMedia: false,
        uploadMedia: false,
        updateMedia: false,

        // Campaign  
        editInteractiveMedia: false,
        campaignMediaSetting: false,
        createCampaign: false,
        updateCampaign: false,

        // Device  
        createGroupDialog: false,
        confirmDialog: false,
        assignDialog: false,
        updateDevice: false,
        orderDialog: false,

        //Playlist
        updatePlaylist: false,
        createPlaylist: false,

        // Broadcast   
        createBroadcastDialog: false,

        // Billing 
        paymentDialog: false,
        addScreens: false,
        upgrade: false,

        // Users
        addUser: false,

        // Apps
        rssFeedAddition: false,
        rssCreation: false,

        customRssCreation: false,
        streamPreview: false,
        addCustomFeed: false,

        createEditCollection:false,
        addCollectionContent:false,
    });

    // Global Loader Handler for Single Inputs Editing
    const [subLoaders, setSubLoaders] = useState({
        // Interactive campaign 
        durationLoader: false,
        titleLoader: false,
        thumbLoading: false,

        // Broadcast App
        createLoading: false,
        streamsLoading: true,
        reload: false,

        // Campaign Media
        deleteCampaignMedia: false,

        // Billing 
        packageDetails: false,
        invoicePreview: false,
        subscriptions: false,
        calculatePrice: true,
        packages: false,
        addScreens: false,

        // Company
        companyDetails: false,
    });

    // Global Dialogs Controls 
    const [deleteDialog, setDeleteDialog] = useState({
        interactiveCampaign: false,
        playlist: false,
        campaign: false,
        device: false,
        folder: false,
        media: false,
        user: false

    });

    const dialogHandler = (key) => {
        if (!openDialog[key])
            document.body.style.overflowY = 'hidden';
        else
            document.body.style.overflowY = 'scroll';

        setOpenDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const loadingHandler = (key) => {
        setSubLoaders(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const deleteDialogHandler = (key) => {
        setDeleteDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const showToast = (severity, summary, detail, content = null) => {
        if (content == null)
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, life: 3000 });
        else
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, content: content, sticky: true });

    };

    return (
        <Global.Provider value={ {
            rerenderSubscription, setRerenderSubscription,
            subscriptionInfo, setSubscriptionInfo,
            companyDetails, setCompanyDetails,

            disableBtn, setDisableBtn,
            isLoading, setIsLoading,
            rerender, setRerender,
            rerenderList, setRerenderList,
            packages, setPackages,

            isPrayerCampaign, setIsPrayerCampaign,
            campaignDetails, setCampaignDetails,
            deletedItemId, setDeletedItemId,
            layerOptions, setLayerOptions,
            isPublished, setIsPublished,
            selectedApp, setSelectedApp,
            hideTabs, setHideTabs,
            assign, setAssign,
            layer, setLayer,
            type, setType,

            deleteDialog, deleteDialogHandler,
            subLoaders, loadingHandler,
            openDialog, dialogHandler,

            selectedMediaInfo, setSelectedMediaInfo,
            profileImage, setProfileImage,

            userInfo, setUserInfo,
            isAuthenticated, setIsAuthenticated,

            stepsBack, setStepsBack,
            countries, setCountries,

            selectedCountry, setSelectedCountry,

            showToast
        } }>
            <Toast ref={ toastRef } position="bottom-center"></Toast>
            { props.children }
        </Global.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(Global)
}