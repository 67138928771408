import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCampaign } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useAssignCampaignDevices } from '../../../../queries';

function AssignCampaignsDropDown() {
    const assignCampaignDevices = useAssignCampaignDevices();
    const { companyCampaigns, selectedCampaign, selectedDevice } = useSelector((state) => state.device);
    const { openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const assign = async () => {
        const data = {
            campaign_id: selectedCampaign,
            devices_ids: [selectedDevice?.id],
            type: "devices",
        };

        await assignCampaignDevices.mutateAsync(data, {
            onSuccess: () => {
                dispatch(toggleDialog("assignCampaignsDropDown"));
            },
        });
    }

    const onClose = () => {
        dispatch(toggleDialog("assignCampaignsDropDown"))
    }

    return (
        <>
            <Dialog visible={openDialog.assignCampaignsDropDown}
                style={{ width: '500px' }}
                breakpoints={{ '960px': '95vw' }}
                header="Assign campaign to Device"
                modal className="p-fluid"
                onHide={onClose}>

                <div className='my-5 px-4'>
                    <label htmlFor="" className='mb-2'>Campaigns</label>
                    <Dropdown className='w-100' value={selectedCampaign} options={companyCampaigns}
                        onChange={(e) => { dispatch(setSelectedCampaign(e.value)) }}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select campaign" />
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                        disabled={assignCampaignDevices.isLoading}
                        onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Save"
                        loading={assignCampaignDevices.isLoading}
                        onClick={assign} />
                </div>
            </Dialog>
        </>
    )
}

export default AssignCampaignsDropDown