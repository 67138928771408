import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCreateDialog, setPrayerEnableDialog } from "../redux/Slices/Apps/appsSlice";
import { setDisableBtn, setIsPrayerCampaign, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import { handleResErrors } from "./utils";
import axiosInstance from "../config/Axios";
import { MULTI_3D_TABLE_CONFIG } from "../Constants/datatables";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";

//--------------------------Get Company Campaigns--------------------------
const getPrayerCampaigns = async () => {
    const { data } = await axiosInstance.get(`/company/prayer/campaigns`);
    return data.data;
}

export const useGetPrayerCampaigns = () => {
    const { showToast } = useCustomToast();

    const { isLoading, data, isFetching, refetch } = useQuery(
        'getPrayerCampaigns',
        getPrayerCampaigns,
        {
            onError: (error) => showToast("error", "Fetching Data", error.response?.data?.message)
        }
    );

    return { isLoading, data, refetch, isFetching };
}

//--------------------------Create Prayer Campaign--------------------------
const createPrayerCampaign = async (payload) => {
    const { data } = await axiosInstance.post("/campaign/prayer/campaign/create", payload);
    return data.data;
}

export const useCreatePrayerCampaign = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return useMutation(createPrayerCampaign
        , {
            onSuccess: async (data) => {
                dispatch(setDisableBtn(false))
                dispatch(setCreateDialog(false))
                dispatch(setIsPrayerCampaign(true))
                showToast('success', 'Campaign Creation', "Campaign created successfully!");
                sessionStorage.setItem('is_default_prayer', JSON.stringify(false));
                navigate(`/campaigns/${data?.id}/setup`)
            },
            onError: (error) => {
                dispatch(setDisableBtn(false));
                handleResErrors(error, showToast)
            }
        })
}

//--------------------------Enable Devices Prayer--------------------------
const enableDevicesPrayer = async (payload) => {
    const { data } = await axiosInstance.post("/device/prayer/enable", payload);
    return data.data;
}

export const useEnableDevicesPrayer = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(enableDevicesPrayer
        , {
            onSuccess: async (data) => {
                showToast('success', "Updating Devices ", "Devices updated successfully!");
                dispatch(setPrayerEnableDialog(false))
            },
            onError: (error) => handleResErrors(error, showToast)

        })
}

//--------------------------Enable Devices Prayer--------------------------
const getPrayersTimes = async () => {
    const { data } = await axiosInstance.get("/device/prayer");
    return data.data;
}

export const useGetPrayersTimes = () => {
    const { showToast } = useCustomToast();

    const { isLoading, data, isFetching, refetch } = useQuery(
        'getPrayersTimes',
        getPrayersTimes,
        {
            onError: (error) => showToast("error", "Fetching Data", error.response?.data?.message)
        }
    );

    return { isLoading, data, refetch, isFetching };
}

//--------------------------Create 3D collection --------------------------
const create3dCollection = async (payload) => {
    const { data } = await axiosInstance.post("/media/multi-3d/create", payload);
    return data.data;
}

export const useCreate3dCollection = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(create3dCollection
        , {
            onSuccess: (data) => {
                showToast('success', "Multi-media Collection", data?.msg);
                dispatch(toggleDialog("createEditCollection"));
                setLazyParams(prev => ({ ...prev, ...MULTI_3D_TABLE_CONFIG }))
            },
            onError: (error) => handleResErrors(error, showToast)

        })
}

//--------------------------Update 3D collection --------------------------
const update3dCollection = async (payload) => {
    const { data } = await axiosInstance.post(`/media/${payload?.id}/update`, payload.data);
    return data.data;
}

export const useUpdate3dCollection = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(update3dCollection,
        {
            onSuccess: () => {
                showToast('success', "Multi-media Collection", "Media collection updated successfully!");
                dispatch(toggleDialog("createEditCollection"));
                setLazyParams(prev => ({ ...prev, ...MULTI_3D_TABLE_CONFIG }))
            },
            onError: (error) => handleResErrors(error, showToast)
        })
}

//--------------------------Get collection content--------------------------
const getCollectionContent= async ({ queryKey }) => {
    const [, id] = queryKey;
    const { data } = await axiosInstance.get(`/media/${id}/multi-3d`);
    return data.data;
}

export const useGetCollectionContent = (id) => {
    const { showToast } = useCustomToast();

    const { isLoading, data, isFetching, refetch } = useQuery(
        ['getCollectionContent', id],
        getCollectionContent,
        {
            enabled: !!id,
            onError: (error) => showToast("error", "Fetching Data", error.response?.data?.message)
        }
    );

    return { isLoading, data, refetch, isFetching };
}

//--------------------------Get collection content--------------------------
const assignCollectionContent= async (payload) => {
     const { data } = await axiosInstance.post(`/media/multi-3d/${payload?.id}/assign`, payload.data);
    return data.data;
}

export const useAssignCollectionContent = () => {
    const { showToast } = useCustomToast();
     const dispatch = useDispatch()

    return useMutation(assignCollectionContent,
        {
            onSuccess: (data) => {
                showToast('success', `Add Collection Content`, data?.msg);
                dispatch(toggleDialog("addCollectionContent"))
            },
            onError: (error) => handleResErrors(error, showToast)
        })
}
