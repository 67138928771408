import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import { FONT_SIZES, FONTS, LANGUAGES } from '../../../Constants/apps';
import { getFormErrorMessage } from '../../../config/global';

import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { setRssSettings } from '../../../redux/Slices/Apps/appsSlice';

const sizes = {
    20: 'Small',
    25: 'Medium',
    30: 'Large'
}

const FontSelector = ({ control, errors, classes = {} }) => {
    const dispatch = useDispatch()
    const rssSettings = useSelector(state => state.apps.rssSettings);

    const inputHandler = (field, e) => {
        if (!_.isNull(e.value)) {
            field.onChange(e.value);
            dispatch(setRssSettings({ [field?.name]: e.value }))

            if (field.name === "font_size")
                dispatch(setRssSettings({ font_size_device: sizes[e.value] }))
        }
    }

    return (
        <>
            <div className={`${classes?.mb ?? "mb-2"} col-12`}>
                <label htmlFor="" className={`form-label text-capitalize mb-0`}>language</label>
                <Controller name="language" control={control}
                    rules={{ required: "language is required!" }}
                    render={({ field, fieldState }) => (
                        <Dropdown
                            id={field.name} {...field}
                            options={LANGUAGES}
                            onChange={(e) => { inputHandler(field, e) }}
                            optionLabel="label"
                            optionValue="value"
                            ref={(el) => {
                                if (el?.focus) field.ref(el);
                            }}
                            placeholder="select..."
                            className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                        />
                    )}
                />
                {getFormErrorMessage('language', errors)}
            </div>

            <div className={`${classes?.mb ?? "mb-2"} col-6 pe-1`}>
                <label htmlFor="" className={`form-label text-capitalize mb-0`}>Font Type</label>
                <Controller name="font_family" control={control}
                    rules={{ required: "language is required!" }}
                    render={({ field, fieldState }) => (
                        <Dropdown
                            id={field.name} {...field}
                            options={FONTS[rssSettings?.language]}
                            onChange={(e) => { inputHandler(field, e) }}
                            optionLabel="label"
                            optionValue="value"
                            ref={(el) => {
                                if (el?.focus) field.ref(el);
                            }}
                            placeholder="select..."
                            className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                        />
                    )}
                />
                {getFormErrorMessage('font_family', errors)}
            </div>

            {/* Font Size SelectButton */}
            <div className={`col-6 ps-1  ${classes?.mb ?? "mb-2"}`}>
                <label htmlFor="font_size" className={`mb-0 form-label text-capitalize `} > Font Size</label>
                <Controller name="font_size" control={control} className=""
                    rules={{ required: false }}
                    render={({ field, fieldState }) => {
                        return (
                            <SelectButton
                                id={field.name} {...field}
                                className={`font_select d-flex ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                inputRef={field.ref}
                                onChange={(e) => { inputHandler(field, e) }}
                                options={FONT_SIZES}
                                optionLabel="label"
                            />
                        )
                    }} />
            </div>
        </>
    );
};

export default FontSelector;
