
import {  COMMON_ASPECT_RATIOS } from '../../../../../../config/layoutConstans';
import { Button } from 'primereact/button';


const AddArea = ({
    currentAspectRatio,
    setAreas,
    areas,
    unit
}) => {
    const handleAddArea = () => {
        if (unit.value == "%") {
            const newArea = {
                unit: "%",
                id: areas.length + 1,
                name: `Screen ${areas.length + 1}`,
                start_margin: 0,
                top_margin: 0,
                width: 100,
                height: 100,
            };
            setAreas([...areas, newArea]);

        } else {
            let maxWidth, maxHeight
            if (currentAspectRatio) {
                const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;

                if (COMMON_ASPECT_RATIOS[aspectKey]) {
                    maxWidth = COMMON_ASPECT_RATIOS[aspectKey].width;
                    maxHeight = COMMON_ASPECT_RATIOS[aspectKey].height;
                } else {
                    maxWidth = currentAspectRatio.width;
                    maxHeight = currentAspectRatio.height;
                }
            }

            const newArea = {
                unit: "px",
                id: areas.length + 1,
                name: `Screen ${areas.length + 1}`,
                start_margin: 0,
                top_margin: 0,
                width: maxWidth,
                height: maxHeight,
            };
            setAreas([...areas, newArea]);
        }

    };

    return (
        <>
            <Button label="Add Area" icon="fas fa-plus" className={"mt-4  pt-1 pb-1 me-2 text-[15px] bg-blue text-white"} onClick={() => handleAddArea()} />

        </>
    );
};

export default AddArea;
