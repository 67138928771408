import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';

import { formatDuration, } from "../../../../../config/global";
import axiosInstance from '../../../../../config/Axios';
import { useCustomToast } from '../../../../Contexts/ToastContext';

import InteractiveLayoutPreview from './InteractiveLayoutPreview';

import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedLayoutTitle, setReloadTrigger } from '../../../../../redux/Slices/layoutSlice';
import { toggleDialog, setIsPublished } from '../../../../../redux/Slices/Global/globalSlice';

const LayoutInfo = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    const { selectedLayout, screenDetails, screenMedia, screens_setting } = useSelector((state) => state.layout);
    const { control, handleSubmit } = useForm({ defaultValues: { layoutName: selectedLayout.title } });
    const [isEditing, setIsEditing] = useState(false);

    const [screenNumber, setScreenNumber] = useState();

    const [loading, setLoading] = useState(false);
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});

    const onTitleSubmit = (data) => {

        let formData = {
            "title": data.layoutName,
            "status": "ACTIVE",
        }
        axiosInstance.post(`/layout/${id}/update`, formData)
            .then((result) => {
                dispatch(updateSelectedLayoutTitle(formData.title));
                setIsEditing(false)
                showToast('success', 'Update Layout', "Layout Title Updated Successfully!");
            })
    }

    useEffect(() => {
        setLoading(true)
        if (selectedLayout.aspect_ratio_id) {
            axiosInstance.get(`/aspect-ratio/${selectedLayout.aspect_ratio_id}/show`)
                .then((res) => {
                    const data = res.data.data;
                    setCurrentAspectRatio(data)
                    getScreenNumber()
                    setLoading(false)

                })
        }
    }, []);

    useEffect(() => {

        getScreenNumber()

    }, [screenDetails]);

    const getScreenNumber = () => {
        const index = screens_setting.findIndex(item => item.id === screenDetails.id);
        return (index + 1)
    }

    const muteHandler = () => {
        setLoading(true)

        axiosInstance.get(`/layout/mute/screen/${screenDetails.id}`)
            .then((res) => {
                showToast('success', 'Screen Muted', "Screen's Media Muted Successfully!");
                dispatch(setReloadTrigger(true))
                setLoading(false)

            })
    }

    return (
        <div className="card-body h-[85vh] scroll_div overflow-auto">
            <div className="col-md-12 col-lg-12 order-md-1 order-lg-1 ">
                <div className="user-avatar-section">
                    <div className="d-flex justify-center align-items-center flex-column">
                        <div className="text-center w-[100%]   ">
                            <h4 className="mb-1 ">Layout Info </h4>
                            <InteractiveLayoutPreview />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 text-center">
                        {loading ? "" :
                            <p className="  mb-2">{currentAspectRatio.width}X{currentAspectRatio.height} </p>
                        }
                        {isEditing ? (
                            <>
                                <form className="flex mt-1 w-100" onSubmit={handleSubmit(onTitleSubmit)}  >
                                    <div className="flex flex-col w-full">
                                        <Controller name="layoutName" control={control} rules={{
                                            required: "Title is required",
                                            minLength: { value: 3, message: "Title must be at least 3 characters" },
                                        }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <InputText
                                                        {...field}
                                                        placeholder="My custom layout"
                                                        className={`p-2 border rounded-lg  ${fieldState.error ? "border-red-500" : "border-gray-300"
                                                            }`}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                    {fieldState.error && (
                                                        <span className="text-red-500 text-start text-sm pt-1">{fieldState.error.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div><Button label="" icon="fa-solid fa-check" className="p-0 me-2 bg-transparent text-black border-transparent " type="submit" /></div>
                                </form>
                            </>
                        ) : (
                            <>
                                <div className="flex justify-center">
                                    <h5 className="mb-0">
                                        {selectedLayout.title.length > 15
                                            ? selectedLayout.title.substring(0, 15) + "..."
                                            : selectedLayout.title}
                                    </h5>
                                    <Button
                                        label=""
                                        icon="fa-solid fa-pen"
                                        className="p-0 me-2 bg-white text-black border-white"
                                        onClick={() => setIsEditing(true)}
                                    />
                                </div>
                            </>)}
                    </div>
                </div>
            </div>


            <div className="col-md-12 col-lg-12 order-md-1 order-lg-1 ">

                <div className="">
                    <div className="flex">
                        <div className=" w-50 ">
                            <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-windows bx-sm"></i></span>
                                <div>
                                    <span>No. Of Screens</span>
                                    <h5 className="mb-0">{selectedLayout.screens_setting?.length ?? "--"}</h5>
                                </div>
                            </div>
                        </div>
                        <div className=" w-50  ">
                            <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-timer  bx-sm"></i></span>
                                <div>
                                    <span>Layout Total Duration</span>
                                    <h5 className="mb-0">{formatDuration(selectedLayout.layout_duration ?? "0")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button label="Edit Layout" className='mt-4  mx-2 py-2 w-[99%]' onClick={() => dispatch(toggleDialog("EditLayoutPopUp"))} />

                </div>
                <hr></hr>
                <div className="row">
                    <div className="user-avatar-section">
                        <div className="d-flex align-items-center flex-column">
                            <div className="  text-center w-[65%] pl-5 ml-5 pe-5">
                                <h4 className="mb-3">Screen Details </h4>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12">
                        <div className="flex justify-between  mt-3">
                            <div className="d-flex align-items-start me-4 gap-3">
                                <span className=" fs-4 bg-label-primary px-3 py-1 rounded-[50%]">{getScreenNumber() ? getScreenNumber() : 1} </span>
                                <div>
                                    <h4 className="my-2  ">{screenDetails.name}</h4>
                                </div>
                            </div>
                            {/* <Button disabled={loading} label="Mute Screen Media" className='py-1 px-2 h-full ' onClick={() => muteHandler()} /> */}

                        </div>
                    </div>
                    <div className="col-12 col-sm-12">
                        <div className="d-flex align-items-start me-4 mt-3 gap-3">
                            <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-check bx-sm"></i></span>
                            <div>
                                <span>No. Of Media</span>
                                <h5 className="mb-0">{screenMedia ?? "--"}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12">
                        <div className="d-flex align-items-start me-4 mt-3 gap-3">
                            <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-time bx-sm"></i></span>
                            <div>
                                <span>Screen Total Duration</span>
                                <h5 className="mb-0">{formatDuration(screenDetails?.campaign_duration ?? "0")}</h5>
                            </div>
                        </div>
                    </div>
                    <Button disabled={loading} label="Mute Screen Media" className='mt-4  mx-2 py-2 w-[99%] ' onClick={() => muteHandler()} />

                </div>
            </div>
        </div>

    );
};

export default LayoutInfo;