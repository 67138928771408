import { LazyLoadImage } from "react-lazy-load-image-component";

import RssPreview from "../RSS/RssPreview";
import VideoJS from "./VideoJS";

const Media = ({ data }) => {
    var html = null;
    if (data.path.includes(".m3u8")) {
        const play = {
            fill: true,
            fluid: true,
            autoplay: true,
            controls: true,
            preload: "metadata",
            sources: [
                {
                    src: data.path,
                    type: "application/x-mpegURL",
                },
            ],
        };
        html = <VideoJS {...play} />;

    } else if (data.type == "IMAGE" || data.type == "CORNER") {
        html = <LazyLoadImage src={data.path} className={"w-100"} />;

    } else if (data.type == "VIDEO") {
        if (data.path.endsWith(".avi")) {
            html = (
                <div className="text-center mt-5">
                    <p>Your browser may not support this format.</p>
                    <a href={data.path} download>Download the file</a>
                </div>
            );
        } else {
            html = (
                <video width="100%" controls>
                    <source src={data.path} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        }
    } else if (data.type == "YOUTUBE") {
        var newPath = data.path.replace("watch?v=", "embed/");
        html = (
            <iframe className="w-100" height="315" src={newPath} title="YouTube video player" frameborder="0"></iframe>
        );

    } else if (data.type == "AUDIO") {
        html = (
            <audio controls className="w-full">
                <source src={data.path} type="audio/ogg"></source>
            </audio>
        );
    } else if (["CHANNEL", "HDMI"].includes(data.type)) {
        html = (
            <div className="w-100 d-flex w-100 h-100 bg-secondary text-white  fs-1 justify-content-center align-items-center">
                <span>{data.type == "CHANNEL" ? "Channel" : "HDMI"}</span>
            </div>
        );
    } else if (data.type == "3D") {
        let _path = `${process.env.REACT_APP_3D_VIEWER_URL}?id=${data?.id}&token=${localStorage.getItem("access_token")}`
        html = <iframe className="w-100" style={{ height: "100%" }} src={_path} title="YouTube video player" frameborder="0"></iframe>
    } else if (["RSS", "RSS_CUSTOM"].includes(data.type)) {
        html = <RssPreview rssURL={data?.path} />;
    }
    else {
        html = <iframe src={data.path} className="w-100" height="500"></iframe>;

    }

    return html

}

export { Media }