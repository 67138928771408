import _ from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { CUSTOM_RSS_TABLE_CONFIG, RSS_TABLE_CONFIG } from "../Constants/datatables";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import { toggleDialog } from "../redux/Slices/Global/globalSlice";
import axiosInstance from "../config/Axios";

//--------------------------Read RSS--------------------------
const readRss = async (payload) => {
    const { data } = await axiosInstance.post("/rss/read", payload);
    return data;
}

export const useReadRss = () => {
    const { showToast } = useCustomToast();

    return useMutation(readRss, {
        onError: () => { showToast("error", "Error", "Failed to fetch RSS feed!") }
    })
}

//--------------------------Create RSS--------------------------
const createRss = async (payload) => {
    const { data } = await axiosInstance.post("/rss/add", payload);
    return data.data;
}

export const useCreateRssMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()
    const { setLazyParams } = useDataTableContext();

    return useMutation(createRss, {
        onSuccess: async (data) => {
            dispatch(toggleDialog("rssFeedAddition"))
            showToast('success', "RSS Feed Handling", data?.msg);
            setLazyParams(prev => ({ ...prev, ...RSS_TABLE_CONFIG }))
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update RSS--------------------------
const updateRss = async (payload) => {
    const { data } = await axiosInstance.post(`/rss/${payload?.id}/update`, payload.data);

    return data.data;
}

export const useUpdateRssMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(updateRss, {
        onSuccess: async (data) => {
            setLazyParams(prev => ({ ...prev, ...RSS_TABLE_CONFIG }))

            dispatch(toggleDialog("rssFeedAddition"))
            showToast('success', "RSS Feed Handling", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//--------------------------Create Custom RSS--------------------------
const createCustomRss = async (payload) => {
    const { data } = await axiosInstance.post(`/rss/create`, payload);

    return data.data;
}

export const useCreateCustomRssMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(createCustomRss, {
        onSuccess: async (data) => {
            setLazyParams(prev => ({ ...prev, ...CUSTOM_RSS_TABLE_CONFIG }))
            dispatch(toggleDialog("customRssCreation"))
            showToast('success', "RSS Feed Handling", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//--------------------------Update Custom RSS --------------------------
const updateCustomRss = async (payload) => {
    const { data } = await axiosInstance.post(`/rss/custom/${payload?.id}/update`, payload?.data);
    return data.data;
}

export const useUpdateCustomRssMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(updateCustomRss, {
        onSuccess: async (data) => {
            setLazyParams(prev => ({ ...prev, ...CUSTOM_RSS_TABLE_CONFIG }))
            dispatch(toggleDialog("customRssCreation"))
            showToast('success', "RSS Feed Handling", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Add Custom RSS Content--------------------------
const addCustomRssContent = async (payload) => {
    const { data } = await axiosInstance.post(`/rss/${payload?.id}/add/feed`, payload?.data);
    return data.data;
}

export const useAddCustomRssContent = () => {
    const { showToast } = useCustomToast();

    return useMutation(addCustomRssContent, {
        onSuccess: async (data) => {
            showToast('success', "RSS Feed Handling", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
//--------------------------Update Custom RSS Content--------------------------
const updateCustomRssContent = async (payload) => {
    const { data } = await axiosInstance.post(`/rss/${payload?.id}/update/feed`, payload?.data);
    return data.data;
}

export const useUpdateCustomRssContent = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(updateCustomRssContent, {
        onSuccess: async (data) => {
            queryClient.invalidateQueries('fetchDataByPath');
            showToast('success', "RSS Feed Handling", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Delete Custom RSS Content--------------------------
const deleteRssFeed = async (payload) => {
    const { data } = await axiosInstance.delete(`/rss/feed/${payload?.id}/delete`);
    return data.data;
}

export const useDeleteRssFeed = () => {
    const { showToast } = useCustomToast();

    return useMutation(deleteRssFeed, {
        onSuccess: async (data) => {
            showToast('success', "Deleting RSS Feed ", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}



