import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../config/Axios";

import { useGalleryContext } from "../../../Contexts/GalleryContext";
import FolderCard from "../MediaLayout/FolderCard";
import MediaCard from "../MediaLayout/MediaCard";

function FolderDragDrop ({ data, getData, setCurrentFolder, currentFolder, setBreadCrumb, breadCrumb }) {

    const { setRerender, layout } = useGalleryContext()
    const [draggedItem, setDraggedItem] = useState({
        id: "",
        type: ""
    });

    // Type option => media / folder
    const onDrag = (event, ID, type) => {
        event.preventDefault();
        setDraggedItem({ id: ID, type: type });
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    const onDrop = (event, folderID) => {

        if (draggedItem.id == folderID) return false

        axiosInstance.post(
            `/folder/media/move`,
            {
                type: draggedItem.type,
                id: draggedItem.id,
                destination_id: folderID,
            }
        ).then((response) => {
            setDraggedItem({
                id: "",
                type: ""
            });
            setRerender(true);
        })

    }

    const getClasses = (index) => {
        return ` ${ layout === "list" ? "media_list" : "media_grid" }
         ${ index + 1 !== data.length && layout === "list" ? "border-bottom border-gray" : "" } 
         mx-auto py-3 px-2 media position-relative`
    }

    return (
        <>
            {
                data.map((item, index) => {
                    return (
                        item.hasOwnProperty("path") ?
                            <div key={ item.id } className={ getClasses(index) } draggable onDrag={ (event) => onDrag(event, item.id, "media") }>
                                <MediaCard item={ item }  getData={getData}/>
                            </div >
                            :
                            <div key={ item.id }
                                className={ getClasses(index) }
                                draggable
                                onDrag={ (event) => onDrag(event, item.id, "folder") }
                                onDrop={ event => onDrop(event, item.id) }
                                onDragOver={ (event => onDragOver(event, item.id)) }
                            >
                                <FolderCard
                                    getData={ getData }
                                    setCurrentFolder={ setCurrentFolder }
                                    currentFolder={ currentFolder }
                                    item={ item }
                                    setBreadCrumb={ setBreadCrumb }
                                    breadCrumb={ breadCrumb }
                                    onDrop={ onDrop }
                                    onDragOver={ onDragOver }
                                />
                            </div >
                    )
                })
            }
        </>
    )
}

export default FolderDragDrop