import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { COMMON_ASPECT_RATIOS } from '../../../../../../config/layoutConstans';

const AreaControls = ({
    areas,
    setSelectedArea, selectedArea,
    unit,
    currentAspectRatio,
    setAreas,
    setOverlappingAreas,
    isSaveDisabled,
    setIsSaveDisabled,
}) => {
    const { control, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const [isDuplicatedNames, setIsDuplicatedNames] = useState(false);

    useEffect(() => {
        // Sync React Hook values with the area state
        areas.forEach((area, index) => {
            setValue(`areas[${index}].start_margin`, area.start_margin);
            setValue(`areas[${index}].top_margin`, area.top_margin);
            setValue(`areas[${index}].width`, area.width);
            setValue(`areas[${index}].height`, area.height);
            if (area.name) {
                setValue(`areas[${index}].name`, area.name || `Screen ${index + 1}`);
            }
        });
    }, [areas, setValue]);

    const handleSmartInputChange = (id, field, value, unit, currentAspectRatio) => {
        setAreas((prevAreas) =>
            prevAreas.map((area) => {
                if (area.id === id) {
                    let updatedValue = parseFloat(value) || 0;
                    let maxWidth = 100;
                    let maxHeight = 100;

                    if (unit?.value === "px" && currentAspectRatio) {
                        const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;
                        if (COMMON_ASPECT_RATIOS[aspectKey]) {
                            maxWidth = COMMON_ASPECT_RATIOS[aspectKey].width;
                            maxHeight = COMMON_ASPECT_RATIOS[aspectKey].height;
                        } else {
                            maxWidth = currentAspectRatio.width;
                            maxHeight = currentAspectRatio.height;
                        }
                    }

                    if (unit.value === "%") {
                        updatedValue = Math.min(100, Math.max(0, updatedValue));
                    } else if (unit.value === "px") {
                        updatedValue = Math.min(field === "width" ? maxWidth : maxHeight, Math.max(0, updatedValue));
                    }

                    if (field === "start_margin") {
                        const newWidth = Math.max(0, area.start_margin + area.width - updatedValue);
                        return {
                            ...area,
                            start_margin: updatedValue,
                            width: unit.value === "%" ? Math.min(100, newWidth) : Math.min(maxWidth, newWidth),
                        };
                    } else if (field === "width") {
                        const newStartMargin = Math.min(
                            unit.value === "%" ? 100 - updatedValue : maxWidth - updatedValue,
                            area.start_margin
                        );
                        return {
                            ...area,
                            width: updatedValue,
                            start_margin: newStartMargin,
                        };
                    } else if (field === "top_margin") {
                        const newHeight = Math.max(0, area.top_margin + area.height - updatedValue);
                        return {
                            ...area,
                            top_margin: updatedValue,
                            height: unit.value === "%" ? Math.min(100, newHeight) : Math.min(maxHeight, newHeight),
                        };
                    } else if (field === "height") {
                        const newTopMargin = Math.min(
                            unit.value === "%" ? 100 - updatedValue : maxHeight - updatedValue,
                            area.top_margin
                        );
                        return {
                            ...area,
                            height: updatedValue,
                            top_margin: newTopMargin,
                        };
                    }
                }
                return area;
            })
        );
    };


    const handleDeleteArea = (id) => {
        const updatedAreas = areas.filter((area) => area.id !== id);
        const resetIdsAreas = updatedAreas.map((area, index) => ({
            ...area,
            id: typeof area.id === 'string' ? area.id : index + 1,
        }));
        setAreas(resetIdsAreas);
    };

    const checkDuplicateNames = () => {
        const names = areas.map((area) => area.name);
        const duplicates = names.filter((name, index) => names.indexOf(name) !== index);
        if (duplicates.length > 0) {
            setError("duplicateNames", {
                type: "manual",
                message: `Duplicate names found: ${[...new Set(duplicates)].join(", ")}`,

            });
            return true

        } else {
            clearErrors("duplicateNames");
            return false
        }
    };

    const checkOverlap = () => {
        const overlappingAreas = [];
        for (let i = 0; i < areas.length; i++) {
            for (let j = i + 1; j < areas.length; j++) {
                const a = areas[i];
                const b = areas[j];
                const isOverlapping =
                    a.start_margin < b.start_margin + b.width &&
                    a.start_margin + a.width > b.start_margin &&
                    a.top_margin < b.top_margin + b.height &&
                    a.top_margin + a.height > b.top_margin;

                if (isOverlapping) {
                    overlappingAreas.push(i, j)
                }
            }
        }
        return Array.from(new Set(overlappingAreas));
    };

    useEffect(() => {
        const overlaps = checkOverlap();
        const duplicate = checkDuplicateNames();

        setIsDuplicatedNames(duplicate);
        setOverlappingAreas(overlaps);

        if (overlaps.length > 0) {
            // setIsSaveDisabled(overlaps.length > 0);

        } else if (areas.length < 1) {
            // setIsSaveDisabled(areas.length < 1);

        } else if (duplicate) {
            setIsSaveDisabled(true);
        } else {
            setIsSaveDisabled(false);
        }
    }, [areas]);

    return (
        <div className="overflow-auto max-h-[330px]">
            {errors.duplicateNames && (
                <div className="text-red-500 text-sm mt-2">{errors.duplicateNames.message}</div>
            )}
            {areas.map((area, index) => (
                <div
                    key={area.id}
                    className={`items-center gap-3 mb-3 p-3 rounded-lg   bg-gray-50 ${selectedArea?.id === area.id ? "border-3 border-[#8a8aed]" : "border border-gray-300"
                        }`}
                >
                    <div className="flex items-center gap-2">
                        <div className="flex items-center text-sm font-medium mb-2 gap-1 ">
                            {index + 1}
                        </div>
                        {/* Editable Screen Name */}
                        <div className="flex items-center  gap-1 w-32">
                            <fieldset className="border border-gray-300 rounded flex flex-col items-center  relative">
                                <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                    Name
                                </legend>
                                <Controller
                                    name={`areas[${index}].name`}
                                    control={control}
                                    rules={{
                                        required: "Required",
                                        minLength: { value: 3, message: "Too short" },
                                    }}
                                    defaultValue={area.name || ""}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <InputText
                                                {...field}
                                                value={field.value}
                                                onFocus={() => setSelectedArea(area)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    field.onChange(value);
                                                    setValue(`areas[${index}].name`, value);
                                                    setAreas((prevAreas) =>
                                                        prevAreas.map((a, idx) =>
                                                            idx === index ? { ...a, name: value } : a
                                                        )
                                                    );
                                                    checkDuplicateNames();
                                                }}
                                                placeholder={`Screen ${index + 1}`}
                                            />
                                            {fieldState.error && (
                                                <span className="text-red-500 text-sm">{fieldState.error.message}</span>
                                            )}
                                        </>
                                    )}
                                />
                            </fieldset>

                        </div>

                        {/* Start Margin (X) */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                X {unit.value}
                            </legend>
                            <Controller
                                name={`areas[${index}].start_margin`}
                                control={control}
                                defaultValue={area.start_margin}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}

                                        minFractionDigits={unit.value == "%" ? 1 : 0}
                                        maxFractionDigits={unit.value == "%" ? 2 : 0}
                                        onFocus={() => setSelectedArea(area)}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].start_margin`, value);
                                            handleSmartInputChange(area.id, "start_margin", value, unit, currentAspectRatio);
                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Top Margin (Y) */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Y {unit.value}
                            </legend>
                            <Controller
                                name={`areas[${index}].top_margin`}
                                control={control}
                                defaultValue={area.top_margin}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={unit.value == "%" ? 1 : 0}
                                        maxFractionDigits={unit.value == "%" ? 2 : 0}
                                        onFocus={() => setSelectedArea(area)}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].top_margin`, value);
                                            handleSmartInputChange(area.id, "top_margin", value, unit, currentAspectRatio);
                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Width */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-25 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Width {unit.value}
                            </legend>
                            <Controller
                                name={`areas[${index}].width`}
                                control={control}
                                defaultValue={area.width}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={unit.value == "%" ? 1 : 0}
                                        maxFractionDigits={unit.value == "%" ? 2 : 0}
                                        onFocus={() => setSelectedArea(area)}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].width`, value);
                                            handleSmartInputChange(area.id, "width", value, unit, currentAspectRatio);

                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Height */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-25 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Height {unit.value}
                            </legend>
                            <Controller
                                name={`areas[${index}].height`}
                                control={control}
                                defaultValue={area.height}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={unit.value == "%" ? 1 : 0}
                                        maxFractionDigits={unit.value == "%" ? 2 : 0}
                                        value={field.value}
                                        onFocus={() => setSelectedArea(area)}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].height`, value);
                                            handleSmartInputChange(area.id, "height", value, unit, currentAspectRatio);

                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Delete Button */}
                        <Button
                            label=""
                            icon="pi pi-trash"
                            className=" bg-transparent text-red-500	 border-transparent"
                            onClick={() => handleDeleteArea(area.id)}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AreaControls;
