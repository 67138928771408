import AddRssDialog from './AddRssDialog';
import RssDataTable from './RssDataTable';
import Layout from '../../Layout/Layout';

import { useSelector, useDispatch } from 'react-redux';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import "../css/apps.css";

function RssIndex() {
    const openDialog = useSelector((state) => state.global.openDialog);
    const dispatch   = useDispatch()

    const { getDataTable } = useDataTableContext();

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">RSS List</h5>
                    <div className=' d-flex align-items-center  mb-4'>
                        <button className="btn btn-primary mx-1" disabled={getDataTable.isLoading} onClick={() => window.history.go(-1)} > Back</button>
                        <button className="btn btn-primary mx-1" disabled={getDataTable.isLoading} onClick={() => dispatch(toggleDialog("rssFeedAddition"))} > Add New RSS</button>
                    </div>
                </div>

                <div className="col-12 d-flex flex-column  ">
                    <RssDataTable />
                </div>
            </div>
            {openDialog.rssFeedAddition ? <AddRssDialog /> : <></>}
        </Layout >
    )
}

export default RssIndex