import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

import { useParams } from "react-router-dom";

import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';

import { formatDuration, } from "../../../../../config/global";
import DragDropScreen from '../../../../SharedComponents/DragDrop/DragDropScreen';
import axiosInstance from '../../../../../config/Axios';

import AddContentPopupScreen from '../../../../SharedComponents/AddContentPopup/AddContentPopupScreen';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, setRerenderList } from '../../../../../redux/Slices/Global/globalSlice';
import { setReloadTrigger, setScreenDetails, setScreenMedia, setIsPublished } from '../../../../../redux/Slices/layoutSlice';
import { useCustomToast } from '../../../../Contexts/ToastContext';


const ScreenInfo = forwardRef((props, ref) => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    const { screens_setting, screenDetails, isPublished } = useSelector((state) => state.layout);
    const { rerenderList, stepsBack, openDialog } = useSelector((state) => state.global);

    const [loading, setLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [data, setData] = useState([]);
    useImperativeHandle(ref, () => ({
        publish
    }));

    const options = [
        {
            label: 'Add Content',
            icon: 'pi pi-plus',
            command: () => {
                dispatch(toggleDialog("addContent"))
            }
        },
        {
            label: 'Publish Layout',
            icon: '',
            command: () => {
                publish()
            }
        }

    ];

    // Fetch Screen data 
    function getDate() {
        dispatch(setRerenderList(false))
        if (screenDetails.id) {
            axiosInstance.get(`/campaign/content/${screenDetails.id}/MAIN`)
                .then((res) => {
                    const data = res.data.data;
                    const dataLength = data.length;
                    dispatch(setScreenMedia(dataLength))
                    setData(data);
                    setLoading(false);
                    setOrderLoading(false)
                })
        }
    }
    // Get screen media on screen change
    useEffect(() => {
        if (screenDetails) {
            dispatch(setRerenderList(false))
            setLoading(true);
            getDate()
        }
    }, [screenDetails]);

    // Get screen media after update it
    useEffect(() => {
        if (rerenderList) {
            updateStatus("DRAFT");
            dispatch(setIsPublished(false))
        }
    }, [rerenderList]);

    // Publish all campaign content from draft 
    const publish = () => {
        setLoading(true)
        dispatch(setIsPublished(true));
        axiosInstance.post(`/campaign/${id}/publish/screen`, {})
            .then(() => {
                setLoading(false)
                dispatch(setReloadTrigger(true));
                showToast('success', 'Publish Layout', 'All Layout\'s screens have been published successfully.');
            })
            .catch((error) => {
                setLoading(false)
                showToast('error', 'Publish Campaign', 'Some screens failed to publish.');
            });
        // updateStatus("PUBLISHED");
    }

    // // Update screen status DRAFT/PUBLISHED
    const updateStatus = (status) => {
        axiosInstance.put(`/campaign/${screenDetails.id}/update`,
            {
                name: screenDetails.name,
                status: status
            })
            .then((res) => {
                dispatch(setScreenDetails(res.data.data));
                if (res.data.data.status === "DRAFT") {
                    dispatch(setIsPublished(false));
                }
            })
    }

    const handleScreenChange = (e) => {
        const selectedScreen = screens_setting.find(
            (item) => item.id === e.target.value
        );
        dispatch(setScreenDetails(selectedScreen));
        setLoading(true)
    };

    const goBack = () => {
        window.history.go(stepsBack)
    }

    return (
        <>
            <div className="p-0 d-flex justify-content-between mb-3">
                <div className="col-8 col-md-6 d-flex">

                    <Tooltip target='.back-button' showDelay={100} className="fs-8" />
                    <Button label="" icon="fas fa-arrow-left" className='py-2 me-2 back-button'
                        data-pr-position="bottom" data-pr-tooltip="Go back"
                        onClick={goBack} disabled={loading} />
                    <Dropdown
                        value={screenDetails?.id || ""}
                        options={screens_setting}
                        optionLabel="name"
                        optionValue="id"
                        className="w-50"
                        onChange={handleScreenChange}
                        placeholder="Select a screen"
                    />
                    {
                        // total duration for each screen 
                        !loading &&
                        <div className='mx-3 d-none d-sm-block'>
                            <p className="mb-0 ">Total Duration</p>
                            <div className='fw-bold'> {formatDuration(screenDetails.campaign_duration ?? "0")}</div>
                        </div>
                    }

                </div>
                <div className='d-none d-md-flex'>
                    <Button label="Add Content" icon="pi pi-plus" className='py-2' onClick={() => dispatch(toggleDialog("addContent"))} disabled={loading ? true : false} />
                </div>
                <SplitButton label="Option" model={options} className="p-button-sm mr-2 mb-2 d-flex d-md-none" />

            </div>
            {
                data.length == 0 && !loading ?
                    <h5 className='w-100 card d-flex align-items-center text-center p-5 mt-5'>
                        There is no media assigned yet !
                    </h5>
                    :
                    ""
            }
            {
                loading ?
                    <div className='w-100 h-100 d-flex align-items-center' >
                        <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                    </div>
                    :
                    <DragDropScreen updateStatus={updateStatus} data={data} setOrderLoading={setOrderLoading} />
            }

            {
                openDialog.addContent && <AddContentPopupScreen updateStatus={updateStatus} loading={loading} />
            }
        </>
    );
});

export default ScreenInfo;