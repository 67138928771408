import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { useDeleteScheduleCampaign, useGetDeviceScheduleCampaigns } from '../../../../queries';
import { setShowAssignForm } from '../../../../redux/Slices/deviceSlice';
import { Message } from 'primereact/message';

function ScheduleCampaignList({ onClose = () => { }, showBtn = true }) {
  const dispatch = useDispatch();
  const showAssignForm = useSelector((state) => state.device.showAssignForm);

  const { data: deviceCampaigns, isLoading } = useGetDeviceScheduleCampaigns();
  const deleteScheduleCampaign = useDeleteScheduleCampaign();

  const deleteScheduledCampaign = async (id) => {
    await deleteScheduleCampaign.mutateAsync({ id })
  }

  return (
    <>
      {
        isLoading ?
          <div className='d-flex m-5 justify-content-center align-items-center '>
            <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
          </div>
          :
          !showAssignForm ?
            !_.isEmpty(deviceCampaigns) ?
              <>
                <div className="d-flex flex-col my-4 scroll_div schedule_campaign_container">
                  {
                    deviceCampaigns.map((campaign, index) => {
                      return <>
                        <Card className='mb-3 mx-3 schedule_card position-relative' disabled key={index}>
                          <div className="w-100 d-flex align-items-center">
                            <div className="col-1 p-3 d-flex justify-content-center"><i className="bx bx-time-five text-secondary fs-1 "></i></div>
                            <div className="col-9 fs-5 d-flex flex-col ps-3">
                              <span className='mb-2'>{campaign.name}</span>
                              <div className='d-flex'>
                                <span className="fs-10 text-capitalize text-center badge bg-label-secondary  d-flex flex-column me-2">
                                  {campaign.is_repeated ? "Repeated" : "once"}
                                </span>
                                <span className="text-center badge bg-label-secondary fs-10 d-flex  me-2">
                                  {
                                    !campaign.is_repeated ? campaign.date
                                      :
                                      campaign.days.length == 7 ?
                                        <div className='text-capitalize'>Daily</div>
                                        :
                                        campaign.days.map((day, index) => {
                                          return <div className="me-2" key={index}>
                                            {
                                              day.substring(0, 3).toUpperCase()
                                            }
                                          </div>
                                        })
                                  }
                                </span>
                                <span className="text-center badge bg-label-secondary fs-10 d-flex flex-column">
                                  {campaign.start_time + " - " + campaign.end_time}
                                </span>
                              </div>
                            </div>
                            {
                              !campaign.is_finished &&
                              <div className="col-2 text-end text-light cursor-pointer">
                                <i className="bx bx-trash cursor-pointer" onClick={() => deleteScheduledCampaign(campaign.id)} ></i>
                              </div>
                            }
                          </div>
                          {
                            campaign.is_finished &&
                            <div className='card_overlay p-4 d-flex align-items-center position-absolute bg-gray-100/75 w-100 h-100 top-0 start-0'>
                              <div className="col-10 text-end ">
                                <span className="text-center badge bg-label-danger ">finished</span>
                              </div>
                              <div className="col-2 text-end text-light cursor-pointer">
                                <i className="bx bx-trash" onClick={() => deleteScheduledCampaign(campaign.id)} ></i>
                              </div>
                            </div>
                          }

                        </Card>

                      </>
                    })
                  }
                </div>
              </>
              :
              <div className="d-flex p-5 justify-content-center ">
                <Message className="fw-bold fs-3 p-4 w-100" severity="info" text="There are no schedule campaigns has been assigned yet!" />
              </div>
            :
            <></>
      }
      {  !showAssignForm ?
        <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
          {
            showBtn ?
              <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                disabled={isLoading || deleteScheduleCampaign.isLoading} onClick={onClose} />
              :
              <></>
          }
          <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Assign Campaign"
            loading={isLoading || deleteScheduleCampaign.isLoading} onClick={() => dispatch(setShowAssignForm(true))} />
        </div> 
        :
        <></>
        }

    </>
  )
}

export default ScheduleCampaignList
