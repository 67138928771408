import React, { useState, useEffect, useRef, useContext, createContext, useMemo } from 'react';
import { useFetchDataByPath } from '../../queries/dataTable';
import { isEmpty } from 'lodash';

const ScrollLoading = createContext({});

export const ScrollLoadingProvider = (props) => {
    const [lastElement, setLastElement] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [data, setData] = useState([]);
    const [path, setPath] = useState("");
    const [ids, setIds] = useState([]);

    const { data: response, isLoading,isFetching, refetch } = useFetchDataByPath(false, path, pageNum);

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
     );

    useEffect(() => {
        if (!isEmpty(response?.data)) {
            setLastPage(response?.pagination?.total_pages);
    
            setData(prevData => {
                // Prevent duplication by checking if the data already exists
                const newData = pageNum === 1 ? response.data : [...prevData, ...response.data];
                return Array.from(new Map(newData.map(item => [item.id, item])).values());
            });
        }
    }, [response]);

    useEffect(() => {
        if (!isEmpty(path) && pageNum === 1) { 
            refetch();
        }
    }, [path]);

    useEffect(() => {
        if (rerender) {
            refetch();
        }
    }, [rerender]);

    useEffect(() => {
        if ((pageNum <= lastPage || lastPage == null) ) {
            refetch();
        }
    }, [pageNum]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    const resetScrollStates = () => {
        setIds([])
        setData([])
        setPath("")
        setPageNum(1)
    }
    return (
        <ScrollLoading.Provider value={{
            data, setData,
            ids, setIds,
            resetScrollStates,
            lastElement, setLastElement,
            isUploaded, setIsUploaded,
            lastPage, setLastPage,
            loading, setLoading,
            pageNum, setPageNum,
            rerender, setRerender,
            setPath, isLoading,isFetching,
            refetch
        }}>
            {props.children}
        </ScrollLoading.Provider>
    )

}

export const useScrollLoadingContext = () => {
    return useContext(ScrollLoading);
}
