import { createSlice } from "@reduxjs/toolkit";
import initialState from "./appsInitialState";
import appsActions from "./appsActions";

const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: appsActions,
});

export const {
  setCreateDialog,
  setPrayerEnableDialog,
  setIsLoading,
  setData,
  setSelectedFeed,
  setSelectedRss,
  setIsCreation,
  setNewSeparator,
  setRssSettings,
  setRssName,
  resetRssStates,
  rssDataHandler
} = appsSlice.actions;

export default appsSlice.reducer;
