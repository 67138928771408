import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from 'react-bootstrap';

import { useTitle } from "../../../../config/global";
import axiosInstance from '../../../../config/Axios';

import Layout from '../../../Layout/Layout';
import LayoutInfo from "./Setup/LayoutInfo";
import ScreenInfo from "./Setup/ScreenInfo";
import EditLayoutPopUp from './Setup/EditLayoutPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../redux/Slices/Global/globalSlice';
import { setScreens_setting, setSelectedLayout, setScreen, setScreenDetails, setReloadTrigger, setIsPublished } from '../../../../redux/Slices/layoutSlice';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

const LayoutSetup = () => {
    useTitle("Layout Setup")
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const screenInfoRef = useRef(null);
    const subLoaders = useSelector((state) => state.global.subLoaders || { Loading: false });
    const { openDialog, loading, isPublished } = useSelector((state) => state.global);

    const reloadTrigger = useSelector((state) => state.layout.reloadTrigger);
    const selectedLayout = useSelector((state) => state.layout.selectedLayout);

    const [titleLoading, setTitleLoading] = useState(true);

    useEffect(() => {
        dispatch(toggleLoader("Loading"))
        getLayoutData()
        getScreensData()
    }, [id])

    useEffect(() => {
        if (reloadTrigger) {
            dispatch(toggleLoader("Loading"))
            dispatch(setReloadTrigger(false))
            getLayoutData()
            getScreensData()
        }
    }, [reloadTrigger])

    function getLayoutData() {

        axiosInstance.get(`/layout/${id}`)
            .then((result) => {
                let data = result.data.data;
                const screens_Options = data.screens_setting.map(screen => ({
                    ...screen,
                }));
                dispatch(setSelectedLayout(data));
            })
            .finally(() => {
                setTitleLoading(false);
            })
            .catch((error) => {
                dispatch(toggleLoader("Loading"))
                console.error("Error in getLayoutData:", error);

            })
    }

    function getScreensData() {
        axiosInstance.get(`/layout/${id}/campaigns`)
            .then((result) => {
                let data = result.data.data;
                const screensData = data.map(screen => ({
                    ...screen,
                }));
                if (screensData.length > 0) {
                    dispatch(setScreenDetails(screensData[0]));
                    dispatch(setScreens_setting(screensData));

                    const allPublished = data.every(screen => screen.status === "PUBLISHED");

                    if (allPublished) {
                        dispatch(setIsPublished(true));
                    } else {
                        dispatch(setIsPublished(false));
                    }

                }
                dispatch(toggleLoader("Loading"))

            })
            .catch((error) => {
                dispatch(toggleLoader("Loading"))

                console.error("Error in getScreensData:", error);
            });
    }

    const subNavbarProps = useMemo(() => {
        return {
            title: titleLoading ? "Loading..." : selectedLayout?.title || " ",
            date: titleLoading ? "" : selectedLayout?.created_at || ""
        };
    }, [selectedLayout, titleLoading]);


    const subNavbarContent = useMemo(() => {
        return (
            <>
                <div className='d-none d-md-flex'>
                    <Button label={loading ?
                        <ProgressSpinner className='btn-spinner py-0' style={{ width: '20px', height: '20px' }} strokeWidth="2" fill="transparent" animationDuration="2s" />
                        : "Publish Layout"}
                        className={`fs_md_6 ${loading ? "pt-2 pb-1 me-2" : "py-2 me-2 "}`}
                        disabled={loading || isPublished}
                        onClick={() => {
                            if (screenInfoRef.current) {
                                screenInfoRef.current.publish();
                            }
                        }}
                    />
                </div>
            </>
        );
    }, [loading, isPublished]);

    return (
        <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent} padding={false}>
            {
                subLoaders.Loading ?
                    <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    </div>
                    :

                    // <div className=" mx-3 mx-md-3 px-2  container-p-y">

                    <div className="p-0 flex flex-col md:flex-row">
                        <div className="px-2 mt-4 w-full md:w-[65%] md:ml-5 md:mr-5   ">
                            <ScreenInfo ref={screenInfoRef} />
                        </div>
                        <div className="w-full md:w-[35%]">
                            <div className="bg-white">
                                <LayoutInfo />
                            </div>
                        </div>
                    </div>

                // </div>

            }
            {
                openDialog.EditLayoutPopUp && <EditLayoutPopUp />
            }
        </Layout>

    );
};

export default LayoutSetup;
