
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import axiosInstance from '../../../../../config/Axios';

import LayoutPreview from '../LayoutPreview';
import CustomLayoutPopUp from './CustomLayoutPopUp';
import TemplateCustomizationPopUp from './TemplateCustomizationPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog, toggleLoader } from '../../../../../redux/Slices/Global/globalSlice';
import {  setSelectedTemplate,  setReloadTrigger } from '../../../../../redux/Slices/layoutSlice';

const CustomLayouts = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { showToast } = useCustomToast();

    const { userTemplate } = useSelector((state) => state.layout);
    const { openDialog, subLoaders } = useSelector((state) => state.global);

    const handleTemplateSelect = (template) => {
        dispatch(toggleLoader("createLoading"))
        dispatch(setDisableBtn(true));
        axiosInstance.post(`/layout/${template.id}/select`)
            .then((result) => {
                const layout_Id = result.data.data[0].pivot.layout_id;
                dispatch(toggleLoader("createLoading"))
                dispatch(toggleDialog("CreateLayoutPopUp"))
                navigate(`/apps/layouts/${layout_Id}/show`);
                showToast('success', "Layout Creation", "Layout created successfully!");
                dispatch(setDisableBtn(false));
            })
            .catch((error) => {
                dispatch(toggleLoader("createLoading"))

                dispatch(setDisableBtn(false));
                showToast('error', 'Layout Creation', error);
            })
    }


    const handleTemplateCustomize = (template) => {
        dispatch(setSelectedTemplate(template));
        dispatch(toggleDialog("TemplateCustomizationPopUp"))
    }

    const handleTemplateDelete = (template) => {
        axiosInstance.delete(`/layout/${template.id}`);
        // dispatch(toggleDeleteDialog("layout"));
        showToast('success', 'Delete Template Layout', 'Layout Template Deleted Successfully');
        dispatch(setReloadTrigger(true))
        // dispatch(toggleLoader("createLoading"))

    }

    return (
        <>
            {
                subLoaders.createLoading ?
                    <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    </div>
                    :
                    <Row className=" overflow-y-auto overflow-x-hidden g-3 pe-5 pl-5  mb-5 pb-5 ">
                        <Col xs={12} sm={6} md={4} lg={3} onClick={() => {
                            dispatch(toggleDialog("CustomLayoutPopUp"))
                        }}>
                            <Card className="h-[277px] border border-dashed border-gray-300 shadow-none hover:shadow-md transition-shadow">
                                <Card.Body className="flex flex-col items-center justify-center h-40 bg-gray-100 cursor-pointer" >
                                    <i className="bx bx-plus bx-lg "></i>
                                    <div className="text-sm font-medium text-gray-600">Add New Template</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {userTemplate.map((template, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title><div className="text-[13px] text-center">{template.title}</div></Card.Title>
                                        <Card.Text><div className="ml-[10px] mr-[10px]">
                                            <LayoutPreview template={template} screens={template.screens_setting} onClick={() => {
                                                setSelectedTemplate(template)
                                                handleTemplateSelect(template)
                                            }} />
                                        </div></Card.Text>
                                    </Card.Body>
                                    <div className="w-100 pl-3 pb-3 flex">
                                        <button
                                            className="w-[40%] px-1 py-1 border-1 border-blue-700 text-[15px]  rounded-lg hover:bg-purple-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                                            onClick={() => handleTemplateSelect(template)}
                                        >
                                            Select
                                        </button>
                                        <button
                                            className="w-[40%]  px-1 py-1 border-1 ml-2 border-blue-700 text-[15px]  rounded-lg hover:bg-purple-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                                            onClick={() => handleTemplateCustomize(template)}
                                        >
                                            Customize
                                        </button>
                                        <button
                                            className="w-[20%] py-1 ml-3 text-[15px] hover:text-red duration-300 cursor-pointer"
                                            onClick={() => handleTemplateDelete(template)}
                                        >
                                            <i class='bx bxs-trash-alt'></i>
                                        </button>
                                    </div>


                                </Card>
                            </Col>
                        ))}



                    </Row>
            }
            {openDialog.CustomLayoutPopUp && <CustomLayoutPopUp />}
            {openDialog.TemplateCustomizationPopUp && <TemplateCustomizationPopUp />}

        </>
    );
};

export default CustomLayouts;