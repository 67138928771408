import React from 'react';
import { Link, useParams } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

import '../../../assets/vendor/css/pages/page-auth.css';
import landing from '../../../assets/img/illustrations/boy-with-rocket-light.png';
import logo from '../../../assets/img/logo.png';

import { useTitle, getFormErrorMessage } from '../../../config/global';
import { useResetPasswordMutation } from '../../../queries/auth';

import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

function ResetPassword () {
    useTitle('Reset Password');

    const { formState: { errors }, watch, handleSubmit, control } = useForm();
    const resetPassword = useResetPasswordMutation();
    const { token } = useParams();

    const onSubmit = async (data) => {
        await resetPassword.mutateAsync({
            token: token,
            data: data
        })
    };

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */ }
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                    <img src={ logo } className="img-fluid" width={ 200 } alt="logo" />
                                </Link>
                            </div>
                            <h5 className="mb-4">  Rest Password   </h5>
                            <form onSubmit={ handleSubmit(onSubmit) } className="mb-3">
                                {/* password */ }
                                <div className="mb-3 form-password-toggle">
                                    <div className="field">
                                        <label className="form-label">Password</label>
                                        <span className="p-float-label">
                                            <Controller name="password" control={ control }
                                                rules={ { required: 'Password is required.' } }
                                                render={ ({ field, fieldState }) => (
                                                    <Password id={ field.password } { ...field } autoFocus
                                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                        toggleMask inputRef={ field.ref }
                                                    />
                                                ) } />
                                        </span>
                                        { getFormErrorMessage('password', errors) }
                                    </div>
                                </div>

                                {/* Password Confirmation */ }
                                <div className="mb-5 form-password-toggle">
                                    <div className="field">
                                        <label className="form-label" >Confirm Password </label>
                                        <span className="p-float-label">
                                            <Controller name="password_confirmation" control={ control }
                                                rules={ {
                                                    required: 'Password confirmation is required!',
                                                    validate: (val) => {
                                                        if (watch('password') != val) {
                                                            return "Your passwords do no match";
                                                        }
                                                    },
                                                } }
                                                render={ ({ field, fieldState }) => (
                                                    <Password inputRef={ field.ref }
                                                        id={ field.password_confirmation } { ...field }
                                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                        feedback={ false } />
                                                ) } />
                                        </span>
                                        { getFormErrorMessage('password_confirmation', errors) }
                                    </div>
                                </div>

                                <button className="btn btn-primary d-grid w-100">Reset</button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword