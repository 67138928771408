import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'
import { isEmpty } from 'lodash';

import { useAssignCollectionContent, useGetCollectionContent } from '../../../queries/apps';
import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import MediaList from './MediaList';

function Add3DMedia({ selectedCollection }) {
    const { isLoading, data } = useGetCollectionContent(selectedCollection?.id);
    const assignContent = useAssignCollectionContent()
    const dispatch = useDispatch()

    const { openDialog } = useSelector((state) => state.global);
    const { ids, setIds } = useScrollLoadingContext();

    useEffect(() => {
        if (!isEmpty(data)) {
            const _ids = data?.map(element => element?.pivot?.related_media_id) || [];
            setIds(_ids)
        }
        return () => setIds([])
    }, [data])

    const assignHandler = async () => {
        await assignContent.mutateAsync({
            id: selectedCollection?.id,
            data: { "media_ids": ids }
        })
    }

    const onClose = () => dispatch(toggleDialog("addCollectionContent"))

    return (
        <Dialog visible={openDialog.addCollectionContent}
            style={{ width: '40%' }}
            breakpoints={{ '960px': '95vw' }}
            header={`Add 3D Media`}
            modal className="p-fluid"
            onHide={onClose}
        >
            <div className="d-flex flex-column">
                <div className="col-12 d-flex flex-wrap p-4 ">
                    <MediaList path={"/media/3D"} />
                </div>
                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                        disabled={isLoading || assignContent.isLoading}
                        onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Add Media"
                        loading={assignContent.isLoading || isLoading}
                        disabled={ids?.length === 0}
                        onClick={assignHandler}
                    />
                </div>
            </div>
        </Dialog >
    )
}

export default Add3DMedia