import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import MenuList from "./MenuList";
import logo from "../../assets/img/logo.png";
import "./css/NavTitle.css";

import { getTitle } from "../../config/global";

import { useSelector } from "react-redux";
import { useLogoutMutation } from "../../queries";

const Navbar = ({ content, title = null, date = null }) => {
    const [visibleLeft, setVisibleLeft] = useState(false);
    const titleData = title == null ? getTitle() : title;
    const dateData = date == null ? "" : date;
    const profileImage = useSelector((state) => state.global.profileImage);
    const navigate = useNavigate()
    const menuRef = useRef(null);

    const subMenuItems = [
        {
            label: "Billing Settings",
            icon: "pi pi-dollar",
            command: () => navigate("/profile", { state: { activeTab: "billing" } })
        },
        {
            label: "Account Settings",
            icon: "pi pi-user",
            command: () => navigate("/profile", { state: { activeTab: "account" } })
        },
        { separator: true },
        {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => logoutHandler(),
            className: "text-red-500"
        },
    ]
    const logout = useLogoutMutation();

    const logoutHandler = () => {
        logout.mutateAsync()
    }

    return (
        <>
            {/* Navbar Container */}
            <nav className="shadow-md py-0 layout-navbar navbar bg-navbar-theme flex-wrap" id="layout-navbar">

                <div className="shadow-md container-fluid d-flex justify-content-between align-items-center py-2 d-lg-none">
                    {/* Logo */}
                    <a href="/" className="d-flex align-items-center">
                        <img src={logo} className="logo_navTitle img-fluid" alt="logo" />
                    </a>

                    {/* Sidebar Menu Button */}
                    <div className="flex">
                        <img src={profileImage} className="w-px-40 object-fit-cover rounded-circle cursor-pointer" onClick={(e) => menuRef.current.toggle(e)} />

                        <Button
                            icon="fas fa-bars"
                            onClick={() => setVisibleLeft(true)}
                            className="p-2 bg-transparent border-0 text-light"
                        />
                    </div>
                </div>

                <div className="w-100  d-flex align-items-center justify-content-between  ">
                    {content ?
                        <>
                            {/* Back Button */}
                            <button className="border-right-only mx-1 px-2 py-2" onClick={() => window.history.go(-1)}>
                                <i className="fa-solid fa-chevron-left mr-1"></i>
                            </button></>
                        :
                        ""}

                    {/* Title */}
                    <div className="ml-5 my-2 flex-grow-1">
                        <span className="fs-3 fw-semibold d-block">{titleData}</span>
                        {date && <small className="text-muted">{dateData}</small>}
                    </div>

                    <div className="navbar-nav flex-row align-items-center ms-auto mr-5 d-lg-flex">
                        {content}
                    </div>
                </div>
            </nav>

            <Sidebar visible={visibleLeft} position="right" onHide={() => setVisibleLeft(false)}>
                <aside id="layout-menu" className="menu-vertical menu bg-menu-theme mx-auto">
                    <MenuList />
                </aside>
            </Sidebar>
            <Menu className="z-[9999]" model={subMenuItems} popup ref={menuRef} />

        </>
    );
};

export default Navbar;
