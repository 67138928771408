import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration } from '../../../config/global';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import { RSS_TABLE_CONFIG } from '../../../Constants/datatables';
import { useDeleteMedia } from '../../../queries/media';
import { setIsCreation, setRssSettings, setSelectedRss } from '../../../redux/Slices/Apps/appsSlice';

function RssDataTable() {
    useTitle('RSS');

    const { totalRecords, lazyParams, setLazyParams, data, onPage, onSort, onFilter, getDataTable } = useDataTableContext();
    const deleteRss = useDeleteMedia();
    const dispatch = useDispatch()

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev, ...RSS_TABLE_CONFIG
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Update RSS */}
                <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button disabled={deleteRss.isLoading} className={`btn btn-sm btn-icon update-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={() => editRss(rowData)}><i className="pi pi-pencil"></i></button>

                {/* Delete RSS */}
                <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button disabled={deleteRss.isLoading} className={`btn btn-sm btn-icon delete-record delete-button-${rowData.id}`}
                    data-pr-position="bottom" data-pr-tooltip="Delete" onClick={() => deleteHandler(rowData)} >
                    <i className="bx bx-trash"></i>
                </button>
            </div>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return formatDuration(rowData.duration)
    }

    const editRss = (data) => {
        let _settings = data?.rss_settings;
        dispatch(setRssSettings({
            ..._settings,
            bg_color: _settings?.bg_color ?? _settings?.bgColor,
            text_color: _settings?.text_color ?? _settings?.textColor,
            direction: _settings?.direction === "ltr" ? "left" : "right",
        }))
        dispatch(setSelectedRss(data))
        dispatch(setIsCreation(false))
        dispatch(toggleDialog("rssFeedAddition"))
    }

    const deleteHandler = async (data) => {
        await deleteRss.mutateAsync(
            { id: data.id },
            {
                onSuccess: () => {
                    setLazyParams(prev => ({
                        ...prev, ...RSS_TABLE_CONFIG
                    }))
                }
            });
    }

    return (
        <div className='table-responsive text-nowrap'>
            <DataTable value={data?.media} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={onFilter} filters={lazyParams.filters} loading={getDataTable.isLoading} className="table"
            >
                <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                <Column field="duration" className='text-center' header="Total Duration" body={durationBodyTemplate} />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column body={actionBodyTemplate} exportable={false} ></Column>
            </DataTable>
        </div>
    )
}

export default RssDataTable