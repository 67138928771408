import Layout from '../../Layout/Layout';
import CreateBroadcast from './components/CreateBroadcast';
import StreamsDataTable from './components/StreamsDataTable';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useTitle } from '../../../config/global';

function BroadcastCard() {
    const { openDialog, isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch()
    useTitle("Broadcast");

    return (
        <Layout>
            <div className="card h-100">
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between   flex-shrink-0 ">
                        <div className='d-flex align-items-center '>
                            <span className="avatar-initial rounded bg-label-primary p-2 me-2">
                                <i className="fas fa-broadcast-tower"></i>
                            </span>
                            <div className='d-flex flex-column'>
                                <h5 className="fw-semibold my-0  ">Broadcast App</h5>
                                <small className=' text-capitalize'>

                                </small>
                            </div>
                        </div>
                        <div className='d-flex align-items-center '>
                            <button className="btn btn-primary mx-1"
                                disabled={isLoading}
                                onClick={() => window.history.go(-1)} >
                                Back
                            </button>
                            <button type="reset" className="btn btn-primary "
                                disabled={isLoading}
                                onClick={() => dispatch(toggleDialog("createBroadcastDialog"))} >
                                New Broadcast
                            </button>
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <StreamsDataTable />
                    </div>
                </div>
            </div>
            {openDialog.createBroadcastDialog && <CreateBroadcast />}
        </Layout>
    )
}

export default BroadcastCard