import React, { useEffect } from 'react'

import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';

import { useProfileContext } from '../../../Contexts/ProfileContext';

import FeaturesList from './FeaturesList';
import { Checkbox } from 'primereact/checkbox';
import { useState } from 'react';
import DevicesList from './DevicesList';
import { useScrollLoadingContext } from '../../../Contexts/ScrollLoadingContext';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function UpgradeDialog() {
    const { paymentData, onScreenChange, numberOfScreens, setSelectedPricing, selectedPricing, setSelectedPackage, selectedPackage } = useProfileContext();
    const dispatch = useDispatch();
    const { openDialog, packages, companyDetails } = useSelector((state) => state.global);
    const { ids, setIds, data, pageNum, isFetching, lastPage, setLastElement, setPath } = useScrollLoadingContext();

    const [selectDevice, setSelectDevice] = useState();
    const [isValid, setIsValid] = useState(true);
    const [isActive, setIsActive] = useState(companyDetails?.subscription?.status == "ACTIVE");
    const [showDeviceList, setShowDeviceList] = useState();

    const closeDialog = () => {
        setIds([]);
        dispatch(toggleDialog("upgrade"))
    }

    const onPackageChange = (isEnable, _package) => {
        if (isEnable) {
            return;
        } else {
            setSelectedPackage(_package)
        }
    }

    const onDevicesChange = (e) => {
        let selectedDevices = [...ids];

        if (e.checked)
            selectedDevices.push(e.value);
        else
            selectedDevices.splice(selectedDevices.indexOf(e.value), 1);

        setIds(selectedDevices);
    }

    const upgrade = () => {
        if (isValid) {
            dispatch(toggleDialog("upgrade"))
            dispatch(toggleDialog("paymentDialog"))
        }
    }

    useEffect(() => {
        setPath(`/company/devices/list?page=${pageNum}&per_page=15&action=addScreens`)

        let index = packages.findIndex(obj => obj.title === companyDetails?.package?.title)
        if (index + 1 < packages.length)
            ++index

        setSelectedPackage(packages[index])
        setSelectedPricing(packages[index]?.prices[0])

        return () => {
            setPath("")
        }
    }, [])

    useEffect(() => {
        if (ids?.length > 0 && ids?.length !== numberOfScreens)
            setIsValid(false)
        else {
            setIsValid(true)
        }
        // }, [ids])
    }, [ids, numberOfScreens])


    useEffect(() => {
        setShowDeviceList(numberOfScreens < data.length)
        // }, [data])
    }, [data, numberOfScreens])


    useEffect(() => {
        if (ids?.length > 0 && ids?.length < numberOfScreens)
            setIsValid(false)
        else {
            setIsValid(true)
        }
    }, [ids])

    useEffect(() => {
        if (numberOfScreens < data.length && ids.length > numberOfScreens) {
            setIds(prev => prev.slice(0, numberOfScreens))
        }
        if (numberOfScreens > data.length)
            setIds([])

        setShowDeviceList(numberOfScreens < data.length)
    }, [numberOfScreens])

    return (
        <Dialog visible={openDialog.upgrade}
            breakpoints={{ '960px': '95vw' }}
            style={{ width: "60%" }}
            modal
            className="p-fluid custom-dialog position-relative"
            onHide={() => closeDialog()}
        >
            {/* Close button  */}
            <button className='position-absolute close_icon text-primary' onClick={() => closeDialog()} >
                <i className="fa-regular fa-circle-xmark text-primary"></i>
            </button>
            <div className="d-flex">
                <div className="col-6 d-flex flex-column p-5">
                    <span className=' fs-5 fw-bold mb-2 col-12'>Choose Plan</span>

                    {/* Packages */}
                    <div className='col-12  d-flex flex-column '>
                        <div className="col-12 fs-8 ms-1">
                            Choose Plan Category
                        </div>
                        <div className="col-12 d-flex mt-1 flex-wrap">
                            {
                                packages.map((item) => {
                                    let _disabled = item.is_free || ((companyDetails?.basic_price >= item?.basic_price) && isActive)
                                        || ((companyDetails?.basic_price > item?.basic_price) && !isActive)

                                    return (
                                        <div key={item.id}
                                            className={`${_disabled ? "text-light" : ""} col-4 d-flex justify-content-between pe-1`} >
                                            <div key={item.id} className={` field-radiobutton col-12 d-flex align-items-center  mb-3 mx-1 rounded p-2 
                                            ${selectedPackage?.id == item.id ? "border-2  border-primary" : " border-1"}`} >
                                                <RadioButton
                                                    inputId={item.id}
                                                    name="package"
                                                    className='d-flex align-items-center justify-content-center radio_bg'
                                                    onChange={() => onPackageChange(item.is_free, item)}
                                                    value={item.id}
                                                    checked={selectedPackage?.id === item.id && !_disabled}
                                                    disabled={_disabled}
                                                />
                                                <label htmlFor={item.id} className={` ${item.is_free ? "" : "cursor-pointer"} fs-8`} >
                                                    {item.title}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>

                    {/* Pricing  */}
                    <div className='col-12  d-flex flex-column mb-3'>
                        <div className="col-12 fs-8 ms-1">
                            Choose Billing Cycle
                        </div>
                        <div className="col-12 d-flex mt-1 flex-wrap">
                            {
                                selectedPackage?.prices?.map((item) => {
                                    let _enable = (selectedPackage.title == companyDetails?.package?.title && isActive)
                                        || selectedPackage?.is_free

                                    return (
                                        <div key={item.id} onClick={() => _enable ? "" : setSelectedPricing(item)}
                                            className={`${item.is_free ? "text-light" : ""} col-4 d-flex justify-content-between pe-1`}
                                        >
                                            <div key={item.id} className={`field-radiobutton col-12 d-flex align-items-center position-relative  mb-1 mx-1 rounded p-2
                                                      ${selectedPricing?.period == item.period && !_enable ? "border-2 border-primary" : " border-1"}`} >
                                                {
                                                    item?.discount_percentage && !_enable ?
                                                        <>
                                                            <div className='discount_label fs-12' > </div>
                                                            <span className='discount'>{item.discount_percentage}%</span>
                                                        </>
                                                        :
                                                        <> </>
                                                }
                                                <RadioButton
                                                    inputId={item.id}
                                                    name="package"
                                                    className='d-flex align-items-center justify-content-center radio_bg'
                                                    value={item.id}
                                                    checked={selectedPricing?.period === item.period}
                                                    disabled={_enable}
                                                />
                                                <label htmlFor={item.id} className={`fs-8 cursor-pointer ${_enable ? "text-light" : ""}`}  >
                                                    {`${item.numeric_period} Month${item.numeric_period > 1 ? "s" : ""}`}
                                                    <br />
                                                    {`$ ${item.price} `}
                                                    <span className='fs-10 fw-light'>
                                                        /screen
                                                    </span>

                                                </label>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>


                    {/* Number of Screens */}
                    <div className='col-12  d-flex flex-column mb-3'>
                        <div className="col-12 fs-8 ms-1">
                            Number of Screens
                        </div>
                        <div className="col-5 d-flex  mt-1">
                            <InputNumber inputId="number_of_screens"
                                value={numberOfScreens}
                                onValueChange={(e) => onScreenChange("onChange", e.value)}
                                min={1}
                                className=''
                                disabled={isActive}
                            />
                            <div className='mx-2 arrow_container mt-1'>
                                <button disabled={isActive} onClick={() => onScreenChange("increment")}>
                                    <i className={`fas fa-caret-square-up fs-4 ${isActive == 1 ? "text-light" : " text-primary cursor-pointer"} `} ></i>
                                </button>
                                <button disabled={numberOfScreens == 1 || isActive} onClick={() => onScreenChange("decrement")} >
                                    <i className={`fas fa-caret-square-down fs-4 ${(numberOfScreens == 1 || isActive) ? "text-light" : " text-primary cursor-pointer"}`}></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-12 ">
                            {
                                (numberOfScreens < companyDetails?.total_used_devices && !isActive) ?
                                    <div className="field-checkbox mt-3 fs-6">
                                        <Checkbox inputId="binary" checked={selectDevice} className="me-2" onChange={e => setSelectDevice(e.checked)} />
                                        <label htmlFor="binary">Manually choose the screens to keep</label>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    {/* Amount  */}
                    <div className='col-12 d-flex flex-column mb-4 py-2 px-4 bg_gray'>
                        <div className="col-12 d-flex flex-wrap fs-8 ms-2">
                            <div className="col-8 mb-1">Subtotal</div>
                            <div className="col-4 text-primary">$ {paymentData?.subtotal}</div>

                            <div className="col-8">Tax <span className='fs-10'>( {selectedPackage?.tax}% VAT )</span></div>
                            <div className="col-4 text-primary">$ {paymentData?.tax}</div>
                        </div>
                        <hr className='my-2' />
                        <div className="col-12 d-flex ms-2 fs-6 fw-bold">
                            <div className="col-8 ">Total</div>
                            <div className="col-4 text-primary">$ {(paymentData?.total)}</div>
                        </div>
                    </div>

                    {/* Form control */}
                    <div className='d-flex justify-content-center '>
                        <button type="reset" className="btn btn-label-secondary me-3 px-4 border "
                            onClick={closeDialog} data-bs-dismiss="modal" aria-label="Close">
                            Cancel
                        </button>
                        <button disabled={selectedPackage?.is_free || (isActive && (selectedPackage?.title == companyDetails?.package?.title))} type="submit" className="btn btn-primary px-4" onClick={() => { upgrade() }}>
                            Upgrade Plan
                        </button>
                    </div>
                </div>

                <div className="col-6 d-flex flex-column p-5 bg_gray">
                    {showDeviceList && numberOfScreens < data?.length ? (

                        <>
                            {/* Device List*/}
                            <span className='fs-5 fw-bold mb-2'>Choose the screens you want to keep:</span>

                            {data?.length === 0 && isFetching ? (
                                <ProgressSpinner style={{ width: "60px", height: "60px" }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                            ) : (
                                <div className={`d-flex flex-column scroll_container scroll_div px-2 w-100`}>
                                    {data?.map((item, index) => {
                                        let _disabled = numberOfScreens === ids.length && ids.indexOf(`${item.id}`) === -1;

                                        return (
                                            <div key={`${item.type}-${index}`} className="col-12 d-flex align-items-center justify-content-between my-1 pb-2">
                                                <div className="col-2 col-sm-1 pe-2">
                                                    <Checkbox inputId={item.id} name="media"
                                                        disabled={_disabled}
                                                        className='me-3'
                                                        value={item.id}
                                                        onChange={onDevicesChange}
                                                        checked={ids.indexOf(`${item.id}`) !== -1}
                                                    />
                                                </div>
                                                <label htmlFor={item.id} className={`col-10 col-sm-11 d-flex text-truncate ${_disabled ? "text-light" : ""}`}>
                                                    {item.name}
                                                </label>
                                            </div>
                                        );
                                    })}

                                    {isFetching && (
                                        <ProgressSpinner style={{ width: '60px', height: '60px', opacity: 0.1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                    )}
                                </div>
                            )}

                            {!isValid && (
                                <div className="text-danger">
                                    {`You have to select ${numberOfScreens - ids?.length} more device${numberOfScreens - ids.length === 1 ? "" : "s"}!`}
                                </div>
                            )}
                            {/* Features List*/}
                            <div className="mt-4">
                                <span className='fs-5 fw-bold mb-2'>Features of {selectedPackage?.title}:</span>
                                <FeaturesList selectedPackage={selectedPackage} />
                            </div>
                        </>

                    ) : (
                        <>
                            {/* Features List*/}
                            <span className='fs-5 fw-bold mb-2'>Included with {selectedPackage?.title}:</span>
                            <FeaturesList selectedPackage={selectedPackage} />
                        </>
                    )}
                </div>
            </div>
        </Dialog >
    )
}

export default UpgradeDialog