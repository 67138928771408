import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useEffect } from 'react'

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useCreate3dCollection, useUpdate3dCollection } from '../../../queries/apps';
import { getFormErrorMessage } from '../../../config/global';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { setIsCreation } from '../../../redux/Slices/Apps/appsSlice';

function CreateEditCollection({ selectedCollection }) {
    const { openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const createCollection = useCreate3dCollection();
    const updateCollection = useUpdate3dCollection();

    const { formState: { errors }, handleSubmit, control, reset } = useForm();
    const { isCreation } = useSelector(state => state.apps);

    useEffect(() => {
        if (!isCreation) {
            reset({ name: selectedCollection?.file_name })
        }

        return () => dispatch(setIsCreation(true));
    }, [])

    const submitHandler = async (formData) => {
        if (isCreation) {
            await createCollection.mutateAsync(formData)
        } else {
            await updateCollection.mutateAsync({
                id: selectedCollection?.id,
                "data": formData
            })
        }
    }

    const onClose = () => dispatch(toggleDialog("createEditCollection"))

    return (
        <Dialog visible={openDialog.createEditCollection}
            style={{ width: '30%' }}
            breakpoints={{ '960px': '95vw' }}
            header={`Collection ${isCreation ? "Creation" : "Editing"}`}
            modal className="p-fluid"
            onHide={onClose}
        >
            <form onSubmit={handleSubmit(submitHandler)} className="d-flex flex-wrap">
                <div className="col-12 d-flex flex-wrap p-4 ">
                    <div className='mb-2 col-12 d-flex flex-column'>
                        <label htmlFor="" className={`form-label text-capitalize mb-2 `} >Collection Name</label>
                        <Controller name="name" control={control}
                            rules={{ required: 'Collection name is required.' }}
                            render={({ field, fieldState }) => (
                                <InputText
                                    type="text"
                                    value={field.value}
                                    id={field.name}
                                    {...field}
                                    inputRef={field.ref}
                                    className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                />
                            )} />

                        {getFormErrorMessage("name", errors)}
                    </div>
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                        disabled={createCollection.isLoading || updateCollection.isLoading}
                        onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label={isCreation ? "Create" : "Update"}
                        loading={createCollection.isLoading || updateCollection.isLoading} />
                </div>
            </form>

        </Dialog >
    )
}

export default CreateEditCollection