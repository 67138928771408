import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';

import { designOptions, blendingOptions, perPageOptions } from '../../../../config/constants';

const ScreenSides = ({ feeds }) => {
    const { rssSettings } = useSelector(state => state.apps);

    const [index, setIndex] = useState(0);
    const [counter, setCounter] = useState(1);
    const [progress, setProgress] = useState(0);
    const [positionClass, setPositionClass] = useState({
        container: "col-12 ",
        firstChild: "col-5 m-0 p-0 h-100",
        secondChild: "col-7 py-4 px-3 my-auto",
        description: "fs-12",
        title: "fs-4",
    })

    useEffect(() => {
        if (counter === rssSettings?.duration) {
            clearInterval(intervalId);
            setProgress(0)
            if (index + 1 < feeds?.length) {
                setIndex(prev => prev + 1)
            }
            else
                setIndex(0)
        }

        // save intervalId to clear the interval when the component re-renders
        var intervalId = setInterval(() => {
            setProgress(((counter + 1) / rssSettings?.duration) * 100)
            setCounter(prev => prev + (1));
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [counter]);

    useEffect(() => {
        if (rssSettings.duration > 3)
            setCounter(1)
    }, [index, rssSettings.duration]);

    useEffect(() => {
        let _position = rssSettings?.position;
        let _style = {}

        if (_position == "RIGHT" || _position == "LEFT")
            _style = {
                container: `col-6 flex-column `,
                firstChild: "col-12 m-0 p-0",
                secondChild: "col-12 my-auto mx-0 p-2",
                description: "fs-12",
                title: "fs-6",
            }
        else
            _style = {
                container: "col-12 ",
                firstChild: "col-5 m-0 p-0 h-100",
                secondChild: "col-7 py-4 px-3 my-auto",
                description: "",
                title: "fs-4",
            }

        setPositionClass(_style)
    }, [rssSettings.position]);

    return (
        <div className={` overflow-hidden ${positionClass?.container} d-flex h-100`} style={{ backgroundColor: rssSettings?.bg_color ?? rssSettings?.bgColor }} >
            <div className={`${positionClass?.firstChild}`}
                style={{
                    height: rssSettings?.image_fullscreen ? "100vh" : feeds[index]?.image ? "50%" : "0",
                    display: feeds[index]?.image ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: feeds[index]?.image ? `url(${feeds[index]?.image})` : "none",
                    backgroundSize: rssSettings?.image_fullscreen ? "contain" : "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                }}
            >
            </div>
            <div className={`${positionClass?.secondChild}`}>
                <div className={`fw-bold mb-2  `}

                    style={{
                        color: rssSettings?.text_color,
                        fontFamily: rssSettings?.font_family,
                        fontSize: `${rssSettings?.font_size}px`,
                        direction: rssSettings?.language === "ar" ? "rtl" : "ltr"
                    }}
                >
                    {feeds[index]?.title}
                </div>
                <div className={`w-50 border-top-4  `} >
                    <ProgressBar value={progress} showValue={false}></ProgressBar>
                </div>
                <p className={`my-3 ${positionClass?.description}`}
                    style={{
                        color: rssSettings?.text_color,
                        fontFamily: rssSettings?.font_family,
                        fontSize: `${rssSettings?.font_size}px`,
                        direction: rssSettings?.language === "ar" ? "rtl" : "ltr"
                    }}>
                    {
                        feeds[index]?.description
                    }
                </p>
            </div>
        </div>
    )
}

const VideoInputs = ({ control, watch }) => {

    return (
        <>
            {/* Display video content */}
            <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                <label htmlFor="display_video_content" className='col-10 mb-2 form-label  text-capitalize '>Display video content</label>
                <Controller name="display_video_content" control={control} className="col-2 "
                    rules={{ required: false }}
                    render={({ field, fieldState }) => (
                        <InputSwitch
                            checked={field.value}
                            id={field.name} {...field}
                            value={field.value}
                            inputRef={field.ref}
                        />
                    )} />
            </div>
            {
                watch("display_video_content") ?
                    <>
                        {/* Mute videos */}
                        <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                            <label htmlFor="mute_video" className='col-10 mb-2 form-label text-capitalize '>Mute videos</label>
                            <Controller name="mute_video" control={control} className="col-2 "
                                rules={{ required: false }}
                                render={({ field, fieldState }) => (
                                    <InputSwitch
                                        checked={field.value}
                                        id={field.name} {...field}
                                        value={field.value}
                                        inputRef={field.ref}
                                    />
                                )} />
                        </div>

                        {/* Play videos full length */}
                        <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                            <label htmlFor="play_video_full_length" className='col-10 mb-2 form-label  text-capitalize '>Play videos full length</label>
                            <Controller name="play_video_full_length" control={control} className="col-2 "
                                rules={{ required: false }}
                                render={({ field, fieldState }) => (
                                    <InputSwitch
                                        checked={field.value}
                                        id={field.name} {...field}
                                        value={field.value}
                                        inputRef={field.ref}
                                    />
                                )} />
                        </div>
                    </>
                    :
                    <> </>
            }
        </>
    )
}

const LayoutInputs = ({ control, watch }) => {
    return (
        <>
            {/* Show image from article */}
            <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                <label htmlFor="show_image" className='col-10 mb-2 form-label text-capitalize '>Show image from article</label>
                <Controller name="show_image" control={control} className="col-2"
                    rules={{ required: false }}
                    render={({ field, fieldState }) => (
                        <InputSwitch
                            checked={field.value}
                            id={field.name} {...field}
                            value={field.value}
                            inputRef={field.ref}
                        />
                    )} />
            </div>

            {/* Revert to original layout */}
            <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                <label htmlFor="original_layout" className='col-10 mb-2 form-label text-capitalize '>Revert to original layout</label>
                <Controller name="original_layout" control={control} className="col-2 "
                    rules={{ required: false }}
                    render={({ field, fieldState }) => (
                        <InputSwitch
                            checked={field.value}
                            id={field.name} {...field}
                            value={field.value}
                            inputRef={field.ref}
                        />
                    )} />
            </div>

            {
                watch("original_layout") ?
                    <>
                        {/* Design */}
                        <div className='col-12 mb-3'>
                            <label htmlFor="" className='mb-2 form-label'>Design</label>
                            <Controller name="design" control={control}
                                rules={{ required: "Design is required!" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        id={field.name} {...field}
                                        value={field.value}
                                        options={designOptions}
                                        onChange={(e) => { field.onChange(e.value) }}
                                        optionLabel="label"
                                        optionValue="value"
                                        inputRef={field.ref}
                                        placeholder="select..."
                                        className={`w-100 `}
                                    />
                                )
                                } />
                        </div>

                        {/* Image Blending */}
                        <div className='col-12 mb-3'>
                            <label htmlFor="" className='mb-2 form-label text-capitalize'>Image Blending</label>
                            <Controller name="image_blending" control={control}
                                rules={{ required: "image_blending is required!" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        id={field.name} {...field}
                                        value={field.value}
                                        options={blendingOptions}
                                        onChange={(e) => { field.onChange(e.value) }}
                                        optionLabel="label"
                                        optionValue="value"
                                        inputRef={field.ref}
                                        placeholder="select..."
                                        className={`w-100`}
                                    />
                                )
                                } />
                        </div>

                        {/* Posts per page */}
                        <div className='col-12 mb-3'>
                            <label htmlFor="" className='mb-2 form-label  text-capitalize'>Posts per page</label>
                            <Controller name="posts_per_page" control={control}
                                rules={{ required: "posts_per_page is required!" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        id={field.name} {...field}
                                        value={field.value}
                                        options={perPageOptions}
                                        onChange={(e) => { field.onChange(e.value) }}
                                        optionLabel="label"
                                        optionValue="value"
                                        inputRef={field.ref}
                                        placeholder="select..."
                                        className={`w-100`}
                                    />
                                )
                                } />
                        </div>
                    </>
                    :
                    <>

                        {/* Stretch images to fill*/}
                        <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                            <label htmlFor="stretch_image" className='col-10 mb-2 form-label  text-capitalize '>Stretch images to fill</label>
                            <Controller name="stretch_image" control={control} className="col-2 "
                                rules={{ required: false }}
                                render={({ field, fieldState }) => (
                                    <InputSwitch
                                        checked={field.value}
                                        id={field.name} {...field}
                                        value={field.value}
                                        inputRef={field.ref}
                                    />
                                )} />
                        </div>

                        {/* Display images fullscreen */}
                        <div className='col-12 mb-3 d-flex  justify-content-between align-items-center'>
                            <label htmlFor="image_fullscreen" className='col-10 mb-2 form-label text-capitalize '>Display images fullscreen</label>
                            <Controller name="image_fullscreen" control={control} className="col-2 "
                                rules={{ required: false }}
                                render={({ field, fieldState }) => (
                                    <InputSwitch
                                        checked={field.value}
                                        id={field.name} {...field}
                                        value={field.value}
                                        inputRef={field.ref}
                                    />
                                )} />
                        </div>
                    </>
            }
        </>
    )
}

export {
    ScreenSides,
    VideoInputs,
    LayoutInputs
}