import React, { useState, useEffect } from "react";

import { Rnd } from "react-rnd";
import { Spinner } from 'react-bootstrap';
import "../../../../css/apps.css"

import AspectRatioController from "./AspectRatioController";
import { COMMON_ASPECT_RATIOS, layoutColor } from '../../../../../../config/layoutConstans';

import axiosInstance from "../../../../../../config/Axios";
import { useCustomToast } from '../../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../../../redux/Slices/Global/globalSlice';

const DynamicContainer = ({ areas, unit, setAreas, currentAspectRatio, setCurrentAspectRatio, setSelectedArea, selectedArea, isMaximized }) => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    const subLoaders = useSelector((state) => state.global.subLoaders);
    const { selectedTemplate, selectedLayout } = useSelector((state) => state.layout);

    const [aspectWidth, setAspectWidth] = useState();
    const [aspectHeight, setAspectHeight] = useState();
    const [aspectRatios, setAspectRatios] = useState([]);

    useEffect(() => {
        dispatch(toggleLoader("createLoading"))
        axiosInstance.get(`/aspect-ratio`)
            .then((res) => {
                const data = res.data.data;
                setAspectRatios(data)
                if (selectedTemplate?.aspect_ratio_id) {
                    const selectedAspect = data.find(item => item.id === selectedTemplate.aspect_ratio_id);
                    setCurrentAspectRatio(selectedAspect);
                } else if (selectedLayout?.aspect_ratio_id) {
                    const selectedAspect = data.find(item => item.id === selectedLayout.aspect_ratio_id);
                    setCurrentAspectRatio(selectedAspect);
                } else {
                    setCurrentAspectRatio(data[1])
                }
                setAspectWidth(currentAspectRatio.width)
                setAspectHeight(currentAspectRatio.height)
                dispatch(toggleLoader("createLoading"))

            })
    }, []);

    useEffect(() => {
        if (currentAspectRatio) {
            const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;

            if (COMMON_ASPECT_RATIOS[aspectKey]) {
                setAspectWidth(COMMON_ASPECT_RATIOS[aspectKey].width);
                setAspectHeight(COMMON_ASPECT_RATIOS[aspectKey].height);
            } else {
                setAspectWidth(currentAspectRatio.width);
                setAspectHeight(currentAspectRatio.height);
            }
        }
    }, [currentAspectRatio]);

    const calculateScaledDimensions = () => {
        if (aspectWidth === 0 || aspectHeight === 0) return { width: 0, height: 0 };
        let widthRatio, heightRatio;
        if (isMaximized) {
            widthRatio = 690 / aspectWidth;
            heightRatio = 590 / aspectHeight;
        } else {
            widthRatio = 500 / aspectWidth;
            heightRatio = 400 / aspectHeight;
        }

        const scaleFactor = Math.min(widthRatio, heightRatio);

        const scaledWidth = aspectWidth * scaleFactor;
        const scaledHeight = aspectHeight * scaleFactor;

        return { width: scaledWidth, height: scaledHeight };
    };

    const { width: scaledWidth, height: scaledHeight } = calculateScaledDimensions();

    const handleDragStop = (id, d) => {
        setAreas((prevAreas) =>
            prevAreas.map((area) => {
                let newX, newY, maxX, maxY;
                if (area.id !== id) return area;

                if (unit.value === "%") {
                    newX = (d.x / scaledWidth) * 100;
                    newY = (d.y / scaledHeight) * 100;

                    maxX = 100 - area.width;
                    maxY = 100 - area.height;


                } else {
                    newX = (d.x / scaledWidth) * aspectWidth;
                    newY = (d.y / scaledHeight) * aspectHeight;

                    maxX = aspectWidth - area.width;
                    maxY = aspectHeight - area.height;
                }

                newX = Math.min(newX, maxX);
                newY = Math.min(newY, maxY);

                const { newX: snappedX, newY: snappedY } = checkNearbyAreas(area, prevAreas, newX, newY, area.width, area.height);
                return {
                    ...area,
                    start_margin: isNaN(snappedX) ? 0 : parseFloat(snappedX.toFixed(1)) <= 0 ? 0 : parseFloat(snappedX.toFixed(1)),
                    top_margin: isNaN(snappedY) ? 0 : parseFloat(snappedY.toFixed(1)) <= 0 ? 0 : parseFloat(snappedY.toFixed(1))
                };
            })
        );
    };

    const handleResizeStop = (id, ref, position) => {
        let maxWidth, maxHeight, newX, newY
        let newWidth = parseFloat(ref.style.width);
        let newHeight = parseFloat(ref.style.height);

        newWidth = parseFloat(newWidth.toFixed(1));
        newHeight = parseFloat(newHeight.toFixed(1));

        if (unit.value == "%") {

            newX = (position.x / scaledWidth) * 100;
            newY = (position.y / scaledHeight) * 100;
            newWidth = parseFloat(newWidth.toFixed(1));
            newHeight = parseFloat(newHeight.toFixed(1));

            maxWidth = 100 - newX
            maxHeight = 100 - newY

            newWidth = Math.min(newWidth, maxWidth);
            newHeight = Math.min(newHeight, maxHeight);


            newX = parseFloat(newX.toFixed(1));
            newY = parseFloat(newY.toFixed(1));

        } else {

            newX = (position.x * aspectWidth) / scaledWidth;
            newY = (position.y * aspectHeight) / scaledHeight;
            newWidth = (newWidth * aspectWidth) / 100;
            newHeight = (newHeight * aspectHeight) / 100;

            maxWidth = aspectWidth - newX
            maxHeight = aspectHeight - newY

            newWidth = Math.min(newWidth, maxWidth);
            newHeight = Math.min(newHeight, maxHeight);

            newX = parseFloat(newX.toFixed(1));
            newY = parseFloat(newY.toFixed(1));

        }
        const { newX: snappedX, newY: snappedY, newWidth: snappedWidth, newHeight: snappedHeight } =
            checkNearbyAreas({ id }, areas, newX, newY, newWidth, newHeight);

        setAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? {
                        ...area,
                        width: snappedWidth,
                        height: snappedHeight,
                        start_margin: isNaN(snappedX) ? 0 : parseFloat(snappedX.toFixed(1)) <= 0 ? 0 : parseFloat(snappedX.toFixed(1)),
                        top_margin: isNaN(snappedY) ? 0 : parseFloat(snappedY.toFixed(1)) <= 0 ? 0 : parseFloat(snappedY.toFixed(1))
                    }
                    : area
            )
        );
    };

    const SNAP_THRESHOLD = unit.value == "px" ? 40 : 4; // the too close limit 


    const checkNearbyAreas = (currentArea, areas, newX, newY, newWidth, newHeight) => {
        areas.forEach(area => {
            if (area.id === currentArea.id) return;

            // R2L boundary 
            if (Math.abs(newX + newWidth - area.start_margin) < SNAP_THRESHOLD) {
                newX = area.start_margin - newWidth;
            }
            // L2R  
            if (Math.abs(newX - (area.start_margin + area.width)) < SNAP_THRESHOLD) {
                newX = area.start_margin + area.width;
            }
            //  B2T 
            if (Math.abs(newY + newHeight - area.top_margin) < SNAP_THRESHOLD) {
                newY = area.top_margin - newHeight;
            }
            //  T2B 
            if (Math.abs(newY - (area.top_margin + area.height)) < SNAP_THRESHOLD) {
                newY = area.top_margin + area.height;
            }
            // R2R
            if (Math.abs(newX + newWidth - (area.start_margin + area.width)) < SNAP_THRESHOLD) {
                newX = area.start_margin + area.width - newWidth;
            }

            // L2L
            if (Math.abs(newX - area.start_margin) < SNAP_THRESHOLD) {
                newX = area.start_margin;
            }

            // B2B
            if (Math.abs(newY + newHeight - (area.top_margin + area.height)) < SNAP_THRESHOLD) {
                newY = area.top_margin + area.height - newHeight;
            }

            // T2T
            if (Math.abs(newY - area.top_margin) < SNAP_THRESHOLD) {
                newY = area.top_margin;
            }
        });

        return { newX, newY, newWidth, newHeight };
    };

    return (
        <>{
            subLoaders.createLoading ?
                <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                </div>
                :

                <div className={`overflow-auto w-full lg:w-1/2 mt-3 `}>

                    <AspectRatioController aspectRatios={aspectRatios} currentAspectRatio={currentAspectRatio} setCurrentAspectRatio={setCurrentAspectRatio} setAspectRatios={setAspectRatios} />

                    <div className={`overflow-hidden flex relative pb-5 w-full`}>
                        <div
                            className={`overflow-hidden p-4 relative border border-gray-300 rounded-md   `}
                            style={{
                                width: scaledWidth,
                                // height: scaledHeight,
                                // width: "100%",
                                height: scaledHeight,
                            }}

                        >
                            {areas.map((area, index) => {
                                return (
                                    <Rnd
                                        key={area.id}
                                        default={{
                                            x: unit.value === "%"
                                                ? ((area.start_margin ?? 0) / 100) * (scaledWidth || 1)
                                                : ((area.start_margin ?? 0) / aspectWidth) * (scaledWidth || 1),
                                            y: unit.value === "%"
                                                ? ((area.top_margin ?? 0) / 100) * (scaledHeight || 1)
                                                : ((area.top_margin ?? 0) / aspectHeight) * (scaledHeight || 1),
                                            width: unit.value === "%"
                                                ? `${area.width}%`
                                                : `${(area.width / aspectWidth) * 100}%`,
                                            height: unit.value === "%"
                                                ? `${area.height}%`
                                                : `${(area.height / aspectHeight) * 100}%`,
                                        }}
                                        position={{
                                            x: unit.value === "%"
                                                ? ((area.start_margin ?? 0) / 100) * (scaledWidth || 1)
                                                : ((area.start_margin ?? 0) / aspectWidth) * (scaledWidth || 1),
                                            y: unit.value === "%"
                                                ? ((area.top_margin ?? 0) / 100) * (scaledHeight || 1)
                                                : ((area.top_margin ?? 0) / aspectHeight) * (scaledHeight || 1),
                                        }}
                                        size={{
                                            width: unit.value === "%"
                                                ? `${area.width}%`
                                                : `${(area.width / aspectWidth) * 100}%`,
                                            height: unit.value === "%"
                                                ? `${area.height}%`
                                                : `${(area.height / aspectHeight) * 100}%`,
                                        }}
                                        bounds="parent"
                                        enableResizing={{
                                            top: true, right: true, bottom: true, left: true,
                                            topRight: true, bottomRight: true, bottomLeft: true, topLeft: true,
                                        }}
                                        maxWidth={unit.value === "%" ? 100 - area.x : aspectWidth - area.x}
                                        maxHeight={unit.value === "%" ? 100 - area.y : aspectHeight - area.y}
                                        onResize={(e, direction, ref, delta, position) => setSelectedArea(area)}
                                        onDrag={(e, d) => setSelectedArea(area)}

                                        onDragStop={(e, d) => { handleDragStop(area.id, d) }}
                                        onResizeStop={(e, direction, ref, delta, position) => handleResizeStop(area.id, ref, position)}
                                        resizeHandleStyles={{
                                            topLeft: {
                                                width: "13px", height: "13px", background: "white",
                                                borderRadius: "50%", border: "2px solid black",
                                                position: "absolute", top: "-6px", left: "-6px"
                                            },
                                            topRight: {
                                                width: "13px", height: "13px", background: "white",
                                                borderRadius: "50%", border: "2px solid black",
                                                position: "absolute", top: "-6px", right: "-6px"
                                            },
                                            bottomLeft: {
                                                width: "13px", height: "13px", background: "white",
                                                borderRadius: "50%", border: "2px solid black",
                                                position: "absolute", bottom: "-6px", left: "-6px"
                                            },
                                            bottomRight: {
                                                width: "13px", height: "13px", background: "white",
                                                borderRadius: "50%", border: "2px solid black",
                                                position: "absolute", bottom: "-6px", right: "-6px"
                                            }
                                        }}
                                        resizeHandleClasses={{
                                            topLeft: "resize-handle",
                                            topRight: "resize-handle",
                                            bottomLeft: "resize-handle",
                                            bottomRight: "resize-handle",
                                        }}
                                        style={{
                                            border: "1px solid black",
                                            backgroundColor: selectedArea?.id === area.id ? "#8a8aed" : layoutColor[index], display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "black"
                                        }}
                                    >
                                        {index + 1}
                                    </Rnd>


                                );
                            })}
                        </div>
                    </div>
                </div >
        }</>
    );
};


export default DynamicContainer;
